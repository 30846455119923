import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, FormGroup, Label, Input, Container, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Swal from "sweetalert2";
import RequestFormCsa from "./requestForm/request_form_csa";
// import RequestFormNda from "./requestForm/request_form_nda";
import { Link, useHistory } from "react-router-dom";
import ConfigData from '../../config.json';
import axios from "axios";
import { getFilterOldFileName, setFormDataUploadFile, ConvertFirstStringUpperCase } from '../../components/utils/format-value';
import { modelDataRequest, modelRequestWorkFlow, modelSendMail, modelFormCsa} from "./model_request";
import { IconAlertSuccess, IconAlertError } from '../../components/common/popup-alert';

const RequestUserInformation = ({ dataFormType }) => {
    const [dataRequest, setDataRequest] = useState(modelDataRequest);
    const [dataRequestWorkFlow, setDataRequestWorkFlow] = useState(modelRequestWorkFlow);
    const [isShowModal, setIsShowModal] = useState();
    const [dataForm, setdataForm] = useState([]);
    const [dataCompany, setDataCompany] = useState([]);
    const history = useHistory();
    const userEmail = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
    // Thailand is UTC+7
    const thaiTimezoneOffset = 7 * 60; 
    const offsetInMilliseconds = thaiTimezoneOffset * 60 * 1000;
    const thaiDateTime = new Date(new Date().getTime() + offsetInMilliseconds);
    // Format the date for MySQL DATETIME
    const newDateTime = thaiDateTime.toISOString().slice(0, 19).replace("T", " ");
    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        timeZone: 'Asia/Bangkok',
    });
    const [currentDay, currentMonth, currentYear] = currentDate.split('/');
    const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const newCurrentDate = formattedCurrentDate.replace(/undefined-/g, '');

    useEffect(() => {
        LoadDataUser(userEmail);
    }, []);

    const LoadDataUser = (email) => {
        let url = ConfigData.API_URL + "user/search?status=1&email=" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataCompany(respData.data[0]);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const onClickOk = (data) => {
        let newFormatStartDate = null;
        let newFormatEndDate = null;
        let isSaveSucess = true;
        let isSendMail;
        let postDataFormCsa;
        let isForm;

        const postdataRequest = {
            ...dataRequest,
            id_form_temp: dataFormType.id,
            table_name: dataFormType.table_name,
            id_workflow_status: 1,
            request_no: "",
            request_date: newCurrentDate,
            id_business_unit : data.id_business_unit,
            business_unit_other : data.business_unit_other,
            id_confidential_level : data.id_confidential_level,
            counterparty_name : data.counterparty_name,
            create_request_by: data.create_request_by,
            request_by: userEmail,
            require_handled_by: data.require_handled_by,
            approve_request_by: data.approve_request_by,
            status: "1"
        };

        if( dataFormType?.table_name.toLowerCase() === "csa" ){
            isForm = "csa";
            if(data.term_start_date !== null && data.term_end_date !== null){
               if(data.term_start_date === "" && data.term_end_date === "") {
                    newFormatStartDate = null;
                    newFormatEndDate = null;
               } else {
                    // Convert term_start_date format
                    const [startDay, startMonth, startYear] = data.term_start_date.split('/');
                    const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
                    newFormatStartDate = formattedStartDate.replace(/undefined-/g, '');
                    // Convert term_end_date format
                    const [endDay, endMonth, endYear] = data.term_end_date.split('/');
                    const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
                    newFormatEndDate = formattedEndDate.replace(/undefined-/g, '');
               }
            }
          
            // Set the converted date back to the values object
            data.term_start_date = newFormatStartDate;
            data.term_end_date = newFormatEndDate;
                // set date to post formcsa
            postDataFormCsa = {
                ...data,
                id_request_type : dataFormType.id_request_type,
                id_sub_request_type : dataFormType.id_sub_request_type,
                request_date: newCurrentDate,
                term_start_date: newFormatStartDate,
                term_end_date: newFormatEndDate,
            };
        } else if ( dataFormType?.table_name.toLowerCase() === "nda" ){
            isForm = "nda";
            // const postDataFormNda = {
            //     ...data,
            //     id_request: dataAddRequest.data[0].id,
            //     id_business_unit : data.id_business_unit === "-1" ? dataAddRequest.data[0].id_business_unit : data.id_business_unit,
            //     id_request_type : dataFormType.id_request_type,
            //     id_sub_request_type : dataFormType.id_sub_request_type,
            //     request_date: newCurrentDate,
            //     id_language: parseInt(data.id_language) ? parseInt(data.id_language) : 0,
            //     type_of_nda: parseInt(data.type_of_nda) ? parseInt(data.type_of_nda) : 0,
            // };
            // getDataForm = await AddForm(postDataFormNda, "nda");
            // isSaveSucess = (getDataForm.is_sucess === true && isSaveSucess === true) ? true : false;
        }

        const postdataRequestWorkFlow = {
            ...dataRequestWorkFlow,
            do_date: newDateTime,
            // do_by: data.create_request_by != "" ? data.create_request_by : userEmail, // if user create request switch to request by
            do_by: userEmail,
            finish_date : null,
            id_workflow_status: 1,
        };

        const combinedData = {
            requestData: postdataRequest,
            formCsaData: postDataFormCsa,
            requestWorkFlowData: postdataRequestWorkFlow,
        };

        // console.log(combinedData);

        setIsShowModal(!isShowModal);
        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });
        (async () => {
            try{
                let requestData = await CreateRequestTrans(combinedData, isForm);
                isSaveSucess = (requestData.is_sucess === true && isSaveSucess === true) ? true : false;

                let isFileName = await SetFileName(requestData.data[0]?.id_form_csa, requestData.data[0].request_no, data);
                isSaveSucess = (isFileName === true && isSaveSucess === true) ? true : false;

                if(isSaveSucess === true){
                        isSendMail = await SendEmailAddRequest(requestData.data[0]);
                    if(data.create_request_by !== "" ){
                        isSendMail = await SendEmailChangeUserCreateRequest(requestData.data[0]);
                    }
                    // console.log(isSendMail);
                    if( isSendMail === true ){
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertSuccess,
                            title: "Sending request to approver.",
                            showConfirmButton: false,
                            showCloseButton: true,
                            // showConfirmButton: false,
                            }).then(() => {
                                // props.nextStep();
                                history.push("/requests");
                            });
                    }else{
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: 'Failed to send email to approver.',
                            showConfirmButton: false,
                            showCloseButton: true,
                        }).then(() => {
                            // props.nextStep();
                            history.push("/requests");
                        });
                        console.log("api on send email is error");
                    }
                }else{
                    Swal.close(); // Close loading spinner
                    Swal.fire({
                        iconHtml: IconAlertError,
                        title: "Failed to save data.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                    console.log("api on create request without email is error");
                }
            } catch(error) {
                Swal.fire({
                    iconHtml: IconAlertError,
                    title: "Failed to save data.",
                    showConfirmButton: false,
                    showCloseButton: true,
                });
                console.log("api all on create request is error");
                console.log(error);
            }
        })();
    };

    const onSaveForm = (values) => {
        setIsShowModal(true);
        setdataForm(values);
    };

    const onSaveDraft = (data) => {
        let newFormatStartDate = null;
        let newFormatEndDate = null;
       
        const postdataRequest = {
            ...dataRequest,
            id_form_temp: dataFormType.id,
            table_name: dataFormType.table_name,
            request_no: "draft",
            id_business_unit : data.id_business_unit !== "" ? data.id_business_unit : "-1",
            business_unit_other : data.business_unit_other,
            id_confidential_level : data.id_confidential_level ? data.id_confidential_level : "0",
            counterparty_name : data.counterparty_name,
            create_request_by: data.create_request_by,
            request_by: userEmail,
            request_date: newCurrentDate,
            require_handled_by: data.require_handled_by,
            approve_request_by: data.approve_request_by,
        };
        // console.log(postdataRequest);
        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        let isSaveSucess = true;
        (async () => {
            try{
                let dataAddRequest = await AddRequest(postdataRequest);
                isSaveSucess = (dataAddRequest.is_sucess === true && isSaveSucess === true) ? true : false;
                // console.log(dataAddRequest);
                
                let getDataFormDraft;
                if( dataFormType?.table_name.toLowerCase() === "csa" ){
                    if( data.term_start_date !== null && data.term_end_date !== null){
                        // Convert term_start_date format
                       const [startDay, startMonth, startYear] = data.term_start_date.split('/');
                       const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
                       newFormatStartDate = formattedStartDate.replace(/undefined-/g, '');
                       // Convert term_end_date format
                       const [endDay, endMonth, endYear] = data.term_end_date.split('/');
                       const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
                       newFormatEndDate = formattedEndDate.replace(/undefined-/g, '');
                   }
                    // set date to post formcsa
                    const postDataFormCsa = {
                        ...data,
                        id_request: dataAddRequest.data[0].id,
                        id_request_type: dataFormType.id_request_type,
                        id_sub_request_type: dataFormType.id_sub_request_type,
                        request_date: newCurrentDate,
                        term_start_date: newFormatStartDate,
                        term_end_date: newFormatEndDate,
                        id_language: parseInt(data.id_language) ? parseInt(data.id_language) : 0,
                        id_confidential_level: data.id_confidential_level ? data.id_confidential_level : "0",
                        id_business_unit: dataAddRequest.data[0].id_business_unit,
                    };
                    // console.log(postDataFormCsa);
                    getDataFormDraft = await AddForm(postDataFormCsa, "csa");
                    isSaveSucess = (getDataFormDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                } else if( dataFormType?.table_name.toLowerCase() === "nda" ){
                     // set date to post formcsa
                     const postDataFormNda = {
                        ...data,
                        id_request: dataAddRequest.data[0].id,
                        id_request_type: dataFormType.id_request_type,
                        id_sub_request_type: dataFormType.id_sub_request_type,
                        beginning_date: data.beginning_date !== "" ? data.beginning_date : null,
                        id_language: parseInt(data.id_language) ? parseInt(data.id_language) : 0,
                        id_confidential_level: data.id_confidential_level ? data.id_confidential_level : "0",
                        id_business_unit: dataAddRequest.data[0].id_business_unit,
                    };

                    getDataFormDraft = await AddForm(postDataFormNda, "nda");
                    isSaveSucess = (getDataFormDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                }

                let isFileName = await SetFileName(getDataFormDraft.data[0].id, "draft", data);
                isSaveSucess = (isFileName === true && isSaveSucess === true) ? true : false;
                // console.log(isFileName);
                
                if(isSaveSucess === true){
                    Swal.close(); // Close loading spinner
                    Swal.fire({
                        iconHtml: IconAlertSuccess,
                        title: "Create new draft success.",
                        showConfirmButton: false,
                        showCloseButton: true,
                        // showConfirmButton: false,
                    }).then(() => {
                        // props.nextStep();
                        history.push("/request/" + btoa(dataAddRequest.data[0].id));
                    });
                }else{
                    Swal.close(); // Close loading spinner
                    Swal.fire({
                        iconHtml: IconAlertError,
                        title: "Failed to save draft data.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                    console.log("some api on save draft is error");
                }
            } catch(error) {
                Swal.close(); // Close loading spinner
                Swal.fire({
                    iconHtml: IconAlertError,
                    title: "Failed to save draft data.",
                    showConfirmButton: false,
                    showCloseButton: true,
                });
                console.log("error on action save draft");
                console.log(error);
            }
        })();
    };

    const CreateRequestTrans = async (data, form) => {
        let url;
        if(form === "csa"){
            url = ConfigData.API_URL + "request/create-csa-trans";
        } else if(form === "nda"){
            url = ConfigData.API_URL + "request/create-nda-trans";
        } else if(form === "ldr"){
            url = ConfigData.API_URL + "request/create-ldr-trans";
        }
        
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.post(url, data, { headers: { 
                                'Authorization' : token,
                                'User': userId,
                            }, responseType: "json" }
                        );
            let respData = resp.data;
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log("error on create request");
            console.log(error);
            return false;
        }
    };

    const AddRequest = async (data) => {
        // console.log(data);
        let url = ConfigData.API_URL + "request/";
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.post(url, data, { headers: { 
                                'Authorization' : token,
                                'User': userId,
                            }, responseType: "json" }
                        );
            let respData = resp.data;
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log("error on creare request");
            console.log(error);
            return false;
        }
    };

    const AddForm = async (data, formName) => {
        let url;
        if( formName === "csa" ){
            url = ConfigData.API_URL + "form-csa/";
             // console.log(data);
            if(data.term_start_date === ""){
                data.term_start_date = null;
            }  
            if(data.term_end_date === ""){
                data.term_end_date = null;
            } 
        } else if( formName === "nda" ){
            url = ConfigData.API_URL + "form-nda/";
        }

        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.post(url, data, { headers: { 
                                'Authorization' : token,
                                'User': userId,
                            }, responseType: "json" }
                        );
            let respData = resp.data;
            // console.log(respData);
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log("error on creare form");
            console.log(error);
            return false;
        }
    };

    const SetFileName = async (id, requestNo, data) => {
        let url;
        let formData = new FormData();
        formData.append("request_no", requestNo);

        if(dataFormType?.table_name.toLowerCase() === "csa" ){
            url = ConfigData.API_URL + "form-csa/set-filename/" + id;

            formData.append("n_draft_doc", getFilterOldFileName(data.file_draft_doc));
            formData.append("n_counterparty_doc", getFilterOldFileName(data.file_counterparty_doc));
            formData.append("n_is_sla_doc", getFilterOldFileName(data.file_is_sla_doc));
            formData.append("n_scope_of_work_doc", getFilterOldFileName(data.file_scope_of_work_doc));
            formData.append("n_consider_doc", getFilterOldFileName(data.file_consider_doc));
            formData = setFormDataUploadFile(formData, "f_draft_doc", data.file_draft_doc);
            formData = setFormDataUploadFile(formData, "f_counterparty_doc", data.file_counterparty_doc);
            formData = setFormDataUploadFile(formData, "f_is_sla_doc", data.file_is_sla_doc);
            formData = setFormDataUploadFile(formData, "f_scope_of_work_doc", data.file_scope_of_work_doc);
            formData = setFormDataUploadFile(formData, "f_consider_doc", data.file_consider_doc);
        } else if(dataFormType?.table_name.toLowerCase() === "nda" ){
            url = ConfigData.API_URL + "form-nda/set-filename/" + id;

            formData.append("n_draft_doc", getFilterOldFileName(data.file_draft_doc));
            formData.append("n_consider_doc", getFilterOldFileName(data.file_consider_doc));
            formData = setFormDataUploadFile(formData, "f_draft_doc", data.file_draft_doc);
            formData = setFormDataUploadFile(formData, "f_consider_doc", data.file_consider_doc);
        }
        
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        
        try{
            const resp = await axios.put(url, formData, { headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log("error on upload file");
            console.log(error);
            return false;
        }
    };

    const SendEmailAddRequest = async (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.approve_request_by.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 1,
            "request_id": data.id,
        };
        try {
            const resp = await axios.post(url, dataReq, { headers: { 
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        } catch(error){
            console.log("error on send mail to approver");
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const SendEmailChangeUserCreateRequest = async (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.request_by.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 21,
            "request_id": data.id,
        };
        try {
            const resp = await axios.post(url, dataReq, { headers: { 
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        } catch(error){
            // handlerRespError(error, history);
            console.log("error on send mail to coordinator");
            console.log(error);
            return false;
        }
    };

  return (
    <React.Fragment>
        <div>
            {/* start component type information */}
            <Row>
                <Col xl={10} className="mx-auto">
                    <div>
                        <span className="span-type-request-note">โปรดกรอกข้อมูลในข้อที่มีดอกจันกำกับให้ครบถ้วน (Please fill out the information in the sections marked with *) </span>
                    </div>
                    <br/>
                    <div>
                        <span className="span-type-request">ข้อมูลของผู้ทำคำขอ (User Information)​</span>
                    </div>
                </Col>
            </Row>
            <br/>
            <Col md={10} className="mx-auto">
                <Row>
                    <Col md={5}>
                        <FormGroup>
                            <Label className="request_label_nowrap">คำขอเลขที่ (Request No.)​</Label>
                            <Input
                            type="text"
                            name="requestNo"
                            placeholder=""
                            // onChange={onInputChanged}
                            // defaultValue={requestNo || ""}
                            // onChange={handleInputChange}
                            value={"Draft" || ""}
                            disabled
                            />
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <Label className="request_label_nowrap">วันที่สร้างคำขอ (Date of Request)​</Label>
                            <Input
                            type="text"
                            name="dateOfRequest"
                            placeholder=""
                            // onChange={handleInputChange}
                            value={currentDate}
                            disabled
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
            <Col md={10} className="mx-auto">
                <Row>
                    <Col md={5}>
                        <FormGroup>
                            <Label htmlFor="ddlBu" className="label-ddl">บริษัทที่สังกัด (User's BU)​</Label>
                            <Input
                            type="text"
                            name="businessUnit"
                            placeholder=""
                            // onChange={handleInputChange}
                            value={dataCompany.business_unit || ""}
                            disabled
                            />
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <Label htmlFor="ddlDepartment" className="label-ddl">แผนกที่สังกัด (User's Department)​</Label>
                            <Input
                            type="text"
                            name="department"
                            placeholder=""
                            // onChange={handleInputChange}
                            value={dataCompany.department || ""}
                            disabled
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <div className="line-border"></div>
                    </Col>
                    <Col md={12}>
                        {
                        dataFormType.table_name?.toLowerCase() === "csa" ? (
                            <RequestFormCsa dataFormCsa={modelFormCsa} dataFormRequest={dataFormType} onSaveDraft={onSaveDraft} onSave={onSaveForm}  />
                        )
                        :
                        // dataFormType.table_name?.toLowerCase() === "nda" ? (
                        //     <RequestFormNda dataFormNda={modelFormNda} dataFormRequest={dataFormType} onSaveDraft={onSaveDraft} onSave={onSaveForm}  />
                        // )
                        // : 
                        (
                            <div>form other</div>
                        )}
                    </Col>
                </Row>
            </Col>
            {/* end component user information */}
            <br />
        </div>
        {/* Modal Add and Edit */}
        <Modal id="modalBU"
                isOpen={isShowModal}
                toggle={() => { setIsShowModal(!isShowModal); }}
                backdrop={'static'}
                centered
            >
                <ModalBody>
                        <Row>
                            <p><i className="mdi mdi-alert-decagram modal-alert"></i></p>
                            <p className="modal-note">Please note</p>
                            <p className="modal-content-approve">that the information provided in the request form is only a preliminary information for review/drafting process.<br/>Legal team may request for more information at a later stage.</p> 
                        </Row>
                </ModalBody>
                <ModalFooter>
                        <Row>
                            <Col md="12">
                                <Button 
                                    className="float-end" 
                                    color="primary" 
                                    type="button"
                                    onClick={() => onClickOk(dataForm)}
                                >
                                    Ok
                                </Button>
                                <Button 
                                    className="float-end" 
                                    color="outline secondary" 
                                    type="button" 
                                    onClick={() => setIsShowModal(false) } 
                                    
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                </ModalFooter>
        </Modal>
    </React.Fragment>
    
  );
};

export default RequestUserInformation;