import React, { useState, useEffect } from "react";
import { Alert, Form, Row, Col, Button, FormFeedback, FormGroup, Label, Input, UncontrolledPopover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import ConfigData from '../../../config.json';
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import SingleUploadfile from '../../common/single-uploadfile';
import MultipleUploadfile from '../../common/multiple-uploadfile';
import { getFilterOldFileName, setFormDataUploadFile, setStringFileNameToArray, setShowFileName } from '../../utils/format-value';
import { getDomain } from '../../utils/check-value';
// import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { IconAlertError, IconAlertReturn } from '../../../components/common/popup-alert';

const RequestFormCsa = ({ dataFormCsa, dataFormRequest, onSaveDraft, onSave, onCancelRequest}) => {
    const history = useHistory();
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isDisableds, setIsDisableds] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const togglePopover = () => setPopoverOpen(!popoverOpen);
    const closePopover = () => setPopoverOpen(false);
    const [selectedDraft, setSelectedDraft] = useState(null);
    const [ddlDraft, setDdlDraft] = useState([]);
    const [dataUser, setDataUser] = useState([]);
    const [dataCompany, setDataCompany] = useState([]);
    const [selectCompany, setSelectCompany] = useState(null);
    const [dataLanguage, setDataLanguage] = useState([]);
    const [selectLanguage, setSelectLanguage] = useState(null);
    const [dataConfidential, setDataConfidential] = useState([]);
    const [selectConfidential, setSelectConfidential] = useState(null);
    const [selectCompanyActAs, setSelectCompanyActAs] = useState(null);
    const [ddlCompanyActAs, setDdlCompanyActAs] = useState([]);
    const [selectCounterActAs, setSelectCounterActAs] = useState(null);
    const [ddlCounterActAs, setDdlCounterActAs] = useState([]);
    const [selectPaymentType, setSelectPaymentType] = useState(null);
    const [ddlPaymentType, setDdlPaymentType] = useState([]);
    const [selectedRadioIsSla, setSelectedRadioIsSla] = useState("");
    const [InputIdIsSla, setInputIdIsSla] = useState(true);
    const [InputIdDraft, setInputIdDraft] = useState(true);
    const [selectedRadioPdpa, setSelectedRadioPdpa] = useState("");
    const [selectedRadioVat, setSelectedRadioVat] = useState("");
    const [isOtherCompanySelected, setisOtherCompanySelected] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fileDraftAgreementDoc, setFileDraftAgreementDoc] = useState([]);
    const [fileCounterpartyDoc, setFileCounterpartyDoc] = useState([]);
    const [fileScopeOfWorkDoc, setFileScopeOfWorkDoc] = useState([]);
    const [fileNameScopeOfWorkMultiple, setFileNameScopeOfWorkMultiple] = useState("");
    const [fileIsSlaDoc, setFileIsSlaDoc] = useState([]);
    const [fileConsiderDoc, setFileConsiderDoc] = useState([]);
    const [fileNameMultiple, setFileNameMultiple] = useState("");
    const [dataDetail, setDataDetail] = useState([]);
    const [emailLegalAuthorized , setEmailLegalAuthorized] = useState([]);
    const [emailLegalAdmin , setEmailLegalAdmin] = useState([]);

    const [formTemp, setFormTemp] = useState({});

     // State to manage the checkbox's checked status
    const [isCheckedCompanyOther, setIsCheckedCompanyOther] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState("");
    const [companyDisabled, setCompanyDisabled] = useState(false);
    const [otherCompanyDisabled, setOtherCompanyDisabled] = useState(true);
    const [companyPlaceholder, setCompanyPlaceholder] = useState("ระบุชื่อในการทำสัญญา");
    const [otherCompanyPlaceholder, setOtherCompanyPlaceholder] = useState("");

    const [isCheckedTermOther, setIsCheckedTermOther] = useState(false);
    const [termStartDisabled, setTermStartDisabled] = useState(true);
    const [termEndDisabled, setTermEndDisabled] = useState(true);
    const [otherTermDisabled, setOtherTermDisabled] = useState(true);
    const [dataTermOther, setDataTermOther] = useState("");
    const [otherTermPlaceholder, setOtherTermPlaceholder] = useState("");

    const [showCommentApprover, setShowCommentApprover] = useState(false);
    const userEmail = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
    const autoCompletes = "on";
    // Thailand is UTC+7
    const thaiTimezoneOffset = 7 * 60; 
    const offsetInMilliseconds = thaiTimezoneOffset * 60 * 1000;
    const thaiDateTime = new Date(new Date().getTime() + offsetInMilliseconds);
    // Format the date for MySQL DATETIME
    const newDateTime = thaiDateTime.toISOString().slice(0, 19).replace("T", " ");
    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        timeZone: 'Asia/Bangkok',
    });
    const [currentDay, currentMonth, currentYear] = currentDate.split('/');
    const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const newCurrentDate = formattedCurrentDate.replace(/undefined-/g, '');
    const englishEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Function to handle checkbox state changes
    const handleCheckboxChangeCompanyOther = () => {
        setIsCheckedCompanyOther(!isCheckedCompanyOther); // Toggle the state
        if (!isCheckedCompanyOther === true) {
            // Checkbox is checked
            setCompanyDisabled(true);
            setCompanyPlaceholder("");
            setSelectCompany("");
            setOtherCompanyDisabled(false);
            setOtherCompanyPlaceholder("กรอกชื่อบริษัท");

            validation.setFieldValue("id_business_unit", "-1");
            validation.setFieldValue("business_unit_other", "");
            validation.setFieldError("id_business_unit", null);
            validation.setFieldError("business_unit_other", null);
            validation.setFieldTouched("id_business_unit", false);
            validation.setFieldTouched("business_unit_other", false);

        } else {
            // Checkbox is unchecked
            setCompanyDisabled(false);
            setCompanyPlaceholder("โปรดเลือก...");
            setOtherCompanyDisabled(true);
            setOtherCompanyPlaceholder("");
        
            validation.setFieldValue("id_business_unit", "");
            validation.setFieldValue("business_unit_other", "");
            validation.setFieldError("id_business_unit", null);
            validation.setFieldError("business_unit_other", null);
            validation.setFieldTouched("id_business_unit", false);
            validation.setFieldTouched("business_unit_other", false);

        }
    };

    // const dataCompanyWithOther = [...dataCompany, { value: "0", label: "Other" }];
    const optionDdlDraft = [
        { value: "1", label: "ตรวจสอบสัญญา/บันทึกข้อตกลงตามแนบ (Review draft agreement as attached)" },
        { value: "2", label: "ให้ฝ่ายกฎหมายจัดทำสัญญา/บันทึกข้อตกลง (Draft agreement)" },
    ];
    const optionDdlCompanyActAs = [
        { value: "1", label: "ผู้รับจ้าง (Service Provider)" },
        { value: "2", label: "ผู้ว่าจ้าง (Service Receiver)" },
    ];
    const optionDdlCounterActAs = [
        { value: "1", label: "ผู้รับจ้าง (Service Provider)" },
        { value: "2", label: "ผู้ว่าจ้าง (Service Receiver)" },
    ];
    const optionDdlPaymentType = [
        { value: "1", label: "ชำระครั้งเดียว เมื่อไร (โปรดระบุ) (Lumpsum (please provide date))" },
        { value: "2", label: "รายงวด (โปรดระบุ) (Instalment (please provide details))" },
    ];

    // start check condition in form

    const checkAuthorizedSubmitOrApprove = (
        (dataUser.email === dataFormRequest.approve_request_by && dataUser.email !== dataFormRequest.request_by) 
        || (dataFormRequest.request_by === dataFormRequest.approve_request_by && dataUser.id_user_group === 2) 
        || dataFormRequest.id_workflow_status === 4
    );

    const checkAuthorizedReturn = (
        (dataUser.email === dataFormRequest.approve_request_by && dataUser.email !== dataFormRequest.request_by) 
        || (dataUser.id_user_group === 2) 
        || (dataFormRequest.id_workflow_status === 4)
    );

    const checkUserIsApproverShowReturnBtn = (
        dataUser.email === dataFormRequest.approve_request_by && dataFormRequest.id_workflow_status !== 4
    );

    // case if requestor && approver ถ้าคนเปิด request และ คนอนุมัติเป็นคนเดียวกันจะปิด ปุ่ม return ถ้าใช้
    const checkUserCreateTicketAndApproveIsSame = ( 
        dataFormRequest.request_by !== dataFormRequest.approve_request_by
    );

    const changeButtonIsStateRequestorOrApprover = (
        (dataFormRequest.id_workflow_status !== 1 && dataFormRequest.id_workflow_status !== 2 && dataUser.email === dataFormRequest.request_by) 
        || (dataFormRequest.id_workflow_status !== 4 && dataUser.email === dataFormRequest.approve_request_by) 
        || (dataFormCsa.id === 0)
    )

    const checkShowInputUserCoordinator = (
        (getDomain(userEmail) === "rabbitholdings.co.th" || dataUser.business_unit?.toLowerCase() === "rabbit holdings public company limited") 
        && (dataFormCsa.id === 0 || dataFormRequest.request_no === "draft")
    );

    const checkLoadDataRequestAndForm = (
        (dataFormRequest.id_workflow_status === 4 && userEmail === dataFormRequest.request_by || dataFormRequest.id_workflow_status === 0)
        || (dataFormRequest.request_by === dataFormRequest.approve_request_by && dataUser.id_user_group === 2) 
        || (dataFormRequest.id_workflow_status === 1 && userEmail === dataFormRequest.approve_request_by) 
        || (dataFormRequest.id_workflow_status === 2 && userEmail === dataFormRequest.approve_request_by)
    );

    // end check condition in form

    useEffect(() => {
        LoadDataBusinessUnit();
        LoadDataLanguage();
        LoadDataConfidential();
        setDdlDraft(optionDdlDraft);
        setDdlCompanyActAs(optionDdlCompanyActAs);
        setDdlCounterActAs(optionDdlCounterActAs);
        setDdlPaymentType(optionDdlPaymentType);
        LoadProfileUser(userEmail);
        LoadLegalAdmin(5); // หมายถึง กลุ่มของ user admin
    }, []);

    useEffect(() => {
        if(dataFormCsa.id > 0){
            let updatedDataFormCsa = {...dataFormCsa};

            if (dataFormRequest.request_no === "draft" && dataFormRequest.id_business_unit === "-1") {
                updatedDataFormCsa.id_business_unit = "";
            }
            if (dataFormRequest.id_confidential_level === "0") {
                updatedDataFormCsa.id_confidential_level = "";
            }
            if (dataFormCsa.term_start_date === null && dataFormCsa.term_end_date === null) {
                if(dataFormCsa.term_other !== ""){
                    updatedDataFormCsa.selectedTerm = "2";
                } else {
                    updatedDataFormCsa.selectedTerm = "";
                }
            } else if(dataFormCsa.term_other !== ""){
                updatedDataFormCsa.selectedTerm = "2";
            } else {
                updatedDataFormCsa.selectedTerm = "1";
            }
            setDataDetail(updatedDataFormCsa);
            
            setFileDraftAgreementDoc(dataFormCsa.draft_doc ? setStringFileNameToArray(dataFormCsa.draft_doc) : []);
            setFileCounterpartyDoc(dataFormCsa.counterparty_doc ? setStringFileNameToArray(dataFormCsa.counterparty_doc) : []);
            setFileScopeOfWorkDoc(dataFormCsa.scope_of_work_doc ? setStringFileNameToArray(dataFormCsa.scope_of_work_doc) : []);
            setFileIsSlaDoc(dataFormCsa.is_sla_doc ? setStringFileNameToArray(dataFormCsa.is_sla_doc) : []);
            setFileConsiderDoc(dataFormCsa.consider_doc ? setStringFileNameToArray(dataFormCsa.consider_doc) : []);
            setSelectedRadioIsSla(dataFormCsa.is_sla ?? "");
            setSelectedRadioPdpa(dataFormCsa.share_pdpa ?? "");
            setSelectedRadioVat(dataFormCsa.vat ?? "");
            setStartDate(dataFormCsa.term_start_date ?? "");
            setEndDate(dataFormCsa.term_end_date ?? "");   
            setDataTermOther(dataFormCsa.term_other ?? "");
            if(dataFormRequest.request_no !== "draft" && dataFormCsa.approver_comment !== ""){
                setShowCommentApprover(true);
            }
            LoadFormTempById(dataFormRequest.id_form_temp);
        }
    }, [dataFormCsa, dataFormRequest]);

    useEffect(() => {
        if(dataFormCsa.id > 0){
            // 1. set group requestor can edit when id workflow = 4 or draft or user groupId2 when user can approve request.
            // 2,3. set group approver can edit when id workflow = 1,2
            if( checkLoadDataRequestAndForm ){
                setCompanyDisabled(false);
                setOtherTermDisabled(dataFormCsa.term_other && dataFormCsa.term_other.length > 0 ? false : true );
                setSelectedTerm(
                    (dataFormCsa.term_start_date && dataFormCsa.term_start_date.length > 0 || dataFormCsa.term_end_date && dataFormCsa.term_end_date.length > 0) ? "1" :
                    (dataFormCsa.term_other && dataFormCsa.term_other.length > 0) ? "2" : "0"
                    // (dataFormRequest.request_no === "draft") ? "0"
                );
                setTermStartDisabled(
                    (dataFormCsa.term_start_date && dataFormCsa.term_start_date.length > 0) ? false :
                    (selectedTerm !== "1" && (dataFormRequest.request_no === "draft" || dataFormCsa.term_start_date === null) ) ? true : false
                );
                setTermEndDisabled(
                    (dataFormCsa.term_end_date && dataFormCsa.term_end_date.length > 0) ? false :
                    ( selectedTerm !== "1" && (dataFormRequest.request_no === "draft" || dataFormCsa.term_end_date === null) ) ? true : false
                );
            } else if( dataFormRequest.id_workflow_status > 4 ){
                setIsDisableds(false);
                setCompanyDisabled(false);
                setTermStartDisabled(false);
                setTermEndDisabled(false);
                setOtherTermDisabled(dataFormCsa.term_other && dataFormCsa.term_other.length > 0 ? false : true );
                setSelectedTerm(
                    (dataFormCsa.term_start_date && dataFormCsa.term_start_date.length > 0 || dataFormCsa.term_end_date && dataFormCsa.term_end_date.length > 0) ? "1" :
                    (dataFormCsa.term_other && dataFormCsa.term_other.length > 0) ? "2" : "0"
                    // (dataFormRequest.request_no === "draft") ? "0"
                );
                setTermStartDisabled(
                    (dataFormCsa.term_start_date && dataFormCsa.term_start_date.length > 0) ? false :
                    (selectedTerm !== "1" && (dataFormRequest.request_no === "draft" || dataFormCsa.term_start_date === null) ) ? true : false
                );
                setTermEndDisabled(
                    (dataFormCsa.term_end_date && dataFormCsa.term_end_date.length > 0) ? false :
                    ( selectedTerm !== "1" && (dataFormRequest.request_no === "draft" || dataFormCsa.term_end_date === null) ) ? true : false
                );
            } else {
                setIsDisableds(true);
                setCompanyDisabled(true);
                setTermStartDisabled(true);
                setTermEndDisabled(true);
                setOtherTermDisabled(true);
                setSelectedTerm(
                    (dataFormCsa.term_start_date && dataFormCsa.term_start_date.length > 0) ? "1" :
                    (dataFormCsa.term_other && dataFormCsa.term_other.length > 0) ? "2" : "0"
                );
            }
            // term other
            // validation.setFieldValue("selectedTerm", selectedTerm !== "" ? selectedTerm : "");

            setSelectLanguage(dataLanguage.find(x => x.value === dataFormCsa.id_language.toString()) ?? "");
            setSelectConfidential(dataConfidential.find(x => x.value === dataFormCsa.id_confidential_level.toString()) ?? "");
            setSelectedDraft(ddlDraft.find(x => x.value === dataFormCsa.draft_agreement.toString()) ?? "");
            setSelectCompany(dataCompany.find(x => x.value === dataFormCsa.id_business_unit.toString()) ?? "");
            setSelectCompanyActAs(ddlCompanyActAs.find(x => x.value === dataFormCsa.business_unit_act_as.toString()) ?? "");
            setSelectCounterActAs(ddlCounterActAs.find(x => x.value === dataFormCsa.counterparty_act_as.toString()) ?? "");
            setSelectPaymentType(ddlPaymentType.find(x => x.value === dataFormCsa.service_fee_payment_type.toString()) ?? ""); 
            LoadLegalAuthorized(dataFormCsa.id_business_unit);
        }
    }, [dataLanguage, dataConfidential, dataCompany, ddlCompanyActAs, ddlCounterActAs, ddlPaymentType, ddlDraft, dataFormCsa ]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const popover = document.getElementById("PopoverRightAttached1");
            if (popover && !popover.contains(event.target)) {
                closePopover();
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        
    }, [isOtherCompanySelected]);

    const LoadProfileUser = (email) => {
        let url = ConfigData.API_URL + "user/search?status=1&email=" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataUser(respData.data[0]);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadFormTempById = (idFormTemp) =>{
        let url = ConfigData.API_URL + "form-temp/" + idFormTemp;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setFormTemp(respData.data[0]);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadLegalAuthorized = (idBusinessUnit) => {
        let url = ConfigData.API_URL + "map-user-assign/search?status=1&id_business_unit=" + idBusinessUnit;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setEmailLegalAuthorized(respData.data);
                // console.log(respData.data[0].email);
            } else {
                setEmailLegalAuthorized("");
                // let newUrl = ConfigData.API_URL + "map-user-assign/search?status=1&id_user=38";
                // axios.get(newUrl, { headers: {
                //         'Authorization': token,
                //     }, responseType: "json" })
                // .then(newResp => {
                //     let newArrData = newResp.data;
                //     if(newArrData.is_sucess === true && newArrData.data.length > 0){
                //         setEmailLegalAuthorized(newArrData.data);
                //     } else {
                //         setEmailLegalAuthorized("");
                //     }
                // })
                // .catch(newError => {
                //     console.log(newError);
                // });
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadLegalAdmin = (groupId) => {
        let url = ConfigData.API_URL + "user/?status=1&id_user_group=" + groupId;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            responseType: "json",
        })
        .then((resp) => {
            const respData = resp.data;
            if (respData.is_sucess && respData.data.length > 0) {
                setEmailLegalAdmin(respData.data);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const LoadDataBusinessUnit = () => {
        let url = ConfigData.API_URL + "business-unit/search?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrData = respData.data.map((e) => {
                    return {
                        label: e.name_en,
                        value: e.id.toString()
                    };
                });
                setDataCompany(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataLanguage = () => {
        let url = ConfigData.API_URL + "language/?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrData = respData.data.map((e) => {
                    return {
                        label: e.name,
                        value: e.id.toString(),
                    };
                });
                setDataLanguage(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataConfidential= () => {
        let url = ConfigData.API_URL + "confidential/?status=1";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrData = respData.data.map((e) => {
                    return {
                        label: e.name_th + " (" + e.name_en + ")",
                        value: e.id.toString(),
                    };
                });
                setDataConfidential(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const onCheckSaveDraft = () => {
        // if(userEmail === dataFormRequest.request_by || dataFormRequest.id_workflow_status === 4){
        if(( dataUser.email === dataFormRequest.approve_request_by && dataUser.email !== dataFormRequest.request_by ) || (dataFormRequest.request_by === dataFormRequest.approve_request_by && dataUser.id_user_group === 2) || dataFormRequest.id_workflow_status === 4 || dataFormRequest.id_workflow_status === 0){
            validationDraft.submitForm();
        } else if(dataFormCsa.id_request === ""){
            validationDraft.submitForm();
        } else {
            Swal.fire({
                iconHtml: IconAlertError,
                title: "You Are not authorized to action this.",
                showConfirmButton: false,
                showCloseButton: true,
            });
        }
    }

    const onCheckSubmit = async () => {
        // ทำการ validate ฟอร์มทั้งหมด
        const errors = await validation.validateForm();
    
        // ตรวจสอบว่ามี error หรือไม่
        if (Object.keys(errors).length > 0) {
            Swal.fire({
                iconHtml: IconAlertError,
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                showConfirmButton: false,
                showCloseButton: true,
            });
            // return; // หยุดการทำงานถ้ามี error
        }
    
        // ถ้าไม่มี error ให้ทำการ submit form
        if (!dataFormRequest.id_workflow_status) {
            validation.submitForm();
        } else if ( checkAuthorizedSubmitOrApprove ) {
            validation.submitForm();
        } else {
            Swal.fire({
                iconHtml: IconAlertError,
                title: "You Are not authorized to action this.",
                showConfirmButton: false,
                showCloseButton: true,
            });
        }
    
        // ตรวจสอบค่าของ validation
        // console.log(validation);
    };

    const onCheckReturn = async () => {
        setShowCommentApprover(true);
        
        // ทำการ submit form เพื่อ trigger การ validate
        await validationReturn.submitForm();
        // ตรวจสอบค่าของ showCommentApprover
        if (showCommentApprover) {
            // ตรวจสอบว่ามีข้อผิดพลาดหรือไม่
            const errors = validationReturn.errors;
            // console.log(errors);
            
            if (Object.keys(errors).length === 0) {
                // ตรวจสอบสิทธิ์การอนุมัติ
                if (checkAuthorizedReturn) {
                    onReturn(dataFormRequest, validation.values);
                } else {
                    Swal.fire({
                        iconHtml: IconAlertError,
                        title: "You Are not authorized to action this.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                }
            } else {
                // ถ้ามีข้อผิดพลาด แสดงข้อความเตือน
                Swal.fire({
                    iconHtml: IconAlertError,
                    title: "กรุณากรอกข้อมูลความเห็นของผู้อนุมัติ",
                    showConfirmButton: false,
                    showCloseButton: true,
                });
            }
        }
    };

    const onReturn = (dataRequest, dataFormCsa) => {
        // setIsShowModal(!isShowModal);
        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });
  
        let isSaveSucess = true;
        (async () => {
            try{
                let dataReturn = await ApprovalReturn(dataRequest.id);
                isSaveSucess = (dataReturn.is_sucess === true && isSaveSucess === true) ? true : false;
                
                const dataRequestWorkFlows = {
                    "id_request": dataRequest.id,
                    "id_workflow_status" : 4,
                    "do_by" : userEmail,
                    "send_to" : dataRequest.request_by,
                    "cc_to" : null,
                    "comment" : dataFormCsa.approver_comment,
                    "document" : null,
                    "consent" : null,
                    "do_date" : newDateTime,
                };
                let dataRequestWorkFlow = await AddRequestWorkFlow(dataRequestWorkFlows);
                isSaveSucess = (dataRequestWorkFlow.is_sucess === true && isSaveSucess === true) ? true : false;

                const {
                    approve_request_by,
                    require_handled_by,
                    request_no,
                    ...postDataFormCsa //delete data json
                } = dataFormCsa;

                // console.log(postDataFormCsa);
                if( postDataFormCsa.term_start_date === "" && postDataFormCsa.term_end_date === ""){
                    postDataFormCsa.term_start_date = null;
                    postDataFormCsa.term_end_date = null;
                } 

                let formCsa;
                if(postDataFormCsa.business_unit_other !== "" && postDataFormCsa.id_business_unit === "-1") {
                    const postdataRequest = {
                        ...dataRequest,
                        id: dataRequest.id,
                        id_business_unit : postDataFormCsa.business_unit_other !== "" ? "-1" : postDataFormCsa.id_business_unit,
                        business_unit_other : postDataFormCsa.business_unit_other,
                        id_confidential_level: postDataFormCsa.id_confidential_level,
                    };
                    let dataRequestForm = await UpdateRequests(postdataRequest);
                    isSaveSucess = (dataRequestForm.is_sucess === true && isSaveSucess === true) ? true : false;
                    // console.log(dataRequest);

                    const updatedDataCompany = {
                        ...postDataFormCsa,
                        id_business_unit: dataRequest.id_business_unit,
                    };
                    formCsa = await UpdateFormCsa(updatedDataCompany);
                } else {
                    const postdataRequest = {
                        ...dataRequest,
                        id: dataRequest.id,
                        id_business_unit : postDataFormCsa.business_unit_other !== "" ? "-1" : postDataFormCsa.id_business_unit,
                        business_unit_other : postDataFormCsa.business_unit_other,
                        id_confidential_level: postDataFormCsa.id_confidential_level,
                    };
                    // console.log(postdataRequest);
                    let dataRequestForm = await UpdateRequests(postdataRequest);
                    isSaveSucess = (dataRequestForm.is_sucess === true && isSaveSucess === true) ? true : false;

                    formCsa = await UpdateFormCsa(postDataFormCsa);
                }
                isSaveSucess = (formCsa.is_sucess === true && isSaveSucess === true) ? true : false;

                if(isSaveSucess === true){
                    let isSendMail = await SendMailReturn(dataRequestWorkFlow.data[0]);
                    if( isSendMail === true ){
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                                iconHtml: IconAlertReturn,
                                title: "Return to user who request.",
                                showConfirmButton: false,
                                showCloseButton: true,
                                // showConfirmButton: false,
                            }).then(() => {
                                // props.nextStep();
                                history.push("/requests");
                            });
                    }else{
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to send email to user who request.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }
                }else{
                    Swal.close(); // Close loading spinner
                    Swal.fire({
                        iconHtml: IconAlertError,
                        title: "Failed to save data.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                }
            } catch(error){
                console.log(error);
            }
        })();
    };

    const ApprovalReturn = async (id) => {
        let url = ConfigData.API_URL + "request/approval-return/" + id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url,{}, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateRequests = async (data) => {
        let url = ConfigData.API_URL + "request/" + data.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateFormCsa = async (data) => {
        let url = ConfigData.API_URL + "form-csa/" + data.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // console.log(respData);
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const AddRequestWorkFlow = async (dataRequestWorkFlows) => {
        let url = ConfigData.API_URL + "request-workflow/";
        let data = dataRequestWorkFlows;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.post(url, data, { headers: { 
                                'Authorization' : token,
                                'User': userId,
                            }, responseType: "json" }
                        );
            let respData = resp.data;
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const SendMailReturn = async (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.send_to.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 2,
            "request_id": data.id_request,
        };
        try {
            const resp = await axios.post(url, dataReq, { headers: { 
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        } catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const OnSetFileAgreementDoc = (file) => {
        const fileName = (file.length > 0) ? file[0].name : "";
        setIsSaveEnabled(true);
        // console.log(isSaveEnabled);
        setFileDraftAgreementDoc(file);
        validation.handleChange('draft_doc')(fileName);
        validationDraft.handleChange('draft_doc')(fileName);
    };

    const OnSetFileCounterpartyDoc = (file) => {
        const fileName = (file.length > 0) ? file[0].name : "";
        setIsSaveEnabled(true);
        setFileCounterpartyDoc(file);
        validation.handleChange('counterparty_doc')(fileName);
        validationDraft.handleChange('counterparty_doc')(fileName);
    };

    const OnSetFileScopeOfWorkDoc = (files) => {
        setIsSaveEnabled(true);
        setFileScopeOfWorkDoc(files);
        updateScopeOfWorkDocNames(files)
    };

    const updateScopeOfWorkDocNames = (files) => {
        setIsSaveEnabled(true);
        const names = files.map(file => file.name).join(",");
        validation.handleChange('scope_of_work_doc')(names);
        validationDraft.handleChange('scope_of_work_doc')(names);
        setFileNameScopeOfWorkMultiple(names);
    };

    const OnSetFileIsSlaDoc = (file) => {
        const fileName = (file.length > 0) ? file[0].name : "";
        setIsSaveEnabled(true);
        setFileIsSlaDoc(file);
        validation.handleChange('is_sla_doc')(fileName);
        validationDraft.handleChange('is_sla_doc')(fileName);
    };

    const OnSetFileConsiderDoc = (files) => {
        setIsSaveEnabled(true);
        setFileConsiderDoc(files);
        updateConsiderDocNames(files);
    };

    const updateConsiderDocNames = (files) => {
        setIsSaveEnabled(true);
        const names = files.map(file => file.name).join(",");
        setFileNameMultiple(names);
    };

    const handleRadioChangeIsSla = (event) => {
        setIsSaveEnabled(true);
        // isEnabled(true);
        setSelectedRadioIsSla(event.target.value);
        if( event.target.value === "1"){
            // setInputIdForUpload({ nameInputIsSla: "isSlaDocActive" });
            setInputIdIsSla(false);
            // console.log(InputIdForUpload.nameInputIsSla);
        } else {
            // setInputIdForUpload({ nameInputIsSla: "isSlaDocInActive" });
            setInputIdIsSla(true);
            // console.log(InputIdForUpload.nameInputIsSla);
        }
        validation.handleChange("is_sla")(event.target.value);
        validationDraft.handleChange("is_sla")(event.target.value);
    };

    const handleRadioChangePdpa = (event) => {
        setIsSaveEnabled(true);
        setSelectedRadioPdpa(event.target.value);
        validation.handleChange("share_pdpa")(event.target.value);
        validationDraft.handleChange("share_pdpa")(event.target.value);
    };

    const handleRadioChangeTerm = (event) => {
        setSelectedTerm(event.target.value);
        if(event.target.value === "1"){
            setTermStartDisabled(false);
            setTermEndDisabled(false);
            setOtherTermDisabled(true);
        } else if(event.target.value === "2") {
            setTermStartDisabled(true);
            setTermEndDisabled(true);
            setOtherTermDisabled(false);
        }
        validation.handleChange("selectedTerm")(event.target.value);

    };

    const handleRadioChangeVat = (event) => {
        setIsSaveEnabled(true);
        setSelectedRadioVat(event.target.value);
        validation.handleChange("vat")(event.target.value);
        validationDraft.handleChange("vat")(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setIsSaveEnabled(true);
        setStartDate(event.target.value);
        validation.handleChange("term_start_date")(event.target.value);  // Trigger validation
        validationDraft.handleChange("term_start_date")(event.target.value);  // Trigger validation
        // console.log(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setIsSaveEnabled(true);
        setEndDate(event.target.value);
        validation.handleChange("term_end_date")(event.target.value);  // Trigger validation
        validationDraft.handleChange("term_end_date")(event.target.value);  // Trigger validation
    };
    // console.log(selectedTerm);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataDetail.id || 0,
            project_name: dataDetail.project_name || "",
            draft_agreement: dataDetail.draft_agreement || "",
            draft_doc: dataDetail.draft_doc || "",
            id_business_unit: dataDetail.id_business_unit || "",
            business_unit_other: dataDetail.business_unit_other || "",
            business_unit_act_as: dataDetail.business_unit_act_as || "",
            counterparty_name: dataDetail.counterparty_name || "",
            counterparty_doc: dataDetail.counterparty_doc || "",
            counterparty_act_as: dataDetail.counterparty_act_as || "",
            purpose: dataDetail.purpose || "",
            place_of_work: dataDetail.place_of_work || "",
            scope_of_work: dataDetail.scope_of_work || "",
            scope_of_work_doc: dataDetail.scope_of_work_doc || "",
            is_sla: dataDetail.is_sla || "",
            is_sla_doc: dataDetail.is_sla_doc || "",
            consider_doc: dataDetail.consider_doc || "",
            term_start_date: dataDetail.term_start_date || "",
            term_end_date: dataDetail.term_end_date || "",
            term_other: dataDetail.term_other || "",
            term_renewal_condition: dataDetail.term_renewal_condition || "",
            service_fee_total: dataDetail.service_fee_total || "",
            vat: dataDetail.vat || "",
            service_fee_payment_type: dataDetail.service_fee_payment_type || "",
            service_fee_payment_detail: dataDetail.service_fee_payment_detail || "",
            service_fee_credit_term: dataDetail.service_fee_credit_term || "",
            performance_bond: dataDetail.performance_bond || "",
            penalty_delay_service: dataDetail.penalty_delay_service || "",
            penalty_not_delivered: dataDetail.penalty_not_delivered || "",
            penalty_absence_work: dataDetail.penalty_absence_work || "",
            penalty_others: dataDetail.penalty_others || "",
            termination_detail: dataDetail.termination_detail || "",
            ip_ownership_detail: dataDetail.ip_ownership_detail || "",
            warranty: dataDetail.warranty || "",
            stamp_duty: dataDetail.stamp_duty || "",
            other_fee: dataDetail.other_fee || "",
            other_conditions: dataDetail.other_conditions || "",
            id_language: dataDetail.id_language || "",
            id_confidential_level: dataDetail.id_confidential_level || "",
            share_pdpa: dataDetail.share_pdpa || "",
            user_comment: dataDetail.user_comment || "",
            check_user_email: dataFormRequest?.create_request_by || dataDetail?.create_request_by || userEmail,
            require_handled_by: dataFormRequest?.require_handled_by || dataDetail?.require_handled_by || "",
            approve_request_by: dataFormRequest?.approve_request_by || dataDetail?.approve_request_by || "",
            approver_comment: dataFormRequest?.approver_comment || dataDetail?.approver_comment || "",
            create_request_by: dataFormRequest?.create_request_by || dataDetail?.create_request_by || "",
            check_request_no: dataFormRequest?.request_no || "",
            selectedTerm: dataFormCsa.id === 0 || dataFormRequest.request_no === "draft" ? dataDetail.selectedTerm : selectedTerm || "" ,
        },
        validationSchema: Yup.object({
            draft_agreement: Yup.string().required("กรุณาเลือก/Please select"),
            // draft_doc: Yup.string().required("Please attach file a Draft Agreement."),
            draft_doc: Yup.string().when("draft_agreement", {
                is: (draft_agreement) => draft_agreement === "1",
                then: Yup.string().required("กรุณาแนบไฟล์/Please attachment file"),
                otherwise: Yup.string().notRequired(),
            }),
            id_business_unit: Yup.string().when("isDisabled", {
                is: (isDisabled) => !isDisabled,
                then: Yup.string().required("กรุณาเลือก/Please select"),
                otherwise: Yup.string().notRequired(),
            }),
            business_unit_other: Yup.string().when(["id_business_unit", "isDisabled"], {
                is: (id_business_unit, isDisabled) => !isDisabled && id_business_unit === "-1",
                then: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
                otherwise: Yup.string().notRequired(),
            }),
            business_unit_act_as: Yup.string().required("กรุณาเลือก/Please select"),
            counterparty_name: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            counterparty_doc: Yup.string().required("กรุณาแนบไฟล์/Please attachment file"),
            counterparty_act_as: Yup.string().required("กรุณาเลือก/Please select"),
            purpose: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            place_of_work: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            scope_of_work: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            scope_of_work_doc: Yup.string().required("กรุณาแนบไฟล์/Please attachment file"),
            is_sla: Yup.string().required("กรุณาเลือก/Please select"),
            // is_sla_doc: Yup.string().required("Please attach file a Service Standards."),
            is_sla_doc: Yup.string().when("is_sla", {
                is: (is_sla) => is_sla === "1",
                then: Yup.string().required("กรุณาแนบไฟล์/Please attachment file"),
                otherwise: Yup.string().notRequired(),
            }),
            service_fee_total: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out").matches(/^[0-9,.]+$/, "กรุณากรอกเฉพาะตัวเลข/Please enter only numbers"),
            vat: Yup.string().required("กรุณาเลือก/Please select"),
            service_fee_payment_type: Yup.string().required("กรุณาเลือก/Please select"),
            service_fee_payment_detail: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            service_fee_credit_term: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            performance_bond: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            // penalty_delay_service: Yup.string().required("Please enter a Penalty Delay Service."),
            // penalty_not_delivered: Yup.string().required("Please enter a Penalty if work is not delivered as agreed."),
            // penalty_absence_work: Yup.string().required("Please enter a Absence from work."),
            termination_detail: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            ip_ownership_detail: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            warranty: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            stamp_duty: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            id_language: Yup.string().required("กรุณาเลือก/Please select"),
            id_confidential_level: Yup.string().required("กรุณาเลือก/Please select"),
            share_pdpa: Yup.string().required("กรุณาเลือก/Please select"),
            user_comment: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
            require_handled_by: Yup.string().email("กรุณาระบุอีเมลนักกฎหมาย/Please provide the lawyer's email address")
            .matches(englishEmailRegex, "Email must be a valid English email address"),
            // approve_request_by: Yup.string().required("กรุณาระบุอีเมลผู้อนุมัติ/Please provide the approver's email address")
            // .email("กรุณาระบุอีเมลผู้อนุมัติ/Please provide the approver's email address")
            // .test('is-not-current-user', 'ไม่สามารถใช้อีเมลของผู้ใช้งานปัจจุบัน/You cannot use your own email', function(value) {
            //     return value !== userEmail ; 
            // }),
            approve_request_by: Yup.string().when("check_request_no", {
                is: (check_request_no) => check_request_no === undefined || check_request_no === "draft",
                then: Yup.string().required("กรุณาระบุอีเมลผู้อนุมัติ/Please provide the approver's email address")
                .email("กรุณาระบุอีเมลผู้อนุมัติ/Please provide the approver's email address").matches(englishEmailRegex, "Email must be a valid English email address")
                .test('is-not-current-user', 'ไม่สามารถใช้อีเมลของผู้ใช้งานปัจจุบัน/You cannot use your own email', function(value) {
                    return value?.toLowerCase() !== userEmail; 
                }),
                otherwise: Yup.string().required("กรุณาระบุอีเมลผู้อนุมัติ/Please provide the approver's email address")
                .email("กรุณาระบุอีเมลผู้อนุมัติ/Please provide the approver's email address").matches(englishEmailRegex, "Email must be a valid English email address"),
            }),
            create_request_by: Yup.string().when("check_user_email", {
                is: (check_user_email) => getDomain(check_user_email) === "rabbitholdings.co.th" || dataUser.business_unit?.toLowerCase() === "rabbit holdings public company limited",
                then: Yup.string().required("กรุณาระบุอีเมลผู้ประสานงาน/Please provide the coordinator's email address")
                .email("กรุณาระบุอีเมลผู้ประสานงาน/Please provide the coordinator's email address")
                .matches(englishEmailRegex, "Email must be a valid English email address"),
                otherwise: Yup.string().nullable(),
            }),
            selectedTerm: Yup.string().required("กรุณาเลือก/Please select"),
            term_start_date: Yup.string().when("selectedTerm", {
                is: (selectedTerm) => selectedTerm === "1",
                then: Yup.string().required("กรุณาเลือก/Please select"),
                otherwise: Yup.string().notRequired(),
            }),
            term_end_date: Yup.string().when("selectedTerm", {
                is: (selectedTerm) => selectedTerm === "1",
                then: Yup.string().required("กรุณาเลือก/Please select"),
                otherwise: Yup.string().notRequired(),
            }),
            term_other: Yup.string().when("selectedTerm", {
                is: (selectedTerm) => selectedTerm === "2",
                then: Yup.string().trim().required("กรุณากรอกข้อมูล/Please fill out"),
                otherwise: Yup.string().notRequired(),
            }),
        }),
        onSubmit: (values) => {
            values.service_fee_total = values.service_fee_total.replace(/,/g, "");
            values.term_start_date = values.term_start_date !== "" ? values.term_start_date : "";
            values.term_end_date = values.term_end_date !== "" ? values.term_end_date : "";
            values.id_language = parseInt(values.id_language);
            values.id_workflow_status = dataFormRequest.id_workflow_status;
            values.request_no = dataFormRequest.request_no; // check request_no
            values.consider_doc = fileNameMultiple.toString() !== "" ? fileNameMultiple.toString() : values.consider_doc;
            values.scope_of_work_doc = fileNameScopeOfWorkMultiple.toString() !== "" ? fileNameScopeOfWorkMultiple.toString() : values.scope_of_work_doc;
            values.file_draft_doc = fileDraftAgreementDoc;
            values.file_counterparty_doc = fileCounterpartyDoc;
            values.file_scope_of_work_doc = fileScopeOfWorkDoc;
            values.file_is_sla_doc = fileIsSlaDoc;
            values.file_consider_doc = fileConsiderDoc;
            values.legal_authorized = emailLegalAuthorized;
            values.legal_admin = emailLegalAdmin;
            values.approve_request_by = values.approve_request_by?.toLowerCase();
            values.require_handled_by = values.require_handled_by?.toLowerCase();
            values.create_request_by = values.create_request_by?.toLowerCase();
            values.id_business_unit = companyDisabled === true ? "-1" : values.id_business_unit;
            values.business_unit_other = otherCompanyDisabled === true ? "" : values.business_unit_other;
            onSave(values);
        }
    });

    const validationDraft = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataDetail.id || 0,
            project_name: dataDetail.project_name || "",
            draft_agreement: dataDetail.draft_agreement || "",
            draft_doc: dataDetail.draft_doc || "",
            id_business_unit: dataDetail.id_business_unit || "",
            business_unit_other: dataDetail.business_unit_other || "",
            business_unit_act_as: dataDetail.business_unit_act_as || "",
            counterparty_name: dataDetail.counterparty_name || "",
            counterparty_doc: dataDetail.counterparty_doc || "",
            counterparty_act_as: dataDetail.counterparty_act_as || "",
            purpose: dataDetail.purpose || "",
            place_of_work: dataDetail.place_of_work || "",
            scope_of_work: dataDetail.scope_of_work || "",
            scope_of_work_doc: dataDetail.scope_of_work_doc || "",
            is_sla: dataDetail.is_sla || "",
            is_sla_doc: dataDetail.is_sla_doc || "",
            consider_doc: dataDetail.consider_doc || "",
            term_start_date: dataDetail.term_start_date || "",
            term_end_date: dataDetail.term_end_date || "",
            term_other: dataDetail.term_other || "",
            term_renewal_condition: dataDetail.term_renewal_condition || "",
            service_fee_total: dataDetail.service_fee_total || "",
            vat: dataDetail.vat || "",
            service_fee_payment_type: dataDetail.service_fee_payment_type || "",
            service_fee_payment_detail: dataDetail.service_fee_payment_detail || "",
            service_fee_credit_term: dataDetail.service_fee_credit_term || "",
            performance_bond: dataDetail.performance_bond || "",
            penalty_delay_service: dataDetail.penalty_delay_service || "",
            penalty_not_delivered: dataDetail.penalty_not_delivered || "",
            penalty_absence_work: dataDetail.penalty_absence_work || "",
            penalty_others: dataDetail.penalty_others || "",
            termination_detail: dataDetail.termination_detail || "",
            ip_ownership_detail: dataDetail.ip_ownership_detail || "",
            warranty: dataDetail.warranty || "",
            stamp_duty: dataDetail.stamp_duty || "",
            other_fee: dataDetail.other_fee || "",
            other_conditions: dataDetail.other_conditions || "",
            id_language: dataDetail.id_language || "",
            id_confidential_level: dataDetail.id_confidential_level || "",
            share_pdpa: dataDetail.share_pdpa || "",
            user_comment: dataDetail.user_comment || "",
            check_user_email: dataFormRequest?.create_request_by || dataDetail?.create_request_by || userEmail,
            check_request_no: dataFormRequest?.request_no || "",
            require_handled_by: dataFormRequest?.require_handled_by || dataDetail?.require_handled_by || "",
            approve_request_by: dataFormRequest?.approve_request_by || dataDetail?.approve_request_by || "",
            approver_comment: dataFormRequest?.approver_comment || dataDetail?.approver_comment || "",
            create_request_by: dataFormRequest?.create_request_by || dataDetail?.create_request_by || "",
        },
        onSubmit: (values) => {
            values.service_fee_total = values.service_fee_total.replace(/,/g, "");
            values.id_language = values.id_language !== "" ? parseInt(values.id_language) : null;
            values.term_start_date = termStartDisabled !== true ? values.term_start_date : null;
            values.term_end_date = termEndDisabled !== true ? values.term_end_date : null;
            values.term_other = otherTermDisabled !== true ? values.term_other : null;
            values.request_no = dataFormRequest.request_no; // check request_no
            values.consider_doc = fileNameMultiple.toString() !== "" ? fileNameMultiple.toString() : values.consider_doc;
            values.scope_of_work_doc = fileNameScopeOfWorkMultiple.toString() !== "" ? fileNameScopeOfWorkMultiple.toString() : values.scope_of_work_doc;
            values.file_draft_doc = fileDraftAgreementDoc;
            values.file_counterparty_doc = fileCounterpartyDoc;
            values.file_scope_of_work_doc = fileScopeOfWorkDoc;
            values.file_is_sla_doc = fileIsSlaDoc;
            values.file_consider_doc = fileConsiderDoc;
            values.id_business_unit = companyDisabled === true ? "-1" : values.id_business_unit;
            values.business_unit_other = otherCompanyDisabled === true ? "" : values.business_unit_other;
            values.approve_request_by = values.approve_request_by?.toLowerCase() === userEmail ? "" : values.approve_request_by?.toLowerCase();
            values.require_handled_by = values.require_handled_by?.toLowerCase();
            values.create_request_by = values.create_request_by?.toLowerCase();
            onSaveDraft(values);
            setStartDate(termStartDisabled !== true ? values.term_start_date : null);
            setEndDate(termEndDisabled !== true ? values.term_end_date : null);
            setDataTermOther(otherTermDisabled !== true ? values.term_other : "");
            // validation.values.term_other = otherTermDisabled !== true ? values.term_other : "";
            // setFieldValue('term_start_date', termStartDisabled !== true ? values.term_start_date : null);
            // setFieldValue('term_end_date', termEndDisabled !== true ? values.term_end_date : null);
            // setFieldValue('term_other', otherTermDisabled !== true ? values.term_other : "");
        }
    });

    const validationReturn = useFormik({
        enableReinitialize: true,
        initialValues: {
            approver_comment: dataFormRequest?.approver_comment || dataDetail?.approver_comment || "",
        },
        validationSchema: Yup.object({
            // approver_comment: Yup.string().when('showCommentApprover', {
            //     is: false,
            //     then: Yup.string().required('Please enter a Comment.'),
            //     otherwise: Yup.string(),
            // }),
            approver_comment: Yup.string().trim().required("กรุณาระบุความเห็นเพิ่มเติม"),
        }),
        onSubmit: (values) => {
            // console.log(values);
        }
    });

    const ValidateNumber = (event) => {
         // Enhanced regex for numbers, commas, decimals, spaces, and '.00' after digits
        const regex = /^(?:[0-9,.\s]*|\.[0-9]{0,2}(?:\s|$)|[0-9,.\s]+\.[0-9]{1,2}(?:\s|$)|[0-9,]+\.00)$/;

        // Validate input against the regex
        if (event.target.value === "" || !regex.test(event.target.value)) {
            // Remove invalid characters
            event.target.value = event.target.value.slice(0, event.target.value.length - 1);
        } else if (event.target.key === '.') {
            const currentValue = event.target.value;
            const cursorPosition = event.target.selectionStart;

            // Check for dot at the end or after a digit
            if ((cursorPosition === currentValue.length && /[0-9]/.test(currentValue[cursorPosition - 1])) || !currentValue.includes('.')) {
                event.target.value += '.00';
            } else {
            // Prevent adding a second decimal point
                event.preventDefault();
            }
        }

        // Extract integer and decimal parts (considering ".00" as two decimals)
        const value = event.target.value;
        const parts = value.split('.');
        const integerPart = parts[0].replace(/,/g, "");

        let decimalPart = "";
        if (parts.length === 2) {
            if(parts[1] === ""){
                decimalPart = '00';
            } else {
                decimalPart = parts[1].slice(0, 2); // Limit decimals to 2
            }
        } else if (parts.length === 3 && parts[2] === '00') {
            // Handle ".00" case (consider as two decimals)
            decimalPart = '00';
        }
        // Format integer part with commas every 3 digits
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d|\.))/g, ',');

        // Update input value with formatted number
        event.target.value = `${formattedInteger}${decimalPart ? `.${decimalPart}` : ""}`;

        return event;
    };

    return (
        <React.Fragment>
            <div id="form-request">
            <Form className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            validationDraft.handleSubmit();
                            validationReturn.handleSubmit();
                            return false;
                        }}
                    >
            <div id="section-page-1-2">
                <Col xl={12} className="mx-auto">
                    <div>​
                        <span className="span-title">ข้อมูลทั่วไป (Request General Information)</span>
                    </div>
                </Col>
                <br/>
                <Col md={12} className="mx-auto">
                    <Row>
                        <Col md={5}>
                            <FormGroup>
                                <Label className="label-title">ประเภทคำขอ (Type of Request)</Label>
                                <Input
                                type="text"
                                name="typeOfRequest"
                                value={dataFormRequest.name_en_request_type ? dataFormRequest.name_en_request_type || "" : formTemp.name_en_request_type || ""}
                                // onChange={onInputChanged}
                                disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Label className="label-title">เรื่อง (Matters)</Label>
                                <Input
                                type="text"
                                name="typeOfContract"
                                value={dataFormRequest.name_en_sub_request_type ? dataFormRequest.name_en_sub_request_type || "" : formTemp.name_en_sub_request_type || ""}
                                // onChange={onInputChanged}
                                disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                                <Label className="label-title" htmlFor="txtProjectName">ชื่อโครงการ (Project Name)</Label>
                                <Input 
                                    id="txtProjectName" 
                                    name="project_name"
                                    autoComplete={autoCompletes}
                                    className="txt-placeholder"
                                    placeholder="ระบุชื่อโครงการ(ถ้ามี)"
                                    onChange={(e) => {
                                        validation.handleChange(e);
                                        validationDraft.handleChange(e);
                                        setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.project_name || ""}
                                    disabled={isDisableds}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                                <Label className="label-title">ต้องการให้ฝ่ายกฎหมายดำเนินการ (Request Legal Team to)<span className="msg-require">*</span></Label>
                                <i className="mdi mdi-help-circle icon-hint-right" id="PopoverRight"></i>
                                <div className="hstack flex-wrap gap-2">
                                    <UncontrolledPopover placement="right" target="PopoverRight" >
                                        <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> หากเป็นกรณีแก้ไขเปลี่ยนแปลงรายละเอียดของสัญญาเดิม ขอให้แนบสัญญาเดิมประกอบมาด้วย<br/>In case of amendment of the existing agreement, please attach the existing agreement file.</PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                                <Select
                                    id="ddlDraft" 
                                    name="draft_agreement"
                                    value={selectedDraft}
                                    className="txt-placeholder"
                                    placeholder={"เลือกความต้องการในการดำเนินการ"}
                                    onChange={(selected) => {
                                        setSelectedDraft(selected);
                                        const selectedValue = (selected !== null) ? selected.value : "";
                                        validation.handleChange("draft_agreement")((selected !== null) ? selected.value : "");
                                        validationDraft.handleChange("draft_agreement")((selected !== null) ? selected.value : "");
                                        setIsSaveEnabled(true);
                                        if( selectedValue === "1"){
                                            setInputIdDraft(false);
                                        } else {
                                            setInputIdDraft(true);
                                        }
                                    }}
                                    options={ddlDraft}
                                    isClearable={true}
                                    isSearchable={true}
                                    invalid={ validation.touched.draft_agreement && validation.errors.draft_agreement ? true : false }
                                    isDisabled={isDisableds}
                                />
                                { validation.touched.draft_agreement && validation.errors.draft_agreement ? 
                                    ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.draft_agreement}</FormFeedback> ) : false 
                                }
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            {/* <Label>กรุณาแนบไฟล์ Draft Agreement<span className="msg-require">*</span></Label> */}
                            <SingleUploadfile inputID="draftDoc" folder={dataFormRequest.request_no} dataFile={fileDraftAgreementDoc} setDataFile={OnSetFileAgreementDoc} isDisable={InputIdDraft} />
                            { validation.touched.draft_doc && validation.errors.draft_doc ? 
                                ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.draft_doc}</FormFeedback> ) : false 
                            }
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <div className="line-border"></div>
                </Col>
                <Col xl={12} className="mx-auto">
                    <div>
                        <span className="span-title">ชื่อคู่สัญญา (The Parties)</span>
                    </div>
                </Col>
                <br/>
                <Col md={12} className="mx-auto">
                    <Row>
                        <Col md={5}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverRightCompanyName"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="right" target="PopoverRightCompanyName">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุว่าจะเข้าทำสัญญาในนามของบริษัทในเครือรายใด หาก user ต้องการระบุชื่อบริษัทที่ไม่มีในรายชื่อบริษัท โปรดเลือก Others และกรอกชื่อบริษัทดังกล่าว<br/>Please indicate the company or affiliate name who is the party. If the party is not listed here, please select 'Others' and provide such company's name.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">ทำสัญญาในนามของ (Company Name)<span className="msg-require">*</span></Label>
                            <Select id="ddlBusinessUnit"
                                name="id_business_unit"
                                value={selectCompany}
                                className="txt-placeholder"
                                placeholder={companyPlaceholder}
                                onChange={(selected) => {
                                    setSelectCompany(selected);
                                    validation.handleChange("id_business_unit")((selected !== null) ? selected.value : "");
                                    validationDraft.handleChange("id_business_unit")((selected !== null) ? selected.value : "");
                                    // handleSelectChangeCompany(selected);
                                    setIsSaveEnabled(true);
                                    if (selected && selected.value) {
                                        LoadLegalAuthorized(selected.value);
                                    }
                                }}
                                options={dataCompany}
                                isClearable={true}
                                isSearchable={true}
                                invalid={ validation.touched.id_business_unit && validation.errors.id_business_unit ? true : false }
                                isDisabled={companyDisabled} // Set disabled based on the variable
                            />
                            { validation.touched.id_business_unit && validation.errors.id_business_unit ? 
                                ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.id_business_unit}</FormFeedback> ) : false 
                            }
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    name="checkbox_business_unit_other"
                                    checked={isCheckedCompanyOther}
                                    onChange={handleCheckboxChangeCompanyOther}
                                    disabled={isDisableds}
                                    />
                                <Label className="label-title"> อื่นๆ (Others)</Label>
                                <Input
                                type="text"
                                name="business_unit_other"
                                className="txt-placeholder"
                                autoComplete={autoCompletes}
                                placeholder={otherCompanyPlaceholder}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={otherCompanyDisabled === true ? "" : validation.values.business_unit_other || ""}
                                invalid={validation.touched.business_unit_other && validation.errors.business_unit_other ? true : false}
                                disabled={otherCompanyDisabled}
                                />
                                {validation.touched.business_unit_other && validation.errors.business_unit_other ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.business_unit_other}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Label className="label-title">โดยเป็นฝ่าย (Act as)<span className="msg-require">*</span></Label>
                                <Select
                                    id="businessUnitActAs"
                                    name="business_unit_act_as"
                                    value={selectCompanyActAs}
                                    className="txt-placeholder"
                                    placeholder={"เลือกฝ่ายการดำเนินการ"}
                                    onChange={(selected) => {
                                        setSelectCompanyActAs(selected);
                                        validation.handleChange("business_unit_act_as")((selected !== null) ? selected.value : "");
                                        validationDraft.handleChange("business_unit_act_as")((selected !== null) ? selected.value : "");
                                        setIsSaveEnabled(true);
                                    }}
                                    options={optionDdlCompanyActAs.filter(
                                        (option) => !selectCounterActAs || (selectCounterActAs && option.value !== selectCounterActAs.value)
                                    )}
                                    isClearable={true}
                                    isSearchable={true}
                                    invalid={validation.touched.business_unit_act_as && validation.errors.business_unit_act_as ? true : false}
                                    isDisabled={isDisableds}
                                    />
                                { validation.touched.business_unit_act_as && validation.errors.business_unit_act_as ? 
                                    ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.business_unit_act_as}</FormFeedback> ) : false 
                                }
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverBottomCounterparty"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="right" target="PopoverBottomCounterparty">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดแนบสำเนาหนังสือรับรองบริษัท/ห้างหุ้นส่วนฯ และ/หรือเอกสารทางราชการอื่น ๆ (เช่น บัตรประชาชน หนังสือเดินทาง)<br/>Please attach the copy of company affidavit/partnership and/or other corporate documents (ex. ID card and passport)</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">ชื่อคู่สัญญาอีกฝ่าย (Counterparty's Name) <span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                name="counterparty_name"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="ระบุชื่อคู่สัญญาอีกฝ่าย"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.counterparty_name || ""}
                                invalid={validation.touched.counterparty_name && validation.errors.counterparty_name ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.counterparty_name && validation.errors.counterparty_name ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.counterparty_name}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <Label className="label-title">กรุณาแนบไฟล์ Counterparty<span className="msg-require">*</span></Label>
                            <SingleUploadfile inputID="counterpartyDoc" folder={dataFormRequest.request_no} dataFile={fileCounterpartyDoc} setDataFile={OnSetFileCounterpartyDoc} isDisable={isDisableds}/>
                            { validation.touched.counterparty_doc && validation.errors.counterparty_doc ? 
                                ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.counterparty_doc}</FormFeedback> ) : false 
                            }
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                            <br/>
                                <Label className="label-title">โดยเป็นฝ่าย (Act as)<span className="msg-require">*</span></Label>
                                <Select
                                    id="counterpartyActAs"
                                    name="counterparty_act_as"
                                    value={selectCounterActAs}
                                    className="txt-placeholder"
                                    placeholder={"เลือกฝ่ายการดำเนินการ"}
                                    onChange={(selected) => {
                                        setSelectCounterActAs(selected);
                                        validation.handleChange("counterparty_act_as")((selected !== null) ? selected.value : "");
                                        validationDraft.handleChange("counterparty_act_as")((selected !== null) ? selected.value : "");
                                        setIsSaveEnabled(true);
                                    }}
                                    options={optionDdlCounterActAs.filter(
                                        (option) => !selectCompanyActAs || (selectCompanyActAs && option.value !== selectCompanyActAs.value)
                                    )}
                                    isClearable={true}
                                    isSearchable={true}
                                    invalid={validation.touched.counterparty_act_as && validation.errors.counterparty_act_as ? true : false}
                                    isDisabled={isDisableds}
                                    />
                                { validation.touched.counterparty_act_as && validation.errors.counterparty_act_as ? 
                                    ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.counterparty_act_as}</FormFeedback> ) : false 
                                }
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </div>
                <Col md={12}>
                    <div className="line-border"></div>
                </Col>
            <div id="section-page-2-1">
                <Col xl={12} className="mx-auto">
                    <div>
                        <span className="span-title">รายละเอียดการว่าจ้าง (Service Details)</span>
                    </div>
                </Col>
                <br/>
                <Col md={12} className="mx-auto">
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverBottomPurpose"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="bottom" target="PopoverBottomPurpose">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุวัตถุประสงค์ว่ามีการจ้าง/บริการนี้เพื่ออะไร เช่น ปรับปรุงหรือพัฒนาระบบงาน เป็นต้น<br/>Please specify the purpose of the service, such as to improve the workplace system.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">วัตถุประสงค์ของการจ้าง/บริการ (Purpose)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                name="purpose"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุวัตถุประสงค์ว่ามีการจ้าง/บริการนี้เพื่ออะไร Please specify the purpose of the service, such as to improve the workplace system."
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.purpose || ""}
                                invalid={validation.touched.purpose && validation.errors.purpose ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.purpose && validation.errors.purpose ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.purpose}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverBottomPlaceOfPer"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="bottom" target="PopoverBottomPlaceOfPer">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i>โปรดระบุรายละเอียดที่อยู่โดยละเอียด กล่าวคือ บ้านเลขที่ อาคาร ชั้น ห้อง ฯลฯ<br/>Please specify the address of place of performance i.e. house no., building, floor, room etc.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">สถานที่ที่ปฏิบัติงาน (Place of Performance)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="placeOfWork"
                                name="place_of_work"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุรายละเอียดที่อยู่โดยละเอียด Please specify the address of place of performance."
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.place_of_work || ""}
                                invalid={validation.touched.place_of_work && validation.errors.place_of_work ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.place_of_work && validation.errors.place_of_work ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.place_of_work}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverBottomScope"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="bottom" target="PopoverBottomScope">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุรายละเอียดของขอบเขตงานตามสัญญานี้<br/>Please specify the details of scope of work.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">ขอบเขต และรายละเอียดการปฏิบัติงานตามสัญญา (Scope of Work)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="scopeOfWork"
                                name="scope_of_work"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุรายละเอียดของขอบเขตงานตามสัญญานี้ Please specify the details of scope of work."
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.scope_of_work || ""}
                                invalid={validation.touched.scope_of_work && validation.errors.scope_of_work ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.scope_of_work && validation.errors.scope_of_work ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.scope_of_work}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <br/>
                        <Col md={10}>
                            <Label className="label-title">โปรดแนบไฟล์ Scope of Work หรือเอกสารอื่นซึ่งแสดงขอบเขตและรายละเอียดของงาน<span className="msg-require">*</span></Label>
                            {/* <SingleUploadfile inputID="scopeOfWorkDoc" folder={dataFormRequest.request_no} dataFile={fileScopeOfWorkDoc} setDataFile={OnSetFileScopeOfWorkDoc} isDisable={isDisableds}/> */}
                            <MultipleUploadfile inputID="scopeOfWorkDoc" folder={dataFormRequest.request_no} dataFile={fileScopeOfWorkDoc} setDataFile={OnSetFileScopeOfWorkDoc} isDisable={isDisableds}/>
                                { validation.touched.scope_of_work_doc && validation.errors.scope_of_work_doc ? 
                                    ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.scope_of_work_doc}</FormFeedback> ) : false 
                                }
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <br/>
                            <Label className="label-title">มาตรฐาน/ระดับการให้บริการ (Service Standards/SLA)<span className="msg-require">*</span></Label>
                            <div className="mt-4 mt-lg-0 label-ddl">
                                <div className="form-check form-check-inline">
                                    <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_sla"
                                    id="inlineRadio1"
                                    value="1"
                                    checked={selectedRadioIsSla === "1"}
                                    onChange={handleRadioChangeIsSla}
                                    disabled={isDisableds}
                                    />
                                    <Label className="form-check-label" htmlFor="inlineRadio1">
                                    มี (โปรดระบุรายละเอียดหรือแนบไฟล์) (Details of SLA are as follows / attached)
                                    </Label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_sla"
                                    id="inlineRadio2"
                                    value="0"
                                    checked={selectedRadioIsSla === "0"}
                                    onChange={handleRadioChangeIsSla}
                                    disabled={isDisableds}
                                    />
                                    <Label className="form-check-label" htmlFor="inlineRadio2">
                                    ไม่มี (None)
                                    </Label>
                                </div>
                            </div>
                            {validation.touched.is_sla && validation.errors.is_sla ? (
                            <FormFeedback type="invalid" style={{ display: "block" }}>
                                {validation.errors.is_sla}
                            </FormFeedback>
                            ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <SingleUploadfile inputID={"isSlaDoc"} folder={dataFormRequest.request_no} dataFile={fileIsSlaDoc} setDataFile={OnSetFileIsSlaDoc} isDisable={InputIdIsSla} />
                                { validation.touched.is_sla_doc && validation.errors.is_sla_doc ? 
                                    ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.is_sla_doc}</FormFeedback> ) : false 
                                }
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                            <br/>
                                <Label className="label-title">เอกสารอื่นๆ เพื่อพิจารณา (Attachment)</Label>
                                <i className="mdi mdi-help-circle icon-hint-no-padding" id="PopoverRightAttached1" onClick={togglePopover}></i>
                                <div className="hstack flex-wrap gap-2">
                                    <UncontrolledPopover placement="right" target="PopoverRightAttached1" isOpen={popoverOpen} toggle={togglePopover}>
                                        <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i>โปรดแนบเอกสารอื่นๆ ที่เกี่ยวข้องเพื่อประกอบการพิจารณา <br/>Please attach related documents for consideration</PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <MultipleUploadfile inputID="fileConsiderDoc" folder={dataFormRequest.request_no} dataFile={fileConsiderDoc} setDataFile={OnSetFileConsiderDoc} isDisable={isDisableds}/>
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <div className="line-border"></div>
                </Col>
            </div>
            <div id="section-page-3-1">
                <Col xl={12} className="mx-auto">
                    <div>
                        <span className="span-title">ระยะเวลาของสัญญา (Term)</span>
                    </div>
                    <br/>
                    <div>
                        <span className="span-term">เลือกระยะเวลาของสัญญา หากยังไม่ทราบวันที่เริ่มต้นและวันที่สิ้นสุดที่แน่นอน ให้เลือกระบุช่วงระยะเวลาของสัญญา<span className="msg-require">*</span></span>
                    </div>
                    <Col md={10}>
                        {validation.touched.selectedTerm && validation.errors.selectedTerm ? (
                        <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                            {validation.errors.selectedTerm}
                        </FormFeedback>
                        ) : false}
                    </Col>
                    
                </Col>
                <Col xl={12}>
                    <Row>
                        <Col md={10}>
                            <Input
                            className="input-radio-check-term-1"
                            type="radio"
                            name="check_term"
                            id="checkTerm1"
                            checked={selectedTerm === "1"}
                            value={1}
                            onChange={handleRadioChangeTerm}
                            disabled={isDisableds}
                            />
                            <Label className="label-title-date">วันเริ่มต้นและสิ้นสุดสัญญา (Commencement and Expiry Date)<span className="msg-require">*</span></Label>
                            <i className="mdi mdi-help-circle icon-hint" id="PopoverRightDate"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="right" target="PopoverRightDate">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i>โปรดระบุวัน/เดือน/ปี ที่แน่นอน แม้จะกำหนดเป็นระยะเวลา เช่น 1 ปีก็ตาม (วันที่อาจมีการเปลี่ยนแปลงในภายหลังได้ หากเกิดความล่าช้าในการเจรจาและลงนามในสัญญา)<br/>Please indicate specific date/month/year (the date can be changed at later stage if the negotiation and signing process is delayed).</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                        </Col>
                        <Row className="align-items-center">
                            <Col md={5} className="form-term-start-date">
                                <FormGroup>    
                                <Input
                                    type="date"
                                    // min={new Date().toISOString().split('T')[0]}
                                    id="termStartDate"
                                    name="term_start_date"
                                    className="form-control"
                                    value={startDate || ""}
                                    invalid={validation.touched.term_start_date && validation.errors.term_start_date ? true : false}
                                    onChange={handleStartDateChange}
                                    disabled={termStartDisabled}
                                />
                                {validation.touched.term_start_date && validation.errors.term_start_date ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.term_start_date}
                                </FormFeedback>
                                ) : false}
                                </FormGroup>
                                
                            </Col>
                            <i className="mdi mdi-arrow-right-thin arrow-right-calendar"></i>
                            <Col md={5} className="form-term-end-date">
                                <FormGroup>   
                                    <Input
                                    type="date"
                                    min={startDate}
                                    id="termEndDate"
                                    name="term_end_date"
                                    className="form-control"
                                    value={endDate || ""}
                                    invalid={validation.touched.term_end_date && validation.errors.term_end_date ? true : false}
                                    onChange={handleEndDateChange}
                                    disabled={termEndDisabled}
                                    />
                                    {validation.touched.term_end_date && validation.errors.term_end_date ? (
                                    <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                        {validation.errors.term_end_date}
                                    </FormFeedback>
                                    ) : false}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col md={10}>
                        <FormGroup>
                            {/* <Input
                                type="checkbox"
                                name="checkbox_business_unit_other"
                                checked={validation.values.term_other !== "" && validation.values.term_other !== null && !isCheckedTermOther ? !isCheckedTermOther : isCheckedTermOther}
                                onChange={handleCheckboxChangeTermOther}
                                disabled={isDisableds}
                            /> */}
                            <Input
                                className="input-radio-check-term-2"
                                type="radio"
                                name="check_term"
                                id="checkTerm2"
                                checked={selectedTerm === "2"}
                                value={2}
                                onChange={handleRadioChangeTerm}
                                disabled={isDisableds}
                                />
                            <Label className="label-title" htmlFor="checkTerm2">ยังไม่ทราบวันที่เริ่มต้นและวันที่สิ้นสุดแน่นอน</Label>
                            <br/>
                            <Label className="label-title-date">ระบุช่วงระยะเวลาสัญญา เช่น xx เดือน / ปี (Specify the contract period)<span className="msg-require">*</span></Label>
                            <Input 
                                id="txtTermOther" 
                                type="text"
                                name="term_other"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder={otherTermPlaceholder}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setDataTermOther(e.target.value);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={dataTermOther || ""}
                                invalid={validation.touched.term_other && validation.errors.term_other ? true : false}
                                disabled={otherTermDisabled}
                            />
                            {validation.touched.term_other && validation.errors.term_other ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.term_other}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverBottomRenew"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="bottom" target="PopoverBottomRenew">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุเงื่อนไขการต่ออายุสัญญา เช่น การขอต่ออายุสัญญาต้องแจ้งล่วงหน้า….เดือน โดยค่าจ้าง/ค่าบริการจะปรับขึ้นร้อยละ…. เป็นต้น<br/>Please specify the renewal term. Ex. The parties have to notify the other party in advance at least ….. Month and the service fee will increase at the rate of ... %.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">เงื่อนไขการต่ออายุสัญญา (Renewal Term)</Label>
                            <Input 
                                id="txtTermRenew" 
                                name="term_renewal_condition"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุเงื่อนไขการต่ออายุสัญญา Please specify the renewal term."
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.term_renewal_condition || ""}
                                disabled={isDisableds}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <div className="line-border"></div>
                </Col>
                <Col xl={12} className="mx-auto">
                    <div>
                        <span className="span-title">ค่าจ้าง/ค่าบริการ (Service Fee)</span>
                    </div>
                </Col>
                <br/>
                <Col xl={12}>
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                            <Label className="label-title">มูลค่ารวมของค่าบริการ (Service Fee)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtServiceFeeTotal"
                                name="service_fee_total"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณากรอก มูลค่ารวมของค่าบริการ"
                                onChange={(e) => {
                                    ValidateNumber(e);
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_fee_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ""}
                                invalid={validation.touched.service_fee_total && validation.errors.service_fee_total ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.service_fee_total && validation.errors.service_fee_total ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.service_fee_total}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <Label className="label-title">มูลค่ารวมข้างต้นนั้น (The Total Service Fee above)<span className="msg-require">*</span></Label>
                        </Col>
                        
                            <Col md={5} className="radio-term">
                                <div className="form-check form-check-inline">
                                    <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="vat"
                                    id="vat1"
                                    value="1"
                                    checked={selectedRadioVat === "1"}
                                    onChange={handleRadioChangeVat}
                                    disabled={isDisableds}
                                    />
                                    <Label className="form-check-label" htmlFor="vat1">
                                    รวม VAT (includes VAT)
                                    </Label>
                                </div>
                            </Col>
                            <Col md={5} className="radio-term">
                                <div className="form-check form-check-inline">
                                    <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="vat"
                                    id="vat2"
                                    value="2"
                                    checked={selectedRadioVat === "2"}
                                    onChange={handleRadioChangeVat}
                                    disabled={isDisableds}
                                    />
                                    <Label className="form-check-label" htmlFor="vat2">
                                    ไม่รวม VAT (excludes VAT)
                                    </Label>
                                </div>
                            </Col>
                        <Col md={10} className="radio-vat">
                            <Col md={5}>
                                <div className="form-check form-check-inline">
                                    <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="vat"
                                    id="vat3"
                                    value="3"
                                    checked={selectedRadioVat === "3"}
                                    onChange={handleRadioChangeVat}
                                    disabled={isDisableds}
                                    />
                                    <Label className="form-check-label" htmlFor="vat3">
                                    ไม่มี VAT (is not subject to VAT)​
                                    </Label>
                                </div>
                            </Col>
                        </Col>
                        <Col md={10} className="alert-validation-vat">
                            {validation.touched.vat && validation.errors.vat ? (
                            <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                {validation.errors.vat}
                            </FormFeedback>
                            ) : false}
                        </Col>
                        {/* <Col md={5}></Col> */}
                        <Col md={5}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverRightPayment"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="right" target="PopoverRightPayment">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> หากชำระครั้งเดียวให้ระบุว่าเมื่อไร เช่น ภายในกี่วันนับจากวันที่ลงนามในสัญญา เป็นต้น<br/>หากชำระเป็นรายงวด ขอให้ระบุรายละเอียดการชำระเงินในแต่ละงวด เช่น งวดที่ 1 ชำระเมื่อ..... งวดที่ 2 ชำระเมื่อ..... ฯลฯ<br/>If the payment will be made in lumpsum, please specify details ex. within ... days from the signing date.<br/>If the payment will be made in instalment, please specify details of each instalment ex. First instalment payable at ..... Second instalment payable at ....</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">รูปแบบการชำระเงิน (Payment Term)<span className="msg-require">*</span></Label>
                            <Select id="ddlServiceFeePaymentType"
                                name="service_fee_payment_type"
                                value={selectPaymentType}
                                className="txt-placeholder"
                                placeholder={"เลือกรูปแบบการชำระเงิน"}
                                onChange={(selected) => {
                                    setSelectPaymentType(selected);
                                    validation.handleChange("service_fee_payment_type")((selected !== null) ? selected.value : "");
                                    validationDraft.handleChange("service_fee_payment_type")((selected !== null) ? selected.value : "");
                                    setIsSaveEnabled(true);
                                }}
                                options={optionDdlPaymentType}
                                isClearable={true}
                                isSearchable={true}
                                invalid={ validation.touched.service_fee_payment_type && validation.errors.service_fee_payment_type ? true : false }
                                isDisabled={isDisableds}
                            />
                            { validation.touched.service_fee_payment_type && validation.errors.service_fee_payment_type ? 
                                ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.service_fee_payment_type}</FormFeedback> ) : false 
                            }
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                            <Label className="label-title">โปรดระบุ (please provide details)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtPaymentDetail"
                                name="service_fee_payment_detail"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="ระบุรายละเอียด"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_fee_payment_detail || ""}
                                invalid={validation.touched.service_fee_payment_detail && validation.errors.service_fee_payment_detail ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.service_fee_payment_detail && validation.errors.service_fee_payment_detail ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.service_fee_payment_detail}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverBottomCreditTerm"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="bottom" target="PopoverBottomCreditTerm">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุรายละเอียด เช่น ชำระภายใน 30 วัน นับแต่ได้รับใบแจ้งหนี้ หรือชำระภายในวันที่ xx ของเดือน หรือหากตกลงเป็นอย่างอื่น โปรดระบุ<br/>Please provide details ex. Payable within 30 days from the receiving of invoice or within the date of xth of each month. If it is otherwise specify, please provide.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">เครดิตการชำระเงิน หรือระยะเวลาถึงกำหนดชำระเงิน (Credit Term)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtCreditTerm"
                                name="service_fee_credit_term"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="ระบุรายละเอียดเครดิตการชำระเงิน"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.service_fee_credit_term || ""}
                                invalid={validation.touched.service_fee_credit_term && validation.errors.service_fee_credit_term ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.service_fee_credit_term && validation.errors.service_fee_credit_term ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.service_fee_credit_term}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverBottomPerform" ></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="bottom" target="PopoverBottomPerform">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุรูปแบบหลักประกัน วงเงิน และเงื่อนไขการคืนหลักประกัน เช่น วาง Bank Guarantee เมื่อใด, จำนวน …….. บาท, คืนให้วันใด<br/>Please specify the details of performance bond, credit limit and terms and conditions for returning of the bond, Ex. The date of bank guarantee submission, amount in THB and the guarantee returning date. </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">หลักประกันการปฏิบัติงาน (Performance Bond)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtPerformanceBond"
                                name="performance_bond"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุรูปแบบหลักประกัน วงเงิน และเงื่อนไขการคืนหลักประกัน Please specify the details of performance bond, credit limit and terms and conditions for returning of the bond."
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.performance_bond || ""}
                                invalid={validation.touched.performance_bond && validation.errors.performance_bond ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.performance_bond && validation.errors.performance_bond ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.performance_bond}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <div className="line-border"></div>
                </Col>
            </div>
            <div id="section-page-4-1">
                <Col xl={12} className="mx-auto">
                    <div>
                        <span className="span-title">ค่าปรับ (Penalty)</span>
                    </div>
                </Col>
                <br/>
                <Col xl={12}>
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopServiceForm"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopServiceForm">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> เช่น ปรับร้อยละ.......ต่อวัน หรือ จำนวน.............บาท จนกว่าจะส่งมอบแล้วเสร็จ<br/>Ex. A penalty of …% or THB…. per day until the delivery completion date will be imposed.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">กรณีไม่ส่งมอบงานตามวันเวลาในสัญญา (in case of delay in service performance)</Label>
                            <Input
                                type="text"
                                id="txtPenaltyDelayService"
                                name="penalty_delay_service"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณากรอกรายละเอียด"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.penalty_delay_service || ""}
                                invalid={validation.touched.penalty_delay_service && validation.errors.penalty_delay_service ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.penalty_delay_service && validation.errors.penalty_delay_service ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.penalty_delay_service}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopDeliveredAsAgreed"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopDeliveredAsAgreed">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุค่าปรับกรณีผู้รับจ้างไม่ทำตามข้อตกลงที่กำหนด เช่น งานไม่เป็นไปตามแบบ หรือไม่แก้ไขงานตามเวลาที่ตกลงกัน ปรับร้อยละ.......ต่อวัน หรือ จำนวน.............บาท<br/>Please provide penalty rate in case the work is not delivered as agreed, for example, if the work is not completed as planned, or if the service provider fails to revise the work within the agreed timeline, a penalty of …% or THB…. per day will be imposed.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">กรณีไม่ส่งมอบงานตามข้อตกลงที่กำหนดไว้ (if work is not delivered as agreed)</Label>
                            <Input
                                type="text"
                                id="txtPenaltyNotDelivered"
                                name="penalty_not_delivered"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณากรอกรายละเอียด"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.penalty_not_delivered || ""}
                                invalid={validation.touched.penalty_not_delivered && validation.errors.penalty_not_delivered ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.penalty_not_delivered && validation.errors.penalty_not_delivered ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.penalty_not_delivered}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopAbsenceFromWork"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopAbsenceFromWork">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุอัตราค่าปรับกรณีผู้รับจ้างขาดงาน เช่น กรณีการจ้างแม่บ้านหรือพนักงานรักษาความปลอดภัย<br/>Please specify penalty rate in case of absence from work, for example, service provider of a cleaning or security service is absence from work.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">กรณีขาดงาน (in case of absence from work)</Label>
                            <Input
                                type="text"
                                id="txtPenaltyAbsenceWork"
                                name="penalty_absence_work"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุอัตราค่าปรับกรณีผู้รับจ้างขาดงาน Please specify penalty rate in case of absence from work."
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.penalty_absence_work || ""}
                                invalid={validation.touched.penalty_absence_work && validation.errors.penalty_absence_work ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.penalty_absence_work && validation.errors.penalty_absence_work ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.penalty_absence_work}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopOthers"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopOthers">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุเหตุการคิดค่าปรับ และอัตราค่าปรับ<br/>Please specify the details of penalty cause and rate.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">กรณีอื่นๆ (Others)</Label>
                            <Input 
                                id="txtPenaltyOthers" 
                                name="penalty_others"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุเหตุการคิดค่าปรับ และอัตราค่าปรับ Please specify the details of penalty cause and rate."
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.penalty_others || ""}
                                disabled={isDisableds}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <div className="line-border"></div>
                </Col>
                <Col xl={12} className="mx-auto">
                    <div>
                        <span className="span-title">การบอกเลิกสัญญา (Termination)</span>
                    </div>
                </Col>
                <br/>
                <Col xl={12}>
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopTermination"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopTermination">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> หากมีข้อตกลงในการบอกเลิกสัญญาเป็นกรณีพิเศษ โปรดระบุรายละเอียด<br/>Please specify details if the parties have agreed on special termination cause.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">การบอกเลิกสัญญา (Termination)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtTermination"
                                name="termination_detail"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณากรอกรายละเอียด"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.termination_detail || ""}
                                invalid={validation.touched.termination_detail && validation.errors.termination_detail ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.termination_detail && validation.errors.termination_detail ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.termination_detail}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <div className="line-border"></div>
                </Col>
            
                <Col xl={12} className="mx-auto">
                    <div>
                        <span className="span-title">อื่นๆ (Others)</span>
                    </div>
                </Col>
                <br/>
                <Col xl={12}>
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopIpOwnerShip"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopIpOwnerShip">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุผู้เป็นเจ้าของกรรมสิทธิ์ในงานที่จ้าง/บริการ ให้ชัดเจน<br/>Please specify the name of owner in the IP rights over the deliverables.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">ความเป็นเจ้าของกรรมสิทธิ์ในผลสำเร็จของงาน (IP Ownership in the Deliverables)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtIpOwnership"
                                name="ip_ownership_detail"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณาระบุผู้เป็นเจ้าของกรรมสิทธิ์ในผลสำเร็จของงาน"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.ip_ownership_detail || ""}
                                invalid={validation.touched.ip_ownership_detail && validation.errors.ip_ownership_detail ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.ip_ownership_detail && validation.errors.ip_ownership_detail ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.ip_ownership_detail}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopWarranty"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopWarranty">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุวันเริ่มต้นการรับประกันด้วยว่าเริ่มเมื่อใด หรือวันที่ใด เช่น ….เดือน/ปี นับแต่วันที่ส่งมอบงานเสร็จสมบูรณ์ เป็นต้น<br/>Please specify the commencement date or timeline of warranty such as …..month/year since the date of completion work.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">การรับประกันผลงาน (Warranty)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtWarranty"
                                name="warranty"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุระยะเวลาการรับประกัน และข้อจำกัดของการรับประกัน Please specify the term and limitation of the warranty."
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.warranty || ""}
                                invalid={validation.touched.warranty && validation.errors.warranty ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.warranty && validation.errors.warranty ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.warranty}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopStamp"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopStamp">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุผู้ชำระค่าอากรแสตมป์ ทั้งนี้ ตามกฎหมายแล้ว ผู้รับจ้างเป็นผู้มีหน้าที่ต้องเสียอากรแสตมป์ต้นฉบับ และผู้ว่าจ้างมีหน้าที่ต้องเสียอากรแสตมป์คู่ฉบับ<br/>Please specify the responsible party for payment of stamp duty. According to the law, the service provider shall be responsible for stamp duty on the original document and the service receiver shall be responsible for stamp duty on the duplicate document.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">อากรแสตมป์ (Stamp Duty)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtStampDuty"
                                name="stamp_duty"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณาระบุผู้ชำระอากรแสตมป์"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.stamp_duty || ""}
                                invalid={validation.touched.stamp_duty && validation.errors.stamp_duty ? true : false}
                                disabled={isDisableds}
                                />
                                {validation.touched.stamp_duty && validation.errors.stamp_duty ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.stamp_duty}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopOtherFees"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopOtherFees">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุค่าธรรมเนียมอื่นๆ เช่น ค่าธรรมเนียมการโอนเงินผ่านธนาคาร เป็นต้น และผู้ชำระ<br/>Please specify other fees such as bank transaction fee etc. and the responsibility party for such fees.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">ค่าธรรมเนียมอื่นๆ (Other Fees)</Label>
                            <Input 
                                id="txtOtherFee" 
                                name="other_fee"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="โปรดระบุค่าธรรมเนียมอื่นๆ และผู้ชำระ"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.other_fee || ""}
                                disabled={isDisableds}
                            />
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <Label className="label-title" >เงื่อนไขอื่น ๆ (หากมี) (Other Conditions)</Label>
                            <Input 
                                id="txtOtherConditions" 
                                name="other_conditions"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณากรอกเงื่อนไขอื่นๆ"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.other_conditions || ""}
                                disabled={isDisableds}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                {/* <Col md={12}>
                    <div className="line-border"></div>
                </Col>
                <Col xl={12} className="mx-auto">
                    <div>
                        <span className="span-title">เงื่อนไขอื่น ๆ (หากมี) (Other Conditions)</span>
                    </div>
                </Col> */}
                {/* <br/> */}
                <Col xl={12} className="mx-auto">
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverRightLanguage"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="right" target="PopoverRightLanguage">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> เลือกได้เพียง 1 ภาษา หากมากกว่า 1 ภาษา User ต้องทำการแปลเอง<br/>Available in a single language; users are responsible for translation if a bilingual document is needed.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">Language<span className="msg-require">*</span></Label>
                            <Select id="ddlLanguage"
                                name="id_language"
                                value={selectLanguage}
                                className="txt-placeholder"
                                placeholder={"เลือกภาษา"}
                                onChange={(selected) => {
                                    setSelectLanguage(selected);
                                    validation.handleChange("id_language")((selected !== null) ? selected.value : "");
                                    validationDraft.handleChange("id_language")((selected !== null) ? selected.value : "");
                                    setIsSaveEnabled(true);
                                }}
                                options={dataLanguage}
                                isClearable={true}
                                isSearchable={true}
                                invalid={ validation.touched.id_language && validation.errors.id_language ? true : false }
                                isDisabled={isDisableds}
                            />
                            { validation.touched.id_language && validation.errors.id_language ? 
                                ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.id_language}</FormFeedback> ) : false 
                            }
                            </FormGroup>
                        </Col>
                        <Col md={8}>
                            <FormGroup>
                            <Label className="label-title-confidential">โปรดระบุระดับชั้นความลับของคำขอ (Please specify the confidentiality level of this request)<span className="msg-require">*</span></Label>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopConfidential"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopConfidential">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> "ทั่วไป" หมายถึง สามารถเปิดเผยให้ฝ่ายกฎหมายและผู้ที่เกี่ยวข้องได้ <br/>"เป็นความลับ" หมายถึง เอกสารนี้จะเปิดเผยให้แก่ฝ่ายกฎหมายที่ถูกระบุชื่อ และฝ่ายกฎหมายระดับ Associate Director ขึ้นไปเท่านั้น​<br/>"General" means that this request will be shared with all legal team members and related persons. ​<br/> "Confidential" means that this request will be shared with the specified legal person and legal associate directors or higher level.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            
                            <Select id="ddlConfidential"
                                name="id_confidential_level"
                                value={selectConfidential}
                                className="txt-placeholder"
                                placeholder={"เลือกระดับความลับของเอกสาร"}
                                onChange={(selected) => {
                                    setSelectConfidential(selected);
                                    validation.handleChange("id_confidential_level")((selected !== null) ? selected.value : "");
                                    validationDraft.handleChange("id_confidential_level")((selected !== null) ? selected.value : "");
                                    setIsSaveEnabled(true);
                                }}
                                options={dataConfidential}
                                isClearable={true}
                                isSearchable={true}
                                invalid={ validation.touched.id_confidential_level && validation.errors.id_confidential_level ? true : false }
                                isDisabled={isDisableds}
                            />
                            { validation.touched.id_confidential_level && validation.errors.id_confidential_level ? 
                                ( <FormFeedback type="invalid" style={{display: "block"}} className="msg-show-hint">{validation.errors.id_confidential_level}</FormFeedback> ) : false 
                            }
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <Label className="label-title">มีการเปิดเผย/ใช้/ประมวลผลข้อมูลส่วนบุคคลภายใต้เอกสารนี้หรือไม่<br/>(Personal data will be disclosed/used/processed under this document)<span className="msg-require">*</span></Label>
                            <i className="mdi mdi-help-circle icon-hint-no-padding" id="PopoverTopPersonaldata"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopPersonaldata">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> ข้อมูลส่วนบุคคลคือข้อมูลที่สามารถระบุตัวบุคคลใดได้ไม่ว่าทางตรงหรือทางอ้อม<br/>Personal Information is any information relating to a person, which enables the identification of such person, whether directly or indirectly.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <div className="mt-4 mt-lg-0 label-title">
                                <div className="form-check form-check-inline">
                                    <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="share_pdpa"
                                    id="sharePdPa1"
                                    value="1"
                                    checked={selectedRadioPdpa === "1"}
                                    onChange={handleRadioChangePdpa}
                                    disabled={isDisableds}
                                    />
                                    <Label className="form-check-label" htmlFor="sharePdPa1">
                                    Yes (This request will be shared with PDPA team)
                                    </Label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <Input
                                    className="form-check-input"
                                    type="radio"
                                    name="share_pdpa"
                                    id="sharePdPa2"
                                    value="0"
                                    checked={selectedRadioPdpa === "0"}
                                    onChange={handleRadioChangePdpa}
                                    disabled={isDisableds}
                                    />
                                    <Label className="form-check-label" htmlFor="sharePdPa2">
                                    No
                                    </Label>
                                </div>
                            </div>
                            {validation.touched.share_pdpa && validation.errors.share_pdpa ? (
                            <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                {validation.errors.share_pdpa}
                            </FormFeedback>
                            ) : false}
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </div>
            <div id="section-page-5-1">
                <Col xl={12} className="mx-auto">
                    <Row>
                    <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopUserComment"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopUserComment">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> กรุณาระบุว่าจากการตรวจสอบของ User แล้ว User มีความเห็นเพิ่มเติมในเอกสาร/สัญญาดังกล่าวอย่างไรบ้าง<br/>Please specify if the user has any comments on the documents / agreements.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">ความเห็นของ User (User's Comment)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtUserComment"
                                name="user_comment"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="ระบุว่ามีความเห็นเพิ่มเติมในเอกสาร/สัญญาดังกล่าวอย่างไร"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.user_comment || ""}
                                invalid={validation.touched.user_comment && validation.errors.user_comment ? true : false}
                                disabled={isDisableds || dataFormRequest.request_no !== "draft" && dataUser.email === dataFormRequest.approve_request_by || dataFormRequest.id_workflow_status > 4 ? true : false}
                                />
                                {validation.touched.user_comment && validation.errors.user_comment ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.user_comment}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverTopLegalHandle"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="top" target="PopoverTopLegalHandle">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> ระบุอีเมลของนักกฎหมายที่เคยรับผิดชอบเอกสารนี้ (ขั้นตอนนี้ไม่ใช่การกำหนดเลือกคนทำงาน ฝ่ายกฎหมายขอสงวนสิทธิ์ในการกำหนดคนทำงานนี้ต่อไป)<br/>This request will be sent to the e-mail address of the e-mail person. (This step is not to select workers. The Legal Department reserves the right to solely decide and assign people to do this work.)</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">ระบุอีเมลของนักกฎหมายที่เคยรับผิดชอบเอกสารนี้ (Legal team who has handled this work (if any))</Label>
                            <Input 
                                id="txtRequireHandledBy" 
                                name="require_handled_by"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณาระบุอีเมลของนักกฎหมายที่เคยรับผิดชอบเอกสารนี้"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.require_handled_by || ""}
                                invalid={validation.touched.require_handled_by && validation.errors.require_handled_by ? true : false}
                                disabled={dataFormRequest.id_workflow_status > 4 ? true : isDisableds}
                            />
                            {validation.touched.require_handled_by && validation.errors.require_handled_by ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.require_handled_by}
                                </FormFeedback>
                                ) : false}
                            </FormGroup>
                        </Col>
                        <Col md={10}>
                            <FormGroup>
                            <Label className="label-title">คำขอนี้จะได้รับการตรวจสอบและอนุมัติโดยใครในฝ่ายผู้ขอ ก่อนส่งต่อให้ฝ่ายกฎหมาย (This request will be reviewed and approved by whom of your function before Legal team’s review)<span className="msg-require">*</span>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverRightApproval"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="right" target="PopoverRightApproval" >
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุชื่อผู้อนุมัติคำขอ โดยคำขอทุกฉบับต้องได้รับการอนุมัติจากผู้มีตำแหน่ง Director ขึ้นไปในฝ่ายผู้ขอ <br/>(Please specify the approver's name. Kindly note that all requests must be approved by director or higher level in your function.)​</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            </Label>
                            <Input
                                type="text"
                                id="txtApproveRequestBy"
                                name="approve_request_by"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรุณากรอกอีเมล"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    // console.log(e.target.value);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.approve_request_by || ""}
                                invalid={validation.touched.approve_request_by && validation.errors.approve_request_by ? true : false}
                                disabled={isDisableds || dataFormRequest.request_no !== "draft" && dataUser.email === dataFormRequest.approve_request_by || dataFormRequest.id_workflow_status > 4 ? true : false}
                            />
                                {validation.touched.approve_request_by && validation.errors.approve_request_by ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.approve_request_by}
                                </FormFeedback>
                                ) : false}
                            
                            </FormGroup>
                        </Col>
                        { checkShowInputUserCoordinator ? (
                        <Col md={10}>
                            <FormGroup>
                            <i className="mdi mdi-help-circle icon-hint-right" id="PopoverRightCoodinator"></i>
                            <div className="hstack flex-wrap gap-2">
                                <UncontrolledPopover placement="right" target="PopoverRightCoodinator">
                                    <PopoverBody className="msg-show-hint"><i className="mdi mdi-help-circle msg-icon-hint"></i> โปรดระบุอีเมลของผู้ประสานงานสำหรับคำขอนี้โดยจะต้องเป็นผู้ที่มีรายชื่อเป็นผู้ประสานงานตามที่บริษัทกำหนดและได้แจ้งฝ่ายกฎหมาย BTSG ไว้แล้ว <br/>Please specify the email address of the coordinator for this request. The specified person must be listed as a coordinator as designated by the company and has been informed to BTSG legal team.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <Label className="label-title">ชื่อผู้ประสานงาน (Name of User Coordinator)<span className="msg-require">*</span></Label>
                            <Input
                                type="text"
                                id="txtCreateRequestBy"
                                name="create_request_by"
                                autoComplete={autoCompletes}
                                className="txt-placeholder"
                                placeholder="กรอกอีเมลผู้ประสานงาน"
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    validationDraft.handleChange(e);
                                    // console.log(e.target.value);
                                    setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.create_request_by || ""}
                                invalid={validation.touched.create_request_by && validation.errors.create_request_by ? true : false}
                                disabled={isDisableds || dataFormRequest.request_no !== "draft" && dataUser.email === dataFormRequest.create_request_by || dataFormRequest.id_workflow_status > 4 ? true : false}
                            />
                                {validation.touched.create_request_by && validation.errors.create_request_by ? (
                                <FormFeedback type="invalid" style={{ display: "block" }} className="msg-show-hint">
                                    {validation.errors.create_request_by}
                                </FormFeedback>
                                ) : false}
                            
                            </FormGroup>
                        </Col>
                        ):""}
                        { showCommentApprover === true || dataFormCsa.approver_comment !== "" ?(
                            <>
                            <Col md={12}>
                                <div className="line-border"></div>
                            </Col>
                            <Col md={10}>
                                <div className="page-content-approver">
                                    <div className="approver-header-content">
                                    {dataUser.email === dataFormRequest.approve_request_by ? "ระบุความเห็นเพื่อส่งไปยังผู้ร้องขอ (Please specify comments to send to the requester.)" : "คุณมีความเห็นจากผู้อนุมัติ (You have a comments from the approver)"}
                                    </div>
                                    <FormGroup>
                                    <Col md={10} className="approver-col">
                                        <Label className="label-title-approver-comment">ความเห็นของผู้อนุมัติ (Approver's Comment)</Label>
                                        <Input
                                            type="text"
                                            id="txtApproveComment"
                                            name="approver_comment"
                                            autoComplete={autoCompletes}
                                            className="txt-approver-comment"
                                            placeholder="ความเห็นเพิ่มเติมของผู้อนุมัติ"
                                            onChange={(e) => {
                                                validation.handleChange(e);
                                                validationDraft.handleChange(e);
                                                validationReturn.handleChange(e);
                                                setIsSaveEnabled(true); // Set save enabled to true when the input changes
                                            }}
                                            onBlur={validationReturn.handleBlur}
                                            value={validation.values.approver_comment || ""}
                                            invalid={validationReturn.touched.approver_comment && validationReturn.errors.approver_comment ? true : false}
                                            disabled={(dataUser.email === dataFormRequest.request_by) || (dataUser.email === dataFormRequest.approve_request_by && dataFormRequest.id_workflow_status === 4) ? true : false}
                                        />
                                        {validationReturn.touched.approver_comment && validationReturn.errors.approver_comment ? (
                                        <FormFeedback type="invalid" className="alert-validation-approver-comment">
                                            {validationReturn.errors.approver_comment}
                                        </FormFeedback>
                                        ) : false}
                                    </Col>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="line-border"></div>
                            </Col>
                            </>
                        ):null}
                        <Col md={10}>
                            <Alert className="border-0 border-0 remark-content">
                            <i className="mdi mdi-alert icon-remark-color"></i><strong className="remark-color"> หมายเหตุ (Remark) </strong><br/>
                            <span className="span-remark">"User รับทราบว่าแบบฟอร์มนี้เป็นความลับและมีวัตถุประสงค์เพื่อใช้ภายในบริษัทในเครือ BTS Group เท่านั้น หากพนักงานเปิดเผยข้อมูลแบบฟอร์มให้แก่บุคคลภายนอก ถือว่าประพฤติผิดวินัยอย่างร้ายแรง"</span><br/>
                            <span className="span-remark">"Users are fully aware that this request form is strictly private and confidential for internal use within BTS Group's affiliates only. Any employee disclosing this request form to the third party is considered a serious misconduct."</span>
                            </Alert>
                        </Col>
                    </Row>
                </Col>
            </div>
                    {   
                    dataFormRequest.id_workflow_status >= 0 && // Check if status is 0 or greater
                    dataFormRequest.id_workflow_status <= 4 ||
                    dataFormCsa.id === 0 ? (
                    <Row>
                        <div className="page-footer">
                            <div className="layout-width-footer">
                                <div className="navbar-footer">
                                    <>
                                    <div className="d-flex align-items-center">
                                        <Link to="/requests" className="logo">
                                            <Button 
                                                color="outline secondary" 
                                                type="button" 
                                                // onClick={handleBack}
                                            >
                                            Back
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {dataFormRequest.request_no === "draft" ? (
                                            <Button 
                                                color="outline secondary" 
                                                type="button" 
                                                // onClick={handleBack}
                                                onClick={() => onCancelRequest(dataFormRequest)}
                                            >
                                            Delete
                                            </Button>
                                        ): null}
                                        {checkUserIsApproverShowReturnBtn ?(
                                            <>
                                            {/* case if requestor && approver ถ้าคนเปิด request และ คนอนุมัติเป็นคนเดียวกันจะปิด ปุ่ม return  */}
                                            {checkUserCreateTicketAndApproveIsSame ?(
                                            <Button 
                                            className="btn-return-style"
                                            color="outline secondary" 
                                            type="button" 
                                            onClick={() => onCheckReturn()}
                                            >
                                                Return
                                            </Button>
                                            ):null}
                                            </>
                                        ):null}
                                        {changeButtonIsStateRequestorOrApprover ?(
                                            <>
                                            <Button 
                                                className="float-end btn-save-style"  
                                                color={isSaveEnabled ? "primary" : "secondary"} 
                                                outline 
                                                type="button" 
                                                disabled={!isSaveEnabled}
                                                onClick={() => onCheckSaveDraft()}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                className="float-end btn-submit-style" 
                                                color="primary" 
                                                type="button"
                                                // onClick={handleNext}
                                                // onClick={() => onClickSubmit(1)}
                                                // onClick={() => setIsShowModal(true)}
                                                onClick={() => { onCheckSubmit()}}
                                            >
                                                {/* {userEmail +"/"+ dataFormRequest.request_by} */}
                                            {dataUser.email === dataFormRequest.approve_request_by ? "Approve" : "Submit"}
                                            </Button>
                                            </>
                                        ):null}
                                    </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </Row>
                    ):
                    <>
                        <div id="footer-modal">
                            <hr className="line-footer"></hr>
                            <Col md={12}>
                                <Button 
                                    className="float-end btn-submit-style" 
                                    color="primary" 
                                    type="button"
                                    onClick={() => validation.submitForm()}
                                >
                                    Update
                                </Button>
                                <Button 
                                    className="float-end btn-cancel" 
                                    color="outline secondary" 
                                    type="button" 
                                    onClick={() => onCancelRequest(false)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </div>
                    </>
                    }
                    
            </Form>
            </div>
        </React.Fragment>
        );
    };

export default RequestFormCsa;