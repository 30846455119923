const groupStatusToPage = [
    {
        url_page: "/request/:id",
        workflow_status: [1, 2, 4]
    },
    {
        url_page: "/assign/:id",
        workflow_status: [3]
    },
    {
        url_page: "/accept/:id",
        workflow_status: [5]
    },
    {
        url_page: "/review-comment/:id",
        workflow_status: [7, 8, 9, 12, 14]
    },
    {
        url_page: "/memo/:id",
        workflow_status: [10]
    },
    {
        url_page: "/prefinal-legal/:id",
        workflow_status: [15]
    },
    {
        url_page: "/prefinal-user/:id",
        workflow_status: [16]
    },
    {
        url_page: "/final-contract/:id",
        workflow_status: [17, 18, 20]
    },
    {
        url_page: "/prefinal-reject/:id",
        workflow_status: [19]
    },
    {
        url_page: "/signed-contract/:id",
        workflow_status: [21]
    },
    {
        url_page: "/summary-request/:id",
        workflow_status: [6, 11, 13, 22]
    }
];

export { groupStatusToPage };