import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import classnames from "classnames";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import TableDashboard from '../../components/common/table-dashboard';
import axios from "axios";
import ConfigData from '../../config.json';
import { groupStatusToPage } from '../../components/model/static-data';

const RequestList = () => {
    const history = useHistory();
    const { changeTitlePage, changeColorName } = useContext(GolbalDataContext);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [dataAll, setDataAll] = useState([]);
    const [dataWaitingForApprove, setDataWaitingForApprove] = useState([]);
    const [dataInProgress, setDataInProgresss] = useState([]);
    const [dataReject, setDataReject] = useState([]);
    const [dataComplete, setDataComplete] = useState([]);
    const [dataDraft, setDataDraft] = useState([]);
    const userEmail = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));

    useEffect(() => {
        changeTitlePage("All Request");
        LoadDataRequest();
    }, []);

    const columnAll = [
        {
            key: "request_no",
            name: <span className='font-weight-bold'>Request No.</span>,
            cell: (row, index) => row.request_no,
            width: "12%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "request_date",
            name: <span className='font-weight-bold'>Date Created</span>,
            cell: (row, index) => row.request_date,
            width: "12%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "form_temp_full_name",
            name: <span className='font-weight-bold'>Type</span>,
            cell: (row, index) => row.form_temp_full_name,
            width: "10%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "business_unit_name_en",
            name: <span className='font-weight-bold'>BU</span>,
            cell: (row, index) => row.id_business_unit === "0" ? row.business_unit_other : row.business_unit_name_en,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "counterparty_name",
            name: <span className='font-weight-bold'>Counter party</span>,
            cell: (row, index) => row.counterparty_name,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "confidential_level_name_en",
            name: <span className='font-weight-bold'>Level</span>,
            cell: (row, index) => {
                return(
                    <label className={"rtc-table-level " + ( row.id_confidential_level !== "0" ? ((row.id_confidential_level == 2) ? "rtc-table-level-confidential" : "rtc-table-level-normal") : "")}>{row.confidential_level_name_en}</label>
                );
            },
            width: "10%",
            classHeader: "text-center",
            classBody: "text-center",
            sortable: true
        },
        {
            key: "accept_request_by",
            name: <span className='font-weight-bold'>Legal Team</span>,
            cell: (row, index) => row.accept_request_by,
            width: "16%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "id_workflow_status",
            name: <span className='font-weight-bold'>Status</span>,
            cell: (row, index) => {
                if(row.id_workflow_status === 1 || row.id_workflow_status === 2 || row.id_workflow_status === 8 
                    || row.id_workflow_status === 12 || row.id_workflow_status === 17 || row.id_workflow_status === 18 
                    || row.id_workflow_status === 20){
                    return (
                        <div className="rtc-table-status">
                            <i className="mdi mdi-clock-time-four-outline rtc-ts-wating-for-approve"></i>
                            <label>Waiting for approve</label>
                        </div>
                    );
                }else if(row.id_workflow_status === 3 ){
                    return (
                        <div className="rtc-table-status">
                            <i className="mdi mdi-timer-sand-empty rtc-ts-in-progress"></i>
                            <label>Waiting for acceptance</label>
                        </div>
                    );
                }else if(row.id_workflow_status === 4 ){
                    return (
                        <div className="rtc-table-status">
                            <i className="mdi mdi-timer-sand-empty rtc-ts-in-progress"></i>
                            <label>Return</label>
                        </div>
                    );
                }else if(row.id_workflow_status === 5
                    || row.id_workflow_status === 7 || row.id_workflow_status === 9 || row.id_workflow_status === 14
                    || row.id_workflow_status === 10 || row.id_workflow_status === 15 || row.id_workflow_status === 16
                    || row.id_workflow_status === 19 || row.id_workflow_status === 21){
                    return (
                        <div className="rtc-table-status">
                            <i className="mdi mdi-timer-sand-empty rtc-ts-in-progress"></i>
                            <label>Reviewing</label>
                        </div>
                    );
                }else if(row.id_workflow_status === 6 || row.id_workflow_status === 11 || row.id_workflow_status === 13){
                    return (
                        <div className="rtc-table-status">
                            <i className="mdi mdi-rotate-left rtc-ts-reject"></i>
                            <label>Reject</label>
                        </div>
                    );
                }else if(row.id_workflow_status === 0){
                    return (
                        <div className="rtc-table-status">
                            <i className="mdi mdi-rotate-left rtc-ts-reject"></i>
                            <label>Draft</label>
                        </div>
                    );
                }else{
                    return (
                        <div className="rtc-table-status">
                            <i className="mdi mdi-check dtdl-wfa-icon rtc-ts-complete"></i>
                            <label>Complete</label>
                        </div>
                    );
                }
            },
            width: "10%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: false
        },
        // {
        //     key: "action",
        //     name: <span className='font-weight-bold fs-13'></span>,
        //     cell: (row, index) => {
        //         return(
        //             <UncontrolledDropdown key={index}>
        //                 <DropdownToggle tag="button" className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="ri-more-2-fill"></i></DropdownToggle>
        //                 <DropdownMenu className="dropdown-menu-end">
        //                     <li>
        //                         <DropdownItem onClick={() => onClickUpdate(row)}>
        //                             <i className="ri-pencil-fill"></i> Edit
        //                         </DropdownItem>
        //                     </li>
        //                     {row.request_no === "draft" ? (
        //                         <li>
        //                         <DropdownItem onClick={() => onClickDelete(row.id)}>
        //                             <i className="ri-delete-bin-line"></i> Delete
        //                         </DropdownItem>
        //                         </li>
        //                     ) : (
        //                         <li>
        //                         {/* Add your alternative action here */}
        //                         <DropdownItem onClick={() => onClickCancel(row)}>
        //                             <i className="ri-delete-bin-line"></i> Delete
        //                         </DropdownItem>
        //                         </li>
        //                     )}
        //                 </DropdownMenu>
        //             </UncontrolledDropdown>
        //         )
        //     },
        //     width: "10%",
        //     classHeader: "text-start",
        //     classBody: "text-end",
        //     sortable: false
        // }
    ];

    const columnForStatus = [
        {
            key: "request_no",
            name: <span className='font-weight-bold'>Request No.</span>,
            cell: (row, index) => row.request_no,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: false
        },
        {
            key: "request_date",
            name: <span className='font-weight-bold'>Date Created</span>,
            cell: (row, index) => row.request_date,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "form_temp_full_name",
            name: <span className='font-weight-bold'>Type</span>,
            cell: (row, index) => row.form_temp_full_name,
            width: "10%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "business_unit_name_en",
            name: <span className='font-weight-bold'>BU</span>,
            cell: (row, index) => row.business_unit_name_en,
            width: "20%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "counterparty_name",
            name: <span className='font-weight-bold'>Counter party</span>,
            cell: (row, index) => row.counterparty_name,
            width: "15%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        {
            key: "confidential_level_name_en",
            name: <span className='font-weight-bold'>Level</span>,
            cell: (row, index) => {
                return(
                    <label className={"rtc-table-level " + ( row.id_confidential_level !== "0" ? ((row.id_confidential_level == 2) ? "rtc-table-level-confidential" : "rtc-table-level-normal") : "")}>{row.confidential_level_name_en}</label>
                );
            },
            width: "10%",
            classHeader: "text-center",
            classBody: "text-center",
            sortable: true
        },
        {
            key: "accept_request_by",
            name: <span className='font-weight-bold'>Legal Team</span>,
            cell: (row, index) => row.accept_request_by,
            width: "20%",
            classHeader: "text-start",
            classBody: "text-start",
            sortable: true
        },
        // {
        //     key: "action",
        //     name: <span className='font-weight-bold fs-13'></span>,
        //     cell: (row, index) => {
        //         return(
        //             <UncontrolledDropdown key={index}>
        //                 <DropdownToggle tag="button" className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="ri-more-2-fill"></i></DropdownToggle>
        //                 <DropdownMenu className="dropdown-menu-end">
        //                     <li>
        //                         <DropdownItem onClick={() => onClickUpdate(row)}>
        //                             <i className="ri-pencil-fill"></i> Edit
        //                         </DropdownItem>
        //                     </li>
        //                 {row.request_no === "draft" ? (
        //                     <li>
        //                     <DropdownItem onClick={() => onClickDelete(row.id)}>
        //                         <i className="ri-delete-bin-line"></i> Delete
        //                     </DropdownItem>
        //                     </li>
        //                 ) : (
        //                     <li>
        //                     {/* Add your alternative action here */}
        //                     <DropdownItem onClick={() => onClickCancel(row)}>
        //                         <i className="ri-delete-bin-line"></i> Delete
        //                     </DropdownItem>
        //                     </li>
        //                 )}
        //                 </DropdownMenu>
        //             </UncontrolledDropdown>
        //         )
        //     },
        //     width: "10%",
        //     classHeader: "text-start",
        //     classBody: "text-end",
        //     sortable: false
        // }
    ];

    const toggleCustomActiveTab = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const OnClickDetail = (data) => {
        let reqId = btoa(data.id);
        let urlPage;
        let url;
        urlPage = groupStatusToPage.find((e) => {
            return e.workflow_status.includes(data.id_workflow_status);
        });

        const checkUserCreateRequestBy = (
            data.create_request_by !== userEmail 
            || data.create_request_by === data.request_by 
            || data.id_workflow_status > 3
        );

        if(typeof(urlPage) !== "undefined"){
                if( checkUserCreateRequestBy ) { // check user if create_request_by ?
                    url = urlPage.url_page.replace(":id", reqId);
                    history.push(url);
                } else {
                    url = "/summary-request/"+reqId;
                    history.push(url);
                }
        } else {
            // case draft
            if(data.id_workflow_status === 0){
                url = "/request/"+reqId;
                history.push(url);
            }
        }
    };

    const LoadDataRequest = () => {
        const email = (!localStorage.getItem("emd")) ? "--" : atob(localStorage.getItem("emd"));
        let url = ConfigData.API_URL + "request/email/all/" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrDataAll = respData.data;
                // console.log(arrDataAll);
                const arrDataWaitingForApprove = respData.data.filter((e) => e.id_workflow_status === 1 || e.id_workflow_status === 2 || e.id_workflow_status === 8 
                                                                            || e.id_workflow_status === 12 || e.id_workflow_status === 17 || e.id_workflow_status === 18 
                                                                            || e.id_workflow_status === 20);
                const arrDataInProgress = respData.data.filter((e) => e.id_workflow_status === 3 || e.id_workflow_status === 4 || e.id_workflow_status === 5
                                                                            || e.id_workflow_status === 7 || e.id_workflow_status === 9 || e.id_workflow_status === 14
                                                                            || e.id_workflow_status === 10 || e.id_workflow_status === 15 || e.id_workflow_status === 16
                                                                            || e.id_workflow_status === 19 || e.id_workflow_status === 21);
                const arrDataReject = respData.data.filter((e) => e.id_workflow_status === 6 || e.id_workflow_status === 11 || e.id_workflow_status === 13);
                const arrDataComplete = respData.data.filter((e) => e.id_workflow_status === 22);
                const arrDataDraft = respData.data.filter((e) => e.id_workflow_status === 0);
                setDataAll(arrDataAll);
                setDataWaitingForApprove(arrDataWaitingForApprove);
                setDataInProgresss(arrDataInProgress);
                setDataReject(arrDataReject);
                setDataComplete(arrDataComplete);
                setDataDraft(arrDataDraft);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="request-tracking-contract">
                        <Col xl={12}>
                            <Row>
                                <Col xl={6}>
                                    <p className="rtc-title">Tracking Contract</p>
                                </Col>
                                <Col xl={6}>
                                    <Link to="/create-request">
                                        <Button 
                                            className="float-end" 
                                            color="primary" 
                                            style={{borderRadius:'8px', height:'50px'}} 
                                            type="button" 
                                        >
                                            <i className="mdi mdi-plus"></i> Create Request
                                        </Button>
                                    </Link>

                                    {/* <div className="float-end" style={{width: "40%", marginRight:"10px"}}>
                                        {/* <Select id="ddlShow"
                                            name="ddlShow"
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: '#F4F6F9',
                                                  }),
                                            }}
                                            // value={selectShow}
                                            // onChange={(selected) => {
                                            //     setSelectShow(selected);
                                            //     onSearch(txtSearch, (selected != null) ? selected.value : "")
                                            // }}
                                            // options={ddlShow}
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder="Sort By"
                                        /> 
                                    </div> */}
                                </Col>
                            </Row>
                            <div className="rtc-content">
                                {/* <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"> */}
                                <Nav tabs className="nav nav-tabs nav-tabs-dashboard mb-3">
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "1",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("1");
                                            }}
                                        >
                                            All
                                            <span className="nav-noti">
                                                <label>{dataAll.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "2",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("2");
                                            }}
                                        >
                                            Waiting for approve
                                            <span className="nav-noti">
                                                <label>{dataWaitingForApprove.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "3",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("3");
                                            }}
                                        >
                                            In Progress
                                            <span className="nav-noti">
                                                <label>{dataInProgress.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "4",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("4");
                                            }}
                                        >
                                            Reject
                                            <span className="nav-noti">
                                                <label>{dataReject.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "5",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("5");
                                            }}
                                        >
                                            Complete
                                            <span className="nav-noti">
                                                <label>{dataComplete.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: customActiveTab === "6",
                                            })}
                                            onClick={() => {
                                                toggleCustomActiveTab("6");
                                            }}
                                        >
                                            Draft
                                            <span className="nav-noti">
                                                <label>{dataDraft.length}</label>
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={customActiveTab}>
                                    <TabPane tabId="1" id="tab1">
                                        {/* <TableDashboard data={dataAll} column={columnAll} OnClickDetail={OnClickDetail} /> */}
                                        <TableDashboard data={dataAll} column={columnAll} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="2" id="tab2">
                                        <TableDashboard data={dataWaitingForApprove} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="3" id="tab3">
                                        <TableDashboard data={dataInProgress} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="4" id="tab4">
                                        <TableDashboard data={dataReject} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="5" id="tab5">
                                        <TableDashboard data={dataComplete} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                    <TabPane tabId="6" id="tab6">
                                        <TableDashboard data={dataDraft} column={columnForStatus} OnClickDetail={OnClickDetail} />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RequestList;