import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import { Button, Col, Container, Row } from 'reactstrap';
import axios from "axios";
import ConfigData from "../../config.json";
import StepWorkflow from '../../components/common/step-workflow';
import RequestHistory from '../../components/request/request-history';
import MultipleDownloadfile from '../../components/common/multiple-downloadfile';
import { getStepWorkflowByIdWorkFlow } from '../../components/utils/check-value';
import RequestFinalApprover from '../../components/request/request-final-approver';

const SummaryRequest = () => {
    const { id } = useParams();
    const history = useHistory();
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [workflowStep, setWorkflowStep] = useState(0);
    const [fileSignedDoc, setFileSignedDoc] = useState([]);
    const [fileMemo, setFileMemo] = useState([]);
    const [fileFinalContract, setFileFinalContract] = useState([]);
    const [lastFinalApprover, setLastFinalApprover] = useState("");
    const [dataHistory, setDataHistory] = useState([]);
    const [dataRequest, setDataRequest] = useState({
        "id": 0,
        "id_workflow_status": 0,
        "request_no": "",
        "contract_no": "",
        "request_by": "",
        "memo_doc": "",
        "final_doc": "",
        "signed_doc": "",
        "legal1_approve_by": "",
        "legal2_approve_by": "",
        "legal3_approve_by": "",
        "user1_approve_by": "",
        "user2_approve_by": "",
        "user3_approve_by": "",
    });

    useEffect(() => {
        changeTitlePage("All Request");
        let strId = "0";
        try{
            strId = atob(id);
        }catch(error){
            strId = "0"
        }
        LoadUserCanDoReq(strId);
    }, []);

    const OnClickBack = () => {
        // history.push("/dashboard");
        history.push("/requests");
        
    };

    const LoadDataRequest = (id) => {
        let url = ConfigData.API_URL + "request/" + id ;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                let objReq = new Object();
                objReq.id = respData.data[0].id;
                objReq.id_workflow_status = respData.data[0].id_workflow_status;
                objReq.request_no = respData.data[0].request_no;
                objReq.contract_no = respData.data[0].contract_no;
                objReq.request_by = respData.data[0].request_by;
                objReq.memo_doc = respData.data[0].memo_doc;
                objReq.final_doc = respData.data[0].final_doc;
                objReq.signed_doc = respData.data[0].signed_doc;
                objReq.legal1_approve_by = respData.data[0].legal1_approve_by;
                objReq.legal2_approve_by = respData.data[0].legal2_approve_by;
                objReq.legal3_approve_by = respData.data[0].legal3_approve_by;
                objReq.user1_approve_by = respData.data[0].user1_approve_by;
                objReq.user2_approve_by = respData.data[0].user2_approve_by;
                objReq.user3_approve_by = respData.data[0].user3_approve_by;
                setDataRequest(objReq);
                setFileFinalContract((respData.data[0].final_doc !== "") ? [{"name": respData.data[0].final_doc}] : []);
                setFileMemo((respData.data[0].memo_doc !== "") ? [{"name": respData.data[0].memo_doc}] : []);
                setFileSignedDoc((respData.data[0].signed_doc !== "") ? [{"name": respData.data[0].signed_doc}] : []);
                setWorkflowStep(getStepWorkflowByIdWorkFlow(respData.data[0].id_workflow_status));
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataLastFinalApprover = (idReq) => {
        let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq  + "&idWorkflowStatus=18";
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: {
            'Authorization' : token,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setLastFinalApprover(respData.data[0].do_by);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataHistory = (idReq) => {
        let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" }) 
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataHistory(respData.data);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadUserCanDoReq = (id) => {
        let userDo = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let url = new URL(ConfigData.API_URL + "request/user-can-do");
        url.searchParams.set("email", userDo);
        url.searchParams.set("id-request", id);
        url.searchParams.set("id-workflow", "10"); 
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                LoadDataRequest(id);
                LoadDataLastFinalApprover(id);
                LoadDataHistory(id);
            }else{
                // history.push("/dashboard");
                // history.push("/requests");
                url = ConfigData.API_URL + "request/" + id;
                axios.get(url, { headers: { 
                    'Authorization' : token ,
                }, responseType: "json" })
                .then(resp => {
                    let respData = resp.data;
                    if(respData.is_sucess === true){
                        const arrDataAll = respData.data[0];
                        if(arrDataAll.create_request_by !== userDo){
                            history.push("/requests");
                        } else {
                            LoadDataRequest(id);
                            LoadDataLastFinalApprover(id);
                            LoadDataHistory(id);
                        }
                    }
                })
                .catch(error => {
                    // handlerRespError(error, history);
                    console.log(error);
                });

            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="summary-request">
                    <Row>
                        <Col lg={12}>
                            <p className="sr-title">Request Detail {"> " + dataRequest.request_no}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <StepWorkflow step={workflowStep} />
                        </Col>
                    </Row>
                    {(dataRequest.id_workflow_status === 22) ? 
                        <Row className="justify-content-center">
                            <Col sm={12} md={11} lg={11}>
                                <div className="sr-msg-contract-completed">
                                    <p className="sr-msg-contract-completed-title">
                                        <i className="mdi mdi-check-circle"></i>
                                        เอกสารถูกจัดทำสำเร็จแล้ว/The document has been completed. 
                                    </p>
                                </div>
                            </Col>
                        </Row> : null
                    }
                    <Row className="justify-content-center">
                        <Col sm={12} md={11} lg={11}>
                            <div className="sr-req-desc">
                                {(dataRequest.id_workflow_status >= 21) ? 
                                    <React.Fragment>
                                        <Row className="sr-req-desc-title">
                                            <Col lg={12}>
                                                <p>เอกสารที่ถูกลงนามแล้ว (Signed Document)</p>
                                            </Col>
                                        </Row>
                                        <Row className="sr-req-desc-workarea">
                                            <Col lg={12}>
                                                <p className="sr-red-desc-contract-label">
                                                    Contract No.
                                                </p>
                                                <p className="sr-red-desc-contract-detail">
                                                    {(dataRequest.contract_no !== "") ? dataRequest.contract_no : "-"}
                                                </p>
                                                <p className="sr-red-desc-file-signed-label">
                                                    เอกสารที่ลงนามเรียบร้อยแล้ว (Signed Document)
                                                </p>
                                                <MultipleDownloadfile folder={dataRequest.request_no} dataFile={fileSignedDoc} />
                                            </Col>
                                        </Row>
                                    </React.Fragment> : null
                                }
                                <Row className={"sr-req-desc-title " + ((dataRequest.id_workflow_status >= 21) ? "mt-4" : "")}>
                                    <Col lg={12}>
                                        <p>ร่างเอกสารสุดท้าย (Finalize Document)</p>
                                    </Col>
                                </Row>
                                <Row className="sr-req-desc-workarea">
                                    <Col lg={12}>
                                        <p className="sr-red-desc-contract-label">
                                            เลขที่คำขอ (Request No.)
                                        </p>
                                        <p className="sr-red-desc-contract-detail">
                                            {(dataRequest.request_no !== "") ? dataRequest.request_no : "-"}
                                        </p>
                                        <p className="sr-red-desc-file-final-contract-label">
                                            ร่างเอกสารสุดท้ายและเอกสารแนบ (Finalized Document and Attachment)
                                        </p>
                                        <MultipleDownloadfile folder={dataRequest.request_no} dataFile={fileFinalContract} />
                                        <p className="sr-red-desc-file-memo-label">
                                            Memo สำหรับเสนอลงนาม (Memo for Signing Procedure)
                                        </p>
                                        <MultipleDownloadfile folder={dataRequest.request_no} dataFile={fileMemo} />
                                    </Col>
                                </Row>
                                <Row className="sr-req-desc-title mt-4">
                                    <Col lg={12}>
                                        <p>ผู้อนุมัติขั้นตอนสุดท้าย (Final approver(s))</p>
                                    </Col>
                                </Row>
                                <Row className="sr-req-desc-workarea">
                                    <Col lg={12}>
                                        <p className="sr-red-desc-contract-label">
                                            อนุมัติตามลำดับ ดังนี้ (following approval sequence.)
                                        </p>
                                        <RequestFinalApprover dataReq={dataRequest} lastApprover={lastFinalApprover} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={11} lg={11}>
                            <div className="sr-history">
                                <p className="sr-history-title">History</p>
                                <RequestHistory data={dataHistory} column="do_date" requestNo={dataRequest.request_no} />
                            </div>
                        </Col>
                    </Row>
                    <div className="page-footer">
                        <div className="layout-width-footer">
                            <div className="navbar-footer2">
                                <Button type="button" className="sr-btn-back" onClick={(e) => { OnClickBack(); }}>
                                    Back
                                </Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SummaryRequest;