import React, { useContext, useEffect, useState } from 'react';
import StepWorkflow from "../../components/common/step-workflow";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, FormGroup, Label, Input, Container, Modal, ModalBody, ModalFooter } from 'reactstrap';
import axios from "axios";
import Swal from "sweetalert2";
import { GolbalDataContext } from '../../components/store/global-data-provider';
import ConfigData from '../../config.json';
import RequestFormCsa from "../../components/request/requestForm/request_form_csa";
import RequestFormNda from "../../components/request/requestForm/request_form_nda";
// import RequestFormLdr from "../../components/request/requestForm/request_form_ldr";
import { getFilterOldFileName, setFormDataUploadFile } from '../../components/utils/format-value';
import { modelRequestWorkFlow } from "../../components/request/model_request";
import { IconAlertSuccess, IconAlertError } from '../../components/common/popup-alert';
import DownloadPDF from '../../components/common/pdf-download';  

const RequestDetail = () => {
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [activeStep, setActiveStep] = useState(null);
    const { idRequest } = useParams();
    const [dataRequestWorkFlow, setDataRequestWorkFlow] = useState(modelRequestWorkFlow);
    const [isShowModalApprove, setIsShowModalApprove] = useState();
    const [isShowModalSubmit, setIsShowModalSubmit] = useState();
    const [dataFormApprove, setdataFormApprove] = useState([]);
    const [dataForm, setDataForm] = useState([]);
    const [requestDataAll, setRequestDataAll] = useState([]);
    // const [dataFormCsa, setDataFormCsa] = useState([]);
    const [dataCompany, setDataCompany] = useState([]);
    const [dataRequest, setDataRequest] = useState([]);
    const [userCreateRequest, setUserCreateRequest] = useState([]);
    const history = useHistory();
    const userEmail = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
    // Thailand is UTC+7
    const thaiTimezoneOffset = 7 * 60;
    const offsetInMilliseconds = thaiTimezoneOffset * 60 * 1000;
    const thaiDateTime = new Date(new Date().getTime() + offsetInMilliseconds);
    // Format the date for MySQL DATETIME
    const newDateTime = thaiDateTime.toISOString().slice(0, 19).replace("T", " ");
    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        timeZone: 'Asia/Bangkok',
    });
    const [currentDay, currentMonth, currentYear] = currentDate.split('/');
    const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const newCurrentDate = formattedCurrentDate.replace(/undefined-/g, '');

    {/* check if step 1 , 2 , 4 */}
    const checkStepViewForm = (
        requestDataAll.request_no === "draft" 
        || requestDataAll.id_workflow_status === 1 
        || requestDataAll.id_workflow_status === 2 
        || requestDataAll.id_workflow_status === 4
    );

    const checkShowInputUserCoordinator = (
        (dataCompany.business_unit?.toLowerCase() === "rabbit holdings public company limited") 
        && (requestDataAll.request_no !== "draft")
    );

    useEffect(() => {
        changeTitlePage("All Request");
        try{
            let reqId;
            if (idRequest === "Mq==") {
                reqId = "MQ==";
            } else {
                reqId = idRequest;
            }
            LoadDataRequest(reqId);
            // LoadDataFormCsa(reqId);
        } catch(error) {
            history.push("/requests");
            console.log(error);
        }
    }, []);

    const LoadDataRequest = (idRequest) => {
        let url = ConfigData.API_URL + "request/" + atob(idRequest);
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrDataAll = respData.data[0];
                setRequestDataAll(arrDataAll);
                LoadDataUser(arrDataAll.request_by);
                if(arrDataAll.create_request_by !== ""){
                    LoadUserCreateRequest(arrDataAll.create_request_by);
                }
                LoadDataForm(idRequest, arrDataAll.table_name.toLowerCase());
                if(arrDataAll.request_no === "draft" && arrDataAll.id_workflow_status === 0){
                    setActiveStep(1);
                } else if(arrDataAll.id_workflow_status === 3){
                    setActiveStep(3);
                } else {
                    setActiveStep(2);
                }
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataForm = (idRequest, formName) => {
        let url;
        if( formName === "csa" ){
            url = ConfigData.API_URL + "form-csa/request/" + atob(idRequest);
        } else if( formName === "nda" ){
            url = ConfigData.API_URL + "form-nda/request/" + atob(idRequest);
        } else if( formName === "ldr" ){
            url = ConfigData.API_URL + "form-ldr/request/" + atob(idRequest);
        }
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const arrData = respData.data[0];
                setDataForm(arrData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataUser = (email) => {
        let url = ConfigData.API_URL + "user/search?status=1&email=" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataCompany(respData.data[0]);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadUserCreateRequest = (email) => {
        //console.log(email);
        let url = ConfigData.API_URL + "user/search?status=1&email=" + email;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            // console.log(respData);
            if(respData.is_sucess === true && respData.data.length > 0){
                setUserCreateRequest(respData.data[0]);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const onCancelRequest = (data) => {
        Swal.fire({
            title: 'Are you sure ?',
            text: 'Once you delete request draft, you can not undo it!',
            showCancelButton: true,
            confirmButtonText: 'OK',
            confirmButtonColor: '#dc3741',
            showCloseButton: true,
        }).then((result) => {
            if(result.isConfirmed){
                Swal.fire({
                    icon: 'info',
                    title: 'Loading...',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    willOpen: () => {
                        Swal.showLoading();
                    }
                });
          
                let isSaveSucess = true;
                (async () => {
                    try{
                        let dataCancel = await DeleteDraft(data.id);
                        isSaveSucess = (dataCancel.is_sucess === true && isSaveSucess === true) ? true : false;
                        
                        if(isSaveSucess === true){
                            Swal.close(); // Close loading spinner
                            Swal.fire({
                                iconHtml: IconAlertSuccess,
                                title: "Delete request draft.",
                                showConfirmButton: false,
                                showCloseButton: true,
                                // showConfirmButton: false,
                            }).then(() => {
                
                                history.push("/requests");
                            });
                        }else{
                            Swal.close(); // Close loading spinner
                            Swal.fire({
                                iconHtml: IconAlertError,
                                title: "Failed to delete data.",
                                showConfirmButton: false,
                                showCloseButton: true,
                            });
                        }
                    } catch(error){
                        console.log(error);
                    }
                })();
            }
        });
    }

    const onClickOk = (data) => {
        let idWorkFlow; 
        let setRequestsNo;
        
        let newRequestDate;
        let newFormatStartDate = null;
        let newFormatEndDate = null;
        let isSaveSucess = true;
        let isSendMail;
        let isForm;
        let postDataForm;
        let decodeIdReq = parseInt(atob(idRequest));
        let doBy;

        // Convert request_date format
        const [requestDay, requestMonth, requestYear] = requestDataAll.request_date.split('/');
        const formattedRequestDate = `${requestYear}-${requestMonth}-${requestDay}`;
        const newRequestDates = formattedRequestDate.replace(/undefined-/g, '');

        if( data.id_workflow_status === 4){
            idWorkFlow = 2;
            setRequestsNo = requestDataAll.request_no;
            newRequestDate = newRequestDates;
            doBy = requestDataAll.request_by;
        } else if( data.id_workflow_status === 0){
            idWorkFlow = 1;
            setRequestsNo = "";
            newRequestDate = newCurrentDate;
            doBy = requestDataAll.request_by != "" ? requestDataAll.request_by: userEmail
        }

        const postdataRequest = {
            ...dataRequest,
            id: decodeIdReq,
            id_form_temp: requestDataAll.id_form_temp !== "" ? requestDataAll.id_form_temp : "",
            table_name: requestDataAll.table_name !== "" ? requestDataAll.table_name : "",
            id_workflow_status: idWorkFlow,
            request_no: setRequestsNo,
            request_date: newRequestDate,
            id_business_unit: data?.id_business_unit?.trim() ? data.id_business_unit: "0",
            business_unit_other: data?.business_unit_other?.trim() ? data.business_unit_other: "",
            id_confidential_level : data.id_confidential_level ? data.id_confidential_level : "0",
            counterparty_name : data.counterparty_name,
            create_request_by: data.create_request_by != "" ? data.create_request_by: "",
            request_by: userEmail,
            require_handled_by: data.require_handled_by,
            approve_request_by: data.approve_request_by,
            status: "1"
        };

        if( requestDataAll.table_name.toLowerCase() === "csa" ){
            isForm = "csa"
            // set date to post formcsa
            if(data.term_start_date !== null && data.term_end_date !== null){
                if(data.term_start_date === "" && data.term_end_date === "") {
                    newFormatStartDate = null;
                    newFormatEndDate = null;
                } else {
                    // Convert term_start_date format
                    const [startDay, startMonth, startYear] = data.term_start_date.split('/');
                    const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
                    newFormatStartDate = formattedStartDate.replace(/undefined-/g, '');
                    // Convert term_end_date format
                    const [endDay, endMonth, endYear] = data.term_end_date.split('/');
                    const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
                    newFormatEndDate = formattedEndDate.replace(/undefined-/g, '');
                }
            } else if(data.term_start_date === "" && data.term_end_date === "") {
                data.term_start_date = null;
                data.term_end_date = null;
            }
            postDataForm = {
                ...data,
                request_date: newRequestDate,
                term_start_date: newFormatStartDate,
                term_end_date: newFormatEndDate,
                id_language: parseInt(data.id_language),
                id_confidential_level: data.id_confidential_level ? data.id_confidential_level : "0",
                approver_comment: data.approver_comment,
            };
            // console.log(postDataForm);
        } else if( requestDataAll.table_name.toLowerCase() === "nda" ){
            isForm = "nda"
            postDataForm = {
                ...data,
                request_date: newRequestDate,
                id_language: parseInt(data.id_language),
                id_confidential_level: data.id_confidential_level ? data.id_confidential_level : "0",
                approver_comment: data.approver_comment,
            };
        } else if( requestDataAll.table_name.toLowerCase() === "ldr" ){
            isForm = "ldr"
            postDataForm = {
                ...data,
                request_date: newRequestDate,
            };
        }

        const postdataRequestWorkFlow = {
            ...dataRequestWorkFlow,
            do_date: newDateTime,
            do_by: doBy, // if user create request switch to request by
            finish_date : null,
            id_workflow_status: idWorkFlow,
            comment : idWorkFlow === 2 ? data.user_comment : null,
        };
        
        const combinedData = {
            requestData: postdataRequest,
            formCsaData: postDataForm,
            requestWorkFlowData: postdataRequestWorkFlow,
        };

        setIsShowModalSubmit(!isShowModalSubmit);
        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });
        
        (async () => {
            try{
                let updateData = await UpdateRequestDraftTrans(decodeIdReq ,combinedData, isForm);
                isSaveSucess = (updateData.is_sucess === true && isSaveSucess === true) ? true : false;

                let isFileName;
                if(idWorkFlow === 1){ // request draft to set to used
                    isFileName = await SetFileName(data.id, "draftFinal/"+updateData.data[0].request_no, data);
                } else {
                    isFileName = await SetFileName(data.id, updateData.data[0].request_no, data);
                }
                isSaveSucess = (isFileName === true && isSaveSucess === true) ? true : false;

                if(isSaveSucess === true){
                        isSendMail = await SendEmailToApprover(updateData.data[0]);
                    if(data.create_request_by !== "" && data.id_workflow_status === 0 ){
                        isSendMail = await SendEmailChangeUserCreateRequest(updateData.data[0]);
                    }
                    
                    if( isSendMail === true ){
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                                iconHtml: IconAlertSuccess,
                                title: "Sending request to approver.",
                                showConfirmButton: false,
                                showCloseButton: true,
                                // showConfirmButton: false,
                            }).then(() => {
                                // props.nextStep();
                                history.push("/requests");
                            });
                    }else{
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to send email to approver.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        }).then(() => {
                            // props.nextStep();
                            history.push("/requests");
                        });
                        
                        console.log("api on send email is error");
                    }
                }else{
                    Swal.close(); // Close loading spinner
                    Swal.fire({
                        iconHtml: IconAlertError,
                        title: "Failed to save data.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                }
            }catch(error){
                console.log(error);
            }
        })();
    };

    const onUpdateForm = (data) => {
        // Convert term_start_date format
        let newFormatStartDate = null;
        let newFormatEndDate = null;

        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        // if request is draft
        if(data.request_no === "draft"){

            if( requestDataAll.table_name.toLowerCase() === "csa" ){
                if(data.term_start_date !== null && data.term_end_date !== null){
                    // Convert term_start_date format
                    const [startDay, startMonth, startYear] = data.term_start_date.split('/');
                    const formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
                    newFormatStartDate = formattedStartDate.replace(/undefined-/g, '');
                    // Convert term_end_date format
                    const [endDay, endMonth, endYear] = data.term_end_date.split('/');
                    const formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
                    newFormatEndDate = formattedEndDate.replace(/undefined-/g, '');
                }
                data.term_start_date = newFormatStartDate;
                data.term_end_date = newFormatEndDate;
            } 
            
            const postdataRequest = {
                ...dataRequest,
                id: parseInt(atob(idRequest)),
                id_form_temp: requestDataAll.id_form_temp !== "" ? requestDataAll.id_form_temp : "",
                table_name: requestDataAll.table_name !== "" ? requestDataAll.table_name : "",
                id_workflow_status: 0,
                request_no: "draft",
                request_date: newCurrentDate,
                id_business_unit: data.business_unit_other !== "" ? "-1" : data.id_business_unit,
                business_unit_other: data.business_unit_other,
                id_confidential_level: data.id_confidential_level ? data.id_confidential_level : "0",
                counterparty_name: data.counterparty_name,
                create_request_by: data?.create_request_by !== "" ? data.create_request_by : "",
                request_by: userEmail,
                require_handled_by: data.require_handled_by,
                approve_request_by: data.approve_request_by,
                status: "1"
            };

            // console.log(postdataRequest);

            let isSaveSucess = true;
            (async () => {
                try{
                    let dataUpdateRequestDraft = await UpdateRequestDraft(postdataRequest);
                    isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;

                    const {
                        approve_request_by,
                        require_handled_by,
                        ...postDataForm //delete data json
                    } = data;

                    // console.log(postDataForm);

                    const updatedDataCompany = {
                        ...postDataForm,
                        id_business_unit: dataUpdateRequestDraft.data[0].id_business_unit,
                    };

                    let getDataForm;
                    if( requestDataAll.table_name.toLowerCase() === "csa" ){
                        getDataForm = await UpdateForm(updatedDataCompany, "csa");
                        isSaveSucess = (getDataForm.is_sucess === true && isSaveSucess === true) ? true : false;
                    } else if( requestDataAll.table_name.toLowerCase() === "nda" ){
                        getDataForm = await UpdateForm(updatedDataCompany, "nda");
                        isSaveSucess = (getDataForm.is_sucess === true && isSaveSucess === true) ? true : false;
                    } else if( requestDataAll.table_name.toLowerCase() === "ldr" ){
                        getDataForm = await UpdateForm(updatedDataCompany, "ldr");
                        isSaveSucess = (getDataForm.is_sucess === true && isSaveSucess === true) ? true : false;
                    }

                    if ( requestDataAll.table_name.toLowerCase() !== "ldr" ){
                        let isFileName = await SetFileName(getDataForm.data[0].id, data.request_no, data);
                        isSaveSucess = (isFileName === true && isSaveSucess === true) ? true : false;

                        let changeRequiredHandle = await UpdateRequiredHandle(getDataForm.data[0].id , data);
                        isSaveSucess = (changeRequiredHandle.is_sucess === true && isSaveSucess === true) ? true : false;
                    }
                    
                    if(isSaveSucess === true){
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertSuccess,
                            title: "Save draft success.",
                            showConfirmButton: false,
                            showCloseButton: true,
                            // showConfirmButton: false,
                        }).then(() => {
                            // props.nextStep();
                            // history.push("/request/" + btoa(dataAddRequest.data[0].id));
                            // window.location.reload();
                        });
                    }else{
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to save draft data.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }
                } catch(error){
                    console.log(error);
                }
            })();
        } else {
            let isSaveSucess = true;
            // console.log(data);
            (async () => {
                try{
                    let getDataForm;
                    const {
                        approve_request_by,
                        require_handled_by,
                        ...postDataForm //delete data json
                    } = data;
                    if(data.business_unit_other !== "" && data.id_business_unit === "-1") {
                        const postdataRequest = {
                            ...dataRequest,
                            id: parseInt(atob(idRequest)),
                            id_business_unit : data.business_unit_other !== "" ? "-1" : data.id_business_unit,
                            business_unit_other : data.business_unit_other,
                            id_confidential_level: data.id_confidential_level,
                        };
                        let dataUpdateRequestDraft = await UpdateRequests(postdataRequest);
                        isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                        // console.log(dataUpdateRequestDraft);

                        const updatedDataCompany = {
                            ...postDataForm,
                            id_business_unit: dataUpdateRequestDraft.data[0].id_business_unit,
                        };
                        
                        if( requestDataAll.table_name.toLowerCase() === "csa" ){
                            getDataForm = await UpdateForm(updatedDataCompany, "csa");
                        } else if( requestDataAll.table_name.toLowerCase() === "nda" ){
                            getDataForm = await UpdateForm(updatedDataCompany, "nda");
                        } else if( requestDataAll.table_name.toLowerCase() === "ldr" ){
                            getDataForm = await UpdateForm(updatedDataCompany, "ldr");
                        }
                    } else {
                        const postdataRequest = {
                            ...dataRequest,
                            id: parseInt(atob(idRequest)),
                            id_business_unit : data.business_unit_other !== "" ? "-1" : data.id_business_unit,
                            business_unit_other : data.business_unit_other,
                            id_confidential_level: data.id_confidential_level,
                        };
                        // console.log(postdataRequest);
                        let dataUpdateRequestDraft = await UpdateRequests(postdataRequest);
                        isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                        
                        // getDataForm = await UpdateForm(postDataForm);
                        if( requestDataAll.table_name.toLowerCase() === "csa" ){
                            getDataForm = await UpdateForm(postDataForm, "csa");
                        } else if( requestDataAll.table_name.toLowerCase() === "nda" ){
                            getDataForm = await UpdateForm(postDataForm, "nda");
                        } else if( requestDataAll.table_name.toLowerCase() === "ldr" ){
                            getDataForm = await UpdateForm(postDataForm, "ldr");
                        }
                    }
                    
                    isSaveSucess = (getDataForm.is_sucess === true && isSaveSucess === true) ? true : false;
                    // console.log(dataFormCsa);

                    let isFileName = await SetFileName(getDataForm.data[0].id, data.request_no, data);
                    isSaveSucess = (isFileName === true && isSaveSucess === true) ? true : false;
                    // console.log(isSaveSucess);

                    let changeRequiredHandle = await UpdateRequiredHandle(getDataForm.data[0].id , data);
                    isSaveSucess = (changeRequiredHandle.is_sucess === true && isSaveSucess === true) ? true : false;
                    
                    if(isSaveSucess === true){
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertSuccess,
                            title: "Save success.",
                            showConfirmButton: false,
                            showCloseButton: true,
                            // showConfirmButton: false,
                        }).then(() => {
                            // props.nextStep();
                            // history.push("/request/" + btoa(dataAddRequest.data[0].id));
                        });
                    }else{
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to save data.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }
                } catch(error){
                    console.log(error);
                }
            })();
        }
        
    };

    const onApprove = (data) => {
        // console.log(dataForm);
        setIsShowModalApprove(!isShowModalApprove);
        Swal.fire({
            icon: 'info',
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            willOpen: () => {
                Swal.showLoading();
            }
        });

        let isSendMail;

        let isSaveSucess = true;
        (async () => {
            try{
                let dataUpdateRequest = await UpdateRequestToApprove(data.id);
                isSaveSucess = (dataUpdateRequest.is_sucess === true && isSaveSucess === true) ? true : false;

                let getDataForm;

                if(dataFormApprove?.business_unit_other !== "" && dataFormApprove?.id_business_unit === "-1") {
                    const postdataRequest = {
                        ...dataRequest,
                        id: parseInt(atob(idRequest)),
                        id_business_unit : dataFormApprove.business_unit_other !== "" ? "-1" : dataFormApprove.id_business_unit,
                        business_unit_other : dataFormApprove.business_unit_other,
                    
                    };
                    let dataUpdateRequestDraft = await UpdateRequests(postdataRequest);
                    isSaveSucess = (dataUpdateRequestDraft.is_sucess === true && isSaveSucess === true) ? true : false;
                    // console.log(dataUpdateRequestDraft);

                    const updatedDataCompany = {
                        ...dataFormApprove,
                        id_business_unit: dataUpdateRequestDraft.data[0].id_business_unit,
                    };
                    if( requestDataAll.table_name.toLowerCase() === "csa" ){
                        getDataForm = await UpdateForm(updatedDataCompany, "csa");
                    } else if( requestDataAll.table_name.toLowerCase() === "nda" ){
                        getDataForm = await UpdateForm(updatedDataCompany, "nda");
                    } else if( requestDataAll.table_name.toLowerCase() === "ldr" ){
                        getDataForm = await UpdateForm(updatedDataCompany, "ldr");
                    }
                    
                } else {
                    // dataFormCsa = await UpdateForm(dataFormApprove);
                    if( requestDataAll.table_name.toLowerCase() === "csa" ){
                        getDataForm = await UpdateForm(dataFormApprove, "csa");
                    } else if( requestDataAll.table_name.toLowerCase() === "nda" ){
                        getDataForm = await UpdateForm(dataFormApprove, "nda");
                    } else if( requestDataAll.table_name.toLowerCase() === "ldr" ){
                        getDataForm = await UpdateForm(dataFormApprove, "ldr");
                    }
                }
                isSaveSucess = (getDataForm.is_sucess === true && isSaveSucess === true) ? true : false;

                let emailLegalAdmin = Array.isArray(dataFormApprove.legal_admin) ? dataFormApprove.legal_admin.map(item => item.email).join(',') : '';
                let emailLegalAuthorized = Array.isArray(dataFormApprove.legal_authorized) ? dataFormApprove.legal_authorized.map(item => item.email).join(',') : '';
                let requireHandledBy = dataFormApprove.require_handled_by;
                let combinedEmails;
                
                if (requestDataAll.table_name.toLowerCase() === "ldr" ){
                    combinedEmails = [emailLegalAdmin].filter(Boolean).join(',');
                } else {
                    combinedEmails = [emailLegalAdmin, emailLegalAuthorized, requireHandledBy].filter(Boolean).join(',');
                    let changeRequiredHandle = await UpdateRequiredHandle(data.id , dataFormApprove);
                    isSaveSucess = (changeRequiredHandle.is_sucess === true && isSaveSucess === true) ? true : false;
                }

                const dataRequestWorkFlows = {
                    "id_request": data.id,
                    "id_workflow_status" : 3,
                    "do_by" : userEmail,
                    "send_to" : combinedEmails,
                    "cc_to" : null,
                    "comment" : null,
                    "document" : null,
                    "consent" : null,
                    "do_date" : newDateTime,
                    "finish_date" : newDateTime,
                };

                let dataRequestWorkFlow = await AddRequestWorkFlow(dataRequestWorkFlows);
                isSaveSucess = (dataRequestWorkFlow.is_sucess === true && isSaveSucess === true) ? true : false;
                
                if(isSaveSucess === true){
                    isSendMail = await SendMailApproveToLegal(dataRequestWorkFlow.data[0]);
                    if( isSendMail === true ){
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                                iconHtml: IconAlertSuccess,
                                title: "Sending request to Legal Team.",
                                showConfirmButton: false,
                                showCloseButton: true,
                                // showConfirmButton: false,
                            }).then(() => {
                                // props.nextStep();
                                history.push("/requests");
                            });
                    }else{
                        Swal.close(); // Close loading spinner
                        Swal.fire({
                            iconHtml: IconAlertError,
                            title: "Failed to send email to Legal Team.",
                            showConfirmButton: false,
                            showCloseButton: true,
                        });
                    }
                }else{
                    Swal.close(); // Close loading spinner
                    Swal.fire({
                        iconHtml: IconAlertError,
                        title: "Failed to save data.",
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                }
            } catch(error){
                console.log(error);
            }
        })();
    };

    const AddRequestWorkFlow = async (dataRequestWorkFlows) => {
        let url = ConfigData.API_URL + "request-workflow/";
        let data = dataRequestWorkFlows;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.post(url, data, { headers: { 
                                'Authorization' : token,
                                'User': userId,
                            }, responseType: "json" }
                        );
            let respData = resp.data;
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateRequestDraftTrans = async (id, data, form) => {
        let url;
        if(form === "csa"){
            url = ConfigData.API_URL + "request/update-csa-trans/" + id;
        } else if(form === "nda"){
            url = ConfigData.API_URL + "request/update-nda-trans/" + id;
        } else if(form === "ldr"){
            url = ConfigData.API_URL + "request/update-ldr-trans/" + id;
        }

        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
    
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };
    
    const UpdateRequestDraft = async (data) => {
        let url = ConfigData.API_URL + "request/draft/" + data.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        if(data.id_business_unit === ""){
            data.id_business_unit = "-1";
        }
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateRequests = async (data) => {
        let url = ConfigData.API_URL + "request/" + data.id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateForm = async (data, formName) => {
        let url;
        if( formName === "csa" ){
            url = ConfigData.API_URL + "form-csa/" + data.id;
            if(data.id_confidential_level === ""){
                data.id_confidential_level = "0";
            }   
            if(data.term_start_date === ""){
                data.term_start_date = null;
            }  
            if(data.term_end_date === ""){
                data.term_end_date = null;
            }  
        } else if( formName === "nda" ){
            url = ConfigData.API_URL + "form-nda/" + data.id;
            if(data.id_confidential_level === ""){
                data.id_confidential_level = "0";
            }  
        } else if( formName === "ldr" ){
            url = ConfigData.API_URL + "form-ldr/" + data.id;
        }
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // console.log(respData);
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateRequestToApprove = async (idRequest) => {
        let url = ConfigData.API_URL + "request/approve-request-form/" + idRequest;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url,{}, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const UpdateRequiredHandle = async (id, data) => {
        // console.log(data);
        let url = ConfigData.API_URL + "request/change-legal-identified/" + id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.put(url, data, { headers: { 
                                        'Authorization' : token,
                                        'User': userId,
                                    }, responseType: "json" });
            let respData = resp.data;
            // setDataIdRequest(respData.data)
            return respData;
        }catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const DeleteDraft = async (id) => {
        let url = ConfigData.API_URL + "request/" + id;
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try {
            const resp = await axios.delete(url, {
                headers: {
                    'Authorization': token,
                    'User': userId,
                },
                responseType: "json",
            });
            let respData = resp.data;
            // Handle respData as needed
            return respData;
        } catch (error) {
            // Handle error as needed
            console.log(error);
            return false;
        }
    };

    const SetFileName = async (id, requestNo, data) => {
        let url;
        let formData = new FormData();
        formData.append("request_no", requestNo);

        if( requestDataAll.table_name?.toLowerCase() === "csa" ){
            url = ConfigData.API_URL + "form-csa/set-filename/" + id;

            formData.append("n_draft_doc", getFilterOldFileName(data.file_draft_doc));
            formData.append("n_counterparty_doc", getFilterOldFileName(data.file_counterparty_doc));
            formData.append("n_is_sla_doc", getFilterOldFileName(data.file_is_sla_doc));
            formData.append("n_scope_of_work_doc", getFilterOldFileName(data.file_scope_of_work_doc));
            formData.append("n_consider_doc", getFilterOldFileName(data.file_consider_doc));
            formData = setFormDataUploadFile(formData, "f_draft_doc", data.file_draft_doc);
            formData = setFormDataUploadFile(formData, "f_counterparty_doc", data.file_counterparty_doc);
            formData = setFormDataUploadFile(formData, "f_is_sla_doc", data.file_is_sla_doc);
            formData = setFormDataUploadFile(formData, "f_scope_of_work_doc", data.file_scope_of_work_doc);
            formData = setFormDataUploadFile(formData, "f_consider_doc", data.file_consider_doc);
        } else if( requestDataAll.table_name?.toLowerCase() === "nda" ){
            url = ConfigData.API_URL + "form-nda/set-filename/" + id;

            formData.append("n_draft_doc", getFilterOldFileName(data.file_draft_doc));
            formData.append("n_consider_doc", getFilterOldFileName(data.file_consider_doc));
            formData = setFormDataUploadFile(formData, "f_draft_doc", data.file_draft_doc);
            formData = setFormDataUploadFile(formData, "f_consider_doc", data.file_consider_doc);
        }
        
        let token = "Bearer " + localStorage.getItem("tkd");
        let userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        
        try{
            const resp = await axios.put(url, formData, { headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            // console.log(respData);
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }
    };

    const SendEmailToApprover = async (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.approve_request_by.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 1,
            "request_id": data.id,
        };
        try {
            const resp = await axios.post(url, dataReq, { headers: { 
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        } catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };
  
    const SendMailApproveToLegal = async (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.send_to.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 3,
            "request_id": data.id_request,
        };
        try {
            const resp = await axios.post(url, dataReq, { headers: { 
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        } catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const SendEmailChangeUserCreateRequest = async (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "": atob(localStorage.getItem("emd"));
        let arrTo = data.request_by.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 21,
            "request_id": data.id,
        };
        try {
            const resp = await axios.post(url, dataReq, { headers: { 
                'Authorization': token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        } catch(error){
            // handlerRespError(error, history);
            console.log(error);
            return false;
        }
    };

    const onCheckApprove = (data) => {
        setdataFormApprove(data);
        // console.log(data);
        if(data.request_no === "draft" || data.id_workflow_status === 4){
            setIsShowModalSubmit(true);
        } else {
            setIsShowModalApprove(true);
        }
    };

    return (
        <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                    {/* Request Detail{" > "+requestDataAll.request_no} */}
                        <Row>
                            <Col xl={12}>
                                <div>
                                    <StepWorkflow step={activeStep} />
                                    {/* check if step 1 , 2 , 4 */}
                                    { checkStepViewForm ? (
                                        <DownloadPDF fileName={`form-csa-${requestDataAll.request_no}.pdf`} type={"formInput"} >
                                            <div>
                                            {/* start component type information */}
                                            <div id="section-page-1-1">
                                                <Col xl={10} className="mx-auto">
                                                    <Row>
                                                        <Col md={8}>
                                                            <span className="span-type-request">ข้อมูลของผู้ทำคำขอ (User Information)</span>
                                                        </Col>
                                                        {/* { requestDataAll.request_no === "draft" && ( // check if draft */}
                                                            <Col md={2}>
                                                                <div id="section-page-hide-input">
                                                                    <span>
                                                                        <Label htmlFor="export-pdf" className="form-export-file-label">
                                                                            <i className="mdi mdi-upload i-upload"></i>
                                                                            Export
                                                                        </Label>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        {/* )} */}
                                                    </Row>
                                                </Col>
                                                <br/>
                                                <Col md={10} className="mx-auto">
                                                    <Row>
                                                        <Col md={5}>
                                                            <FormGroup>
                                                                <Label className="request_label_nowrap">คำขอเลขที่ (Request No.)</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="requestNo"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={requestDataAll.request_no || ''}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={5}>
                                                            <FormGroup>
                                                                <Label className="request_label_nowrap">วันที่สร้างคำขอ (Date of Request)</Label>
                                                                <Input
                                                                type="text"
                                                                name="dateOfRequest"
                                                                placeholder=""
                                                                // onChange={onInputChanged}
                                                                value={requestDataAll.request_date || ''}
                                                                disabled
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={10} className="mx-auto">
                                                    <Row>
                                                        { checkShowInputUserCoordinator ? (
                                                        <>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="userCreateReq" className="request_label">ผู้สร้างคำขอ (User's Create Request)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={userCreateRequest.name+" "+userCreateRequest.surname || ""}
                                                                    disabled
                                                                    />
                                                                    {/* <Label className="request_label-val">
                                                                    {userCreateRequest.name+" "+userCreateRequest.surname || ""}
                                                                    </Label> */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="emailCreateReq" className="request_label">อีเมลผู้สร้างคำขอ (User's Create Request Email)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={requestDataAll.create_request_by || ""}
                                                                    disabled
                                                                    />
                                                                    {/* <Label className="request_label-val">
                                                                    {requestDataAll.create_request_by || ""}
                                                                    </Label> */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="ddlBu" className="request_label">บริษัทที่สังกัด (User's BU)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={userCreateRequest.business_unit || ""}
                                                                    disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="ddlDepartment" className="request_label">แผนกที่สังกัด (User's Department)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="department"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={userCreateRequest.department || ""}
                                                                    disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="line-border"></div>
                                                            </Col>
                                                            <Col xl={12} className="mx-auto">
                                                                <div>
                                                                    <span className="span-type-request">ข้อมูลของผู้ประสานงาน (User Coordinator Information)</span>
                                                                </div>
                                                            </Col> <br/> <br/>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="coordinatorReq" className="request_label">ผู้ประสานงาน (User's Coordinator Request)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={dataCompany.name+" "+dataCompany.surname || ""}
                                                                    disabled
                                                                    />
                                                                    {/* <Label className="request_label-val">
                                                                        {dataCompany.name+" "+dataCompany.surname || ""}
                                                                    </Label> */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="emailCoordinator" className="request_label">อีเมลผู้ประสานงาน (User's Coordinator Email)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={requestDataAll.request_by || ""}
                                                                    disabled
                                                                    />
                                                                    {/* <Label className="request_label-val">
                                                                        {requestDataAll.request_by || ""}
                                                                    </Label> */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="ddlBu" className="request_label">บริษัทที่สังกัด (User's BU)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={dataCompany.business_unit || ""}
                                                                    disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="ddlDepartment" className="request_label">แผนกที่สังกัด (User's Department)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="department"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={dataCompany.department || ""}
                                                                    disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            </>
                                                        ):(
                                                            <>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="coordinatorReq" className="request_label">ผู้ทำคำขอ (User's Request)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={dataCompany.name+" "+dataCompany.surname || ""}
                                                                    disabled
                                                                    />
                                                                    {/* <Label className="request_label-val">
                                                                        {dataCompany.name+" "+dataCompany.surname || ""}
                                                                    </Label> */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="emailCoordinator" className="request_label">อีเมลผู้ทำคำขอ (User's Email)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={requestDataAll.request_by || ""}
                                                                    disabled
                                                                    />
                                                                    {/* <Label className="request_label-val">
                                                                        {requestDataAll.request_by || ""}
                                                                    </Label> */}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="ddlBu" className="request_label">บริษัทที่สังกัด (User's BU)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="businessUnit"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={dataCompany.business_unit || ""}
                                                                    disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5}>
                                                                <FormGroup>
                                                                    <Label htmlFor="ddlDepartment" className="request_label">แผนกที่สังกัด (User's Department)</Label>
                                                                    <Input
                                                                    type="text"
                                                                    name="department"
                                                                    placeholder=""
                                                                    // onChange={onInputChanged}
                                                                    value={dataCompany.department || ""}
                                                                    disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            </>
                                                        )}
                                                        <Col md={12}>
                                                            <div className="line-border"></div>
                                                        </Col>
                                                        </Row>
                                                </Col>
                                            </div>
                                                <Col md={10} className="mx-auto">
                                                    <Row>
                                                        <Col md={12}>
                                                            { requestDataAll.table_name?.toLowerCase() === "csa" ? (
                                                                <RequestFormCsa dataFormCsa={dataForm} dataFormRequest={requestDataAll} onSaveDraft={onUpdateForm} onSave={onCheckApprove} onCancelRequest={onCancelRequest}/>
                                                            ) 
                                                            :
                                                            requestDataAll.table_name?.toLowerCase() === "nda" ? (
                                                                <RequestFormNda dataFormNda={dataForm} dataFormRequest={requestDataAll} onSaveDraft={onUpdateForm} onSave={onCheckApprove} onCancelRequest={onCancelRequest} />
                                                            )
                                                            // :
                                                            //   requestDataAll.table_name?.toLowerCase() === "ldr" ? (
                                                            //     <RequestFormLdr dataFormLdr={dataForm} dataFormRequest={requestDataAll} onSaveDraft={onUpdateForm} onSave={onCheckApprove} onCancelRequest={onCancelRequest} />
                                                            // )
                                                            :
                                                            (
                                                                <div>form other</div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            {/* end component user information */}
                                                <br />
                                            {/* check state button is request_no = draft ? */}
                                            {/* หน้านี้ จะมี action ปุ่ม approve ส่วน จะมี ปุ่ม action submit ก็ต่อเมื่อ มี การ return กลับมาแล้วเท่านั้น */}
                                                
                                            </div>
                                        </DownloadPDF>
                                    ): "Review" } {/* check if step 3 หมายถึง approver approve request เรียบร้อยแล้ว จะไปที่หน้า review ต่อไป */}
                                    {/* </StepWizard> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
             {/* Modal Add and Edit */}
            <Modal id="modalApprove"
                isOpen={isShowModalApprove}
                toggle={() => { setIsShowModalApprove(!isShowModalApprove); }}
                backdrop={'static'}
                centered
            >
                {/* <ModalHeader id="modalBUHeader" 
                    className="modal-title"
                    toggle={() => { setIsShowModal(!isShowModal); }}
                >
                </ModalHeader> */}
                <ModalBody>
                <Row>
                    <p><i className="mdi mdi-alert-decagram modal-alert"></i></p>
                    <p className="modal-note">Please note</p>
                    <p className="modal-content-approve">that the information provided in the request form is only a preliminary information for review/drafting process.<br/>Legal team may request for more information at a later stage.</p> 
                </Row>
                </ModalBody>
                <ModalFooter>
                <Row>
                    <Col md="12">
                        <Button 
                            className="float-end" 
                            color="primary" 
                            type="button"
                            onClick={() => onApprove(requestDataAll)}
                        >
                            Ok
                        </Button>
                        <Button 
                            className="float-end" 
                            color="outline secondary" 
                            type="button" 
                            onClick={() => setIsShowModalApprove(false) }  
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
                </ModalFooter>
            </Modal>
             
            <Modal id="modalSubmit"
                isOpen={isShowModalSubmit}
                toggle={() => { setIsShowModalSubmit(!isShowModalSubmit); }}
                backdrop={'static'}
                centered
            >
                <ModalBody>
                        <Row>
                            <p><i className="mdi mdi-alert-decagram modal-alert"></i></p>
                            <p className="modal-note">Please note</p>
                            <p className="modal-content-approve">that the information provided in the request form is only a preliminary information for review/drafting process.<br/>Legal team may request for more information at a later stage.</p> 
                        </Row>
                </ModalBody>
                <ModalFooter>
                        <Row>
                            <Col md="12">
                                <Button 
                                    className="float-end" 
                                    color="primary" 
                                    type="button"
                                    onClick={() => onClickOk(dataFormApprove)}
                                >
                                    Ok
                                </Button>
                                <Button 
                                    className="float-end" 
                                    color="outline secondary" 
                                    type="button" 
                                    onClick={() => setIsShowModalSubmit(false) } 
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                </ModalFooter>
            </Modal>
            
        </React.Fragment>
    );
};

export default RequestDetail;
