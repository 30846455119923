import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from 'sweetalert2';
import { GolbalDataContext } from '../../components/store/global-data-provider';
import ConfigData from "../../config.json";
import StepWorkflow from '../../components/common/step-workflow';
import { IconAlertSuccess } from '../../components/common/popup-alert';

const PrefinalContractUser = () => {
    const { id } = useParams();
    const history = useHistory();
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [dataRequest, setDataRequest] = useState({
        "id": 0,
        "request_no": "",
        "select_legal_approve_by": "",
        "legal1_approve_by": "",
        "legal2_approve_by": "",
        "legal3_approve_by": "",
        "select_user_approve_by": "",
        "user1_approve_by": "",
        "user2_approve_by": "",
        "user3_approve_by": "",
        "note": "",
        "send_to": ""
    });
    const englishEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        changeTitlePage("All Request");
        let strId = "0";
        try{
            strId = atob(id);
        }catch(error){
            strId = "0";
        }
        LoadUserCanDoReq(strId);
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataRequest.id || 0,
            request_no: dataRequest.request_no || "",
            select_legal_approve_by: dataRequest.select_legal_approve_by || "",
            legal1_approve_by: dataRequest.legal1_approve_by || "",
            legal2_approve_by: dataRequest.legal2_approve_by || "",
            legal3_approve_by: dataRequest.legal3_approve_by || "",
            select_user_approve_by: dataRequest.select_user_approve_by || "",
            user1_approve_by: dataRequest.user1_approve_by || "",
            user2_approve_by: dataRequest.user2_approve_by || "",
            user3_approve_by: dataRequest.user3_approve_by || "",
            note: dataRequest.note || "",
            send_to: dataRequest.send_to || "",
        },
        validationSchema: Yup.object({
            // user1_approve_by: Yup.string().required("Please enter a approver 1"),
            user1_approve_by: Yup.string().email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
            user2_approve_by: Yup.string().email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
            user3_approve_by: Yup.string().email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
        }),
        onSubmit: (values) => {
            // console.log(values);
            // console.log(fileLegalFinalDoc);
            SavePreFinalContactUser(values);
        }
    });

    const OnClickBack = () => {
        // history.push("/dashboard");
        history.push("/requests");
    };

    const LoadDataRequest = (id) => {
        let url = ConfigData.API_URL + "request/" + id ;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const objData = {
                    "id": respData.data[0].id,
                    "request_no": respData.data[0].request_no,
                    "select_legal_approve_by": respData.data[0].select_legal_approve_by,
                    "legal1_approve_by": respData.data[0].legal1_approve_by,
                    "legal2_approve_by": respData.data[0].legal2_approve_by,
                    "legal3_approve_by": respData.data[0].legal3_approve_by,
                    "select_user_approve_by": respData.data[0].select_user_approve_by,
                    "user1_approve_by": respData.data[0].user1_approve_by,
                    "user2_approve_by": respData.data[0].user2_approve_by,
                    "user3_approve_by": respData.data[0].user3_approve_by,
                    "send_to": respData.data[0].legal1_approve_by,
                    "note": ""
                };
                LoadDataReqWorkflowStatus(respData.data[0].id, 17, objData);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataReqWorkflowStatus = (idReq, idWorkflowStatus, objReq) => {
        let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq  + "&idWorkflowStatus=" + idWorkflowStatus;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                objReq.note = respData.data[0].comment;
            }
            setDataRequest(objReq);
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
            setDataRequest(objReq);
        });
    };

    const LoadUserCanDoReq = (id) => {
        let userDo = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let url = new URL(ConfigData.API_URL + "request/user-can-do");
        url.searchParams.set("email", userDo);
        url.searchParams.set("id-request", id);
        url.searchParams.set("id-workflow", "16"); 
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const arrUserDo = respData.data[0].user_do_request.split(",");
                if(arrUserDo.find((e) => e === userDo) !== undefined){
                    LoadDataRequest(id);    
                }else{
                    history.push("/summary-request/" + btoa(id));
                }
            }else{
                // history.push("/dashboard");
                history.push("/requests");
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const SavePreFinalContactUser = (values) => {
        if(values.id > 0){
            let isSaveSucess = true;
            (async () => {
                let isSaveFinalContractUser = await UpdateFinalContractUser(values);
                isSaveSucess = (isSaveFinalContractUser === true && isSaveSucess === true) ? true : false;
                let isSaveReqWorkflowStatus = await AddWorkFlowStaus(values);
                isSaveSucess = (isSaveReqWorkflowStatus === true && isSaveSucess === true) ? true : false;
                if(isSaveSucess === true){
                    SendEmail(values);
                    Swal.fire({
                        iconHtml: IconAlertSuccess,
                        title: "ส่งคำขอเรียบร้อย\nอยู่ระหว่างส่งคำขอสุดท้ายให้ผู้อนุมัติขั้นสุดท้าย\nSubmitted the request\nsending to final approvers",
                        showConfirmButton: false,
                        showCloseButton: true,
                    }).then(() => {
                        // history.push("/dashboard");
                        history.push("/requests");
                    });
                }
            })();
        }
    };

    const UpdateFinalContractUser = async (dataReq) => {
        const url = ConfigData.API_URL + "request/prefilnal-by-user/" + dataReq.id;
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        dataReq.select_user_approve_by = userId;
        try{
            const resp = await axios.put(url, dataReq, { headers: { 
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }

    };

    const AddWorkFlowStaus = async (dataReq) => {
        const url = ConfigData.API_URL + "request-workflow/step";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try{
            let formData = new FormData();
            formData.append("id_request", dataReq.id);
            formData.append("id_workflow_status", "17");
            formData.append("do_by", userId);
            formData.append("send_to", dataReq.send_to);
            formData.append("comment", dataReq.note);
            const resp = await axios.post(url, formData, { headers: { 
                            'Authorization' : token,
                            'User': userId,
                        }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }
    };

    const SendEmail = (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data.send_to.split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 12,
            "request_id": data.id,
        };
        axios.post(url, dataReq, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            // console.log(resp.data);
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="prefinal-contract-user">
                    <Row>
                        <Col lg={12}>
                            <p className="pcu-title">Request Detail {"> " + dataRequest.request_no}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <StepWorkflow step={7} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={8} lg={6}>
                            <Form className="needs-validation"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row className="pcu-legal-div">
                                    <Col lg={12}>
                                        <p className="pcu-legal-title">ฝ่ายกฏหมายได้เลือกผู้อนุมัติขั้นตอนสุดท้าย<br/>(Legal team selected final approver(s)</p>
                                        <p className="pcu-legal-desc">เพื่ออนุมัติตามลำดับ ดังนี้ (with the following approval sequence.)</p>
                                        {(dataRequest.legal1_approve_by !== "") ? <p className="pcu-legal-user">1.{dataRequest.legal1_approve_by}</p> : null}
                                        {(dataRequest.legal2_approve_by !== "") ? <p className="pcu-legal-user">2.{dataRequest.legal2_approve_by}</p> : null}
                                        {(dataRequest.legal3_approve_by !== "") ? <p className="pcu-legal-user">3.{dataRequest.legal3_approve_by}</p> : null}
                                        <div className="pcu-legal-remark">
                                            <p className="pcu-legal-remark-title">
                                                <i className="mdi mdi-alert"></i>
                                                หมายเหตุ (Remark)
                                            </p>
                                            <p className="pcu-legal-remark-desc">
                                                ผู้ทำคำขอมีหน้าที่ในการส่งเอกสารที่ลงนามแล้ว ให้แก่บุคคลหรือฝ่ายที่เกี่ยวข้อง เช่น แผนกบัญชี หรือ การเงิน
                                                <br/>Please note that user is responsible for sending the signed document(s) to related party
                                                <br/>or related department (such as accounting or finance team.)
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <p className="pcu-form-header">ท่านสามารถเลือกผู้อนุมัติขั้นสุดท้ายเพิ่มเติมได้<br/>You can select additional final approval</p>
                                        <p className="pcu-form-title">
                                            ท่านสามารถเลือกผู้อนุมัติได้ 1-3 คน โดยการอนุมัติจะถูกเรียงตามลำดับที่ท่านเลือก<br/>
                                            (You can select 1-3 persons.The approval will be arranged according to your selection.)
                                        </p>
                                        <FormGroup>
                                            <Label className="pcu-form-label" htmlFor="txtApprove1">1.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย(ถ้ามี) (Require Final Approval From(If any)</Label>
                                            <Input id="txtApprove1" 
                                                name="user1_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.user1_approve_by || ""}
                                                invalid={ validation.touched.user1_approve_by && validation.errors.user1_approve_by ? true : false }    
                                            />
                                            { validation.touched.user1_approve_by && validation.errors.user1_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.user1_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label className="pcu-form-label" htmlFor="txtApprove2">2.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย(ถ้ามี) (Require Final Approval From(If any)</Label>
                                            <Input id="txtApprove2" 
                                                name="user2_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.user2_approve_by || ""}
                                                invalid={ validation.touched.user2_approve_by && validation.errors.user2_approve_by ? true : false } 
                                            />
                                            { validation.touched.user2_approve_by && validation.errors.user2_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.user2_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label className="pcu-form-label" htmlFor="txtApprove3">3.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย(ถ้ามี) (Require Final Approval From(If any)</Label>
                                            <Input id="txtApprove3" 
                                                name="user3_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.user3_approve_by || ""}
                                                invalid={ validation.touched.user3_approve_by && validation.errors.user3_approve_by ? true : false }
                                            />
                                            { validation.touched.user3_approve_by && validation.errors.user3_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.user3_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="pcu-note-div">
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label className="pcu-form-label" htmlFor="txtNote">Note</Label>
                                            <Input id="txtNote"
                                                name="note"
                                                placeholder="Note"
                                                type="textarea"
                                                rows={1}
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.note || ""}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="page-footer">
                                    <div className="layout-width-footer">
                                        <div className="navbar-footer2">
                                            <Button type="button" className="pcu-btn-back" onClick={(e) => { OnClickBack(); }}>
                                                Back
                                            </Button>
                                            <Button type="submit" color='primary' className="pcu-btn-submit">
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PrefinalContractUser;