import React, { useContext, useEffect, useState, lazy, Suspense, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from 'sweetalert2';
import { GolbalDataContext } from '../../components/store/global-data-provider';
import ConfigData from "../../config.json";
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import StepWorkflow from '../../components/common/step-workflow';
import SingleUploadfile from '../../components/common/single-uploadfile';
import { getFilterOldFileName, setFormDataUploadFile, setStringFileNameToArray } from '../../components/utils/format-value';
import { IconAlertSuccess } from '../../components/common/popup-alert';

const PrefinalContractReject = () => {
    const { id } = useParams();
    const history = useHistory();
    const { changeTitlePage } = useContext(GolbalDataContext);
    const [keySendTo, setKeySendTo] = useState("");
    const [fileLegalFinalDoc, setFileLegalFinalDoc] = useState([]);
    const [fileMemoDoc, setFileMemoDoc] = useState([]);
    const [dataRequest, setDataRequest] = useState({
        "id": 0,
        "id_workflow_status": 0,
        "request_no": "",
        "request_by": "",
        "final_doc": "-",
        "memo_doc": "",
        "select_legal_approve_by": "",
        "legal1_approve_by": "",
        "legal2_approve_by": "",
        "legal3_approve_by": "",
        "select_user_approve_by": "",
        "user1_approve_by": "",
        "user2_approve_by": "",
        "user3_approve_by": "",
        "send_to": "",
        "reject_by": "",
        "reject_note": "",
    });
    const arrSeqApprover = [
        {"no": 1, "column": "legal1_approve_by"},
        {"no": 2, "column": "legal2_approve_by"},
        {"no": 3, "column": "legal3_approve_by"},
        {"no": 4, "column": "user1_approve_by"},
        {"no": 5, "column": "user2_approve_by"},
        {"no": 6, "column": "user3_approve_by"},
    ];
    const englishEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        changeTitlePage("All Request");
        let strId = "0";
        try{
            strId = atob(id);
        }catch(error){
            strId = "0"
        }
        LoadUserCanDoReq(strId);
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataRequest.id || 0,
            id_workflow_status: dataRequest.id_workflow_status || 0,
            request_no: dataRequest.request_no || "",
            final_doc: dataRequest.final_doc || "",
            memo_doc: dataRequest.memo_doc || "",
            select_legal_approve_by: dataRequest.select_legal_approve_by || "",
            legal1_approve_by: dataRequest.legal1_approve_by || "",
            legal2_approve_by: dataRequest.legal2_approve_by || "",
            legal3_approve_by: dataRequest.legal3_approve_by || "",
            select_user_approve_by: dataRequest.select_user_approve_by || "",
            user1_approve_by: dataRequest.user1_approve_by || "",
            user2_approve_by: dataRequest.user2_approve_by || "",
            user3_approve_by: dataRequest.user3_approve_by || "",
            send_to: dataRequest.send_to || "",
        },
        validationSchema: Yup.object({
            final_doc: Yup.string().required("กรุณาแนบไฟล์ร่างเอกสารสุดท้าย/Please attach the finalized document"),
            memo_doc: Yup.string().required("กรุณาแนบไฟล์ Memo ที่ลงนามแล้ว/Please attach the signed Memo"),
            legal1_approve_by: Yup.string().required("กรุณาระบุผู้อนุมัติขั้นสุดท้ายอย่างน้อย 1 คน/Please specify at least 1 final approver.").email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
            legal2_approve_by: Yup.string().email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
            legal3_approve_by: Yup.string().email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
            user1_approve_by: Yup.string().email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
            user2_approve_by: Yup.string().email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
            user3_approve_by: Yup.string().email("Please enter a Email Address.").matches(englishEmailRegex, "Email must be a valid English email address"),
        }),
        onSubmit: (values) => {
            // console.log(fileLegalFinalDoc);
            SavePrefinalContractReject(values);
        }
    });

    const OnSetFileLegalFinalDoc = (file) => {
        const fileName = (file.length > 0) ? file[0].name : "";
        setFileLegalFinalDoc(file);
        validation.handleChange('final_doc')(fileName);
    };

    const OnSetFileMemoDoc = (file) => {
        const fileName = (file.length > 0) ? file[0].name : "";
        setFileMemoDoc(file);
        validation.handleChange('memo_doc')(fileName);
    };

    const OnClickBack = () => {
        // history.push("/dashboard");
        history.push("/requests");
    };

    const LoadDataRequest = (id) => {
        let url = ConfigData.API_URL + "request/" + id ;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                dataRequest.id = respData.data[0].id;
                dataRequest.id_workflow_status = respData.data[0].id_workflow_status;
                dataRequest.request_no = respData.data[0].request_no;
                dataRequest.request_by = respData.data[0].request_by;
                dataRequest.final_doc = respData.data[0].final_doc;
                dataRequest.memo_doc = respData.data[0].memo_doc;
                dataRequest.select_legal_approve_by = respData.data[0].select_legal_approve_by;
                dataRequest.legal1_approve_by = respData.data[0].legal1_approve_by;
                dataRequest.legal2_approve_by = respData.data[0].legal2_approve_by;
                dataRequest.legal3_approve_by = respData.data[0].legal3_approve_by;
                dataRequest.select_user_approve_by = respData.data[0].select_user_approve_by;
                dataRequest.user1_approve_by = respData.data[0].user1_approve_by;
                dataRequest.user2_approve_by = respData.data[0].user2_approve_by;
                dataRequest.user3_approve_by = respData.data[0].user3_approve_by;
                setFileLegalFinalDoc(setStringFileNameToArray(respData.data[0].final_doc));
                setFileMemoDoc(setStringFileNameToArray(respData.data[0].memo_doc));
                LoadDataReqWorkflowStatus(respData.data[0].id, respData.data[0].id_workflow_status);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadDataReqWorkflowStatus = (idReq, idWorkflowStatus) => {
        let url = ConfigData.API_URL + "request-workflow/search?status=1&idRequest=" + idReq  + "&idWorkflowStatus=" + idWorkflowStatus;
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: {
            'Authorization' : token,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                dataRequest.reject_by = respData.data[0].do_by;
                dataRequest.reject_note = respData.data[0].comment;
                const strKeySendTo = Object.keys(dataRequest).find(key => dataRequest[key] === respData.data[0].do_by);
                setKeySendTo(strKeySendTo);
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const LoadUserCanDoReq = (id) => {
        let userDo = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let url = new URL(ConfigData.API_URL + "request/user-can-do");
        url.searchParams.set("email", userDo);
        url.searchParams.set("id-request", id);
        url.searchParams.set("id-workflow", "19"); 
        let token = "Bearer " + localStorage.getItem("tkd");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                const arrUserDo = respData.data[0].user_do_request.split(",");
                if(arrUserDo.find((e) => e === userDo) !== undefined){
                    LoadDataRequest(id);
                }else{
                    history.push("/summary-request/" + btoa(id));
                }
            }else{
                // history.push("/dashboard");
                history.push("/requests");
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const SavePrefinalContractReject = (values) => {
        if(values.id > 0){
            let isSaveSucess = true;
            (async () => {
                let isSavePrefinalContractReject = await UpdatePrefinalContractReject(values);
                isSaveSucess = (isSavePrefinalContractReject === true && isSaveSucess === true) ? true : false;
                let isSaveReqWorkflowStatus = await AddWorkFlowStaus(values);
                isSaveSucess = (isSaveReqWorkflowStatus === true && isSaveSucess === true) ? true : false;
                if(isSaveSucess === true){
                    SendEmail(values);
                    Swal.fire({
                        iconHtml: IconAlertSuccess,
                        title: "ส่งคำขอเรียบร้อย\nอยู่ระหว่างส่งคำขอสุดท้ายให้ผู้อนุมัติขั้นสุดท้าย\nSubmitted the request\nsending to final approvers",
                        showConfirmButton: false,
                        showCloseButton: true,
                    }).then(() => {
                        // history.push("/dashboard");
                        history.push("/requests");
                    });
                }
            })();
        }
    };

    const UpdatePrefinalContractReject = async (dataReq) => {
        const url = ConfigData.API_URL + "request/prefilnal-reject/" + dataReq.id;
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let formData = new FormData();
        formData.append("request_no", dataReq.request_no);
        formData.append("legal1_approve_by", dataReq.legal1_approve_by);
        formData.append("legal2_approve_by", dataReq.legal2_approve_by);
        formData.append("legal3_approve_by", dataReq.legal3_approve_by);
        formData.append("user1_approve_by", dataReq.user1_approve_by);
        formData.append("user2_approve_by", dataReq.user2_approve_by);
        formData.append("user3_approve_by", dataReq.user3_approve_by);
        formData.append("n_final_doc", getFilterOldFileName(fileLegalFinalDoc));
        formData = setFormDataUploadFile(formData, "f_final_doc", fileLegalFinalDoc);
        formData.append("n_memo_doc", getFilterOldFileName(fileMemoDoc));
        formData = setFormDataUploadFile(formData, "f_memo_doc", fileMemoDoc);
        try{
            const resp = await axios.put(url, formData, { headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization' : token,
                'User': userId,
            }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }

    };

    const AddWorkFlowStaus = async (dataReq) => {
        const url = ConfigData.API_URL + "request-workflow/step";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        try{
            let formData = new FormData();
            formData.append("id_request", dataReq.id);
            formData.append("id_workflow_status", "20");
            formData.append("do_by", userId);
            formData.append("send_to", dataReq[keySendTo]);
            const resp = await axios.post(url, formData, { headers: { 
                            'Authorization' : token,
                            'User': userId,
                        }, responseType: "json" });
            let respData = resp.data;
            return respData.is_sucess;
        }catch(error){
            console.log(error);
            return false;
        }
    };

    const SendEmail = (data) => {
        const url = ConfigData.API_URL + "email/send";
        const token = "Bearer " + localStorage.getItem("tkd");
        const userId = (!localStorage.getItem("emd")) ? "" : atob(localStorage.getItem("emd"));
        let arrTo = data[keySendTo].split(",");
        let dataReq = {
            "to": arrTo,
            "template_id": 12,
            "request_id": data.id,
        };
        axios.post(url, dataReq, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            // console.log(resp.data);
        })
        .catch(error => {
            // handlerRespError(error, history);
            console.log(error);
        });
    };

    const CheckDisableApprover = (indexInput) => {
        const rejectColumn = arrSeqApprover.find(e => e.column === keySendTo);
        if(rejectColumn !== undefined){
            if(rejectColumn.no > indexInput){
                return true;
            }
        }
        return false;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="prefinal-contract-reject">
                    <Row>
                        <Col lg={12}>
                            <p className="pcr-title">Request Detail {"> " + dataRequest.request_no}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <StepWorkflow step={8} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={11} lg={11}>
                            <div className="pcr-reject-by">
                                <p className="pcr-reject-title">
                                    <i className="mdi mdi-clipboard-alert"></i>
                                    {/* Mr.xxxxx xxxxxx rejected the final contract! */}
                                    {dataRequest.reject_by} ปฏิเสธร่างเอกสารสุดท้าย (Rejected the final document.)
                                </p>
                                <p className="pcr-reject-reason-label">สาเหตุในการปฏิเสธ (Reason to reject)</p>
                                <p className="pcr-reject-reason-detail">{(dataRequest.reject_note !== "") ? dataRequest.reject_note : "-"}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center pcr-form">
                        <Col sm={12} md={8} lg={6}>
                            <Form className="needs-validation"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row className="pcr-form-legal-file-div">
                                    <Col lg={12}>
                                        <p className="pcr-form-legal-title">การจัดทำร่างเอกสารสุดท้าย (Finalizing)</p>
                                        <p className="pcr-form-legal-file-label">ร่างเอกสารสุดท้ายและ เอกสารแนบ (Finalized Document and Attachment)</p>
                                        <SingleUploadfile inputID="legalFinalDoc" folder={validation.values.request_no} dataFile={fileLegalFinalDoc} setDataFile={OnSetFileLegalFinalDoc} />
                                        { validation.touched.final_doc && validation.errors.final_doc ? 
                                            ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.final_doc}</FormFeedback> ) : false 
                                        }
                                        <p className="pcr-form-memo-label">Memo สำหรับเสนอลงนาม (Memo for Signing Procedure)</p>
                                        <SingleUploadfile inputID="memoDoc" folder={validation.values.request_no} dataFile={fileMemoDoc} setDataFile={OnSetFileMemoDoc} />
                                        { validation.touched.memo_doc && validation.errors.memo_doc ? 
                                            ( <FormFeedback type="invalid" style={{display: "block"}}>{validation.errors.memo_doc}</FormFeedback> ) : false 
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <p className="pcr-form-head-input-label">
                                            ท่านสามารถเลือกผู้อนุมัติได้ 1-3 คน โดยการอนุมัติจะถูกเรียงตามลำดับที่ท่านเลือก<br />
                                            (You can select 1-3 persons.The approval will be arranged according to your selection.)
                                        </p>
                                        <FormGroup>
                                            <Label className="pcr-form-label" htmlFor="txtLegalApprove1">1.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย (Require Final Approval From)<span className="msg-require">*</span></Label>
                                            <Input id="txtLegalApprove1"
                                                name="legal1_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                disabled={CheckDisableApprover(1)}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.legal1_approve_by || ""}
                                                invalid={ validation.touched.legal1_approve_by && validation.errors.legal1_approve_by ? true : false }    
                                            />
                                            { validation.touched.legal1_approve_by && validation.errors.legal1_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.legal1_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label className="pcr-form-label" htmlFor="txtLegalApprove2">2.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย (Require Final Approval From)</Label>
                                            <Input id="txtLegalApprove2" 
                                                name="legal2_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                disabled={CheckDisableApprover(2)}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.legal2_approve_by || ""}
                                                invalid={ validation.touched.legal2_approve_by && validation.errors.legal2_approve_by ? true : false } 
                                            />
                                            { validation.touched.legal2_approve_by && validation.errors.legal2_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.legal2_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="pcr-form-legal-last-div">
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label className="pcr-form-label" htmlFor="txtLegalApprove3">3.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย (Require Final Approval From)</Label>
                                            <Input id="txtLegalApprove3" 
                                                name="legal3_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                disabled={CheckDisableApprover(3)}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.legal3_approve_by || ""}
                                                invalid={ validation.touched.legal3_approve_by && validation.errors.legal3_approve_by ? true : false }
                                            />
                                            { validation.touched.legal3_approve_by && validation.errors.legal3_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.legal3_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="pcr-form-user-div">
                                    <Col lg={12}>
                                        <p className="pcr-form-user-title">ผู้อนุมัติขั้นสุดท้ายโดยผู้ทำคำขอ (Final approval person selected by the user)</p>
                                        <p className="pcr-form-head-input-label">
                                            ท่านสามารถเลือกผู้อนุมัติได้ 1-3 คน โดยการอนุมัติจะถูกเรียงตามลำดับที่ท่านเลือก<br />
                                            (You can select 1-3 persons.The approval will be arranged according to your selection.)
                                        </p>
                                        <FormGroup>
                                            <Label className="pcr-form-label" htmlFor="txtUserApprove1">1.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย(ถ้ามี) (Require Final Approval From(If any)</Label>
                                            <Input id="txtUserApprove1" 
                                                name="user1_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                disabled={CheckDisableApprover(4)}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.user1_approve_by || ""}
                                                invalid={ validation.touched.user1_approve_by && validation.errors.user1_approve_by ? true : false }    
                                            />
                                            { validation.touched.user1_approve_by && validation.errors.user1_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.user1_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label className="pcr-form-label" htmlFor="txtUserApprove2">2.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย(ถ้ามี) (Require Final Approval From(If any)</Label>
                                            <Input id="txtUserApprove2" 
                                                name="user2_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                disabled={CheckDisableApprover(5)}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.user2_approve_by || ""}
                                                invalid={ validation.touched.user2_approve_by && validation.errors.user2_approve_by ? true : false } 
                                            />
                                            { validation.touched.user2_approve_by && validation.errors.user2_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.user2_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="pcr-form-user-last-div">
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label className="pcr-form-label" htmlFor="txtUserApprove3">3.จำเป็นต้องได้รับการอนุมัติขั้นสุดท้ายโดย(ถ้ามี) (Require Final Approval From(If any)</Label>
                                            <Input id="txtUserApprove3" 
                                                name="user3_approve_by"
                                                placeholder="Please Input email"
                                                className="from-control"
                                                disabled={CheckDisableApprover(6)}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.user3_approve_by || ""}
                                                invalid={ validation.touched.user3_approve_by && validation.errors.user3_approve_by ? true : false }
                                            />
                                            { validation.touched.user3_approve_by && validation.errors.user3_approve_by ? 
                                                ( <FormFeedback type="invalid">{validation.errors.user3_approve_by}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="page-footer">
                                    <div className="layout-width-footer">
                                        <div className="navbar-footer2">
                                            <Button type="button" className="pcr-form-btn-back" onClick={(e) => { OnClickBack(); }}>
                                                Back
                                            </Button>
                                            <Button type="submit" color='primary' className="pcr-form-btn-submit">
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PrefinalContractReject;